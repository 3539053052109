import React,{useState} from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Fade } from '@material-ui/core';
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import styles from "./index.module.scss";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

export default function UpcomingChallenge(props){

    const {
      openModalChallenge,
      challengeActive, 
      startDate,
      endDate,
      handleStartDateChange,
      handleEndDateChange,
      isDisabledInput,
      minDate,
      onErrorStartDate,
      onErrorEndDate,
      errorValidateStartDate,
      errorValidateEndDate,
      handleChangeDescription,
      cancelUpdateChallenge,
      dataUpdateChallenge,
      updateDataChallenge,
      description,
      setName, name, 
      setDistance, distance
    } = props;


    const onNameChange = (e)=>{
      const VALUE = e.target.value;
      setName(VALUE);
    }

    const onDistanceChange = (e)=>{
      const VALUE = e.target.value;
      if(!isNaN(VALUE)){
        setDistance(VALUE);
      }
    }

    return (
      <Modal
      className={styles.modalSchedule}
      open={openModalChallenge}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModalChallenge}>
        <div className={styles.contentModal}>
          <h3 className={styles.titleModal}>Challenge Details for {challengeActive ? challengeActive.name : null}</h3>
          <div className={styles.labelDate}>
            Start / End Dates
          </div>
          <div className={styles.inputDate}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/DD/YYYY"
                margin="normal"
                value={moment(startDate).toString()}
                autoOk={true}
                onChange={handleStartDateChange}
                name="startDate"
                placeholder="mm/dd/yyyy"
                disabled={isDisabledInput}
                minDate={minDate}
                onError={onErrorStartDate}
                error={errorValidateStartDate}
                keyboardIcon={<img src={require('src/assets/images/icon-calendar.svg')}
                                   alt=""/>}
              />
              <KeyboardDatePicker
                onError={onErrorEndDate}
                error={errorValidateEndDate}
                autoOk={true}
                disableToolbar
                variant="inline"
                format="MM/DD/YYYY"
                margin="normal"
                value={moment(endDate).toString()}
                onChange={handleEndDateChange}
                name="endDate"
                placeholder="mm/dd/yyyy"
                disabled={isDisabledInput}
                minDate={minDate}
                keyboardIcon={<img src={require('src/assets/images/icon-calendar.svg')}
                                   alt=""/>}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className={styles.challengeInformation}>

            <div className={styles.challengeInformationItem}>
              <p className="">Name: </p>&ensp;
              <input  onChange={onNameChange} type="text" value={name} />
            </div>

            <div className={styles.challengeInformationItem}>
              <p className="">Distance: </p>&ensp;
              <input onChange={onDistanceChange} type="text" value={distance} />
            </div>

            </div>

          <div className={styles.description}>
            Description
          </div>
          <TextField
            multiline={true}
            className={styles.textDescription}
            placeholder={"This message will appear in the email introducing the challenge. If you have any prizes for the winners we recommend you list them here."}
            name="description"
            value={description}
            inputProps={{maxLength: 140}}
            disabled={isDisabledInput}
            onChange={handleChangeDescription}
          />
          <p className={styles.maxChar}>
            140 char max
          </p>
          <div className={styles.note}>
            <span>*</span>
            <p>
              Since this is scheduled for a date in the future you will see it in the upcoming
              section of
              challenges and on your dashboard.
            </p>
          </div>
          <div className={styles.modalFooter}>
            <Button
              variant="outlined"
              type="button"
              onClick={cancelUpdateChallenge}
              disabled={dataUpdateChallenge.loading}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              type="button"
              onClick={updateDataChallenge}
              disabled={(dataUpdateChallenge.loading) || isDisabledInput}
            >
              Save
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
    )
}
UpcomingChallenge.propTypes = {
    
};
  