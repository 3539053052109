export const types = {
  UPDATE_STEP: 'UPDATE_STEP',
  NEXT_STEP: 'NEXT_STEP',

  ONBOARDING_REQUEST: 'ONBOARDING_REQUEST',
  ONBOARDING_SUCCESS: 'ONBOARDING_SUCCESS',
  ONBOARDING_FAILURE: 'ONBOARDING_FAILURE',
};


export const urls = {
  UPDATE_ONBOARDING: 'companyAdmin/onboarding',
};
