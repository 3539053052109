import React from 'react';

function Prev() {
  return (
    <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.999999 6.58609L0.707105 6.87898C0.316581 7.26951 0.316581 7.90267 0.707106 8.29319L0.999999 8.58609L7 14.5861C7.55227 15.1384 8.44772 15.1384 9 14.5861C9.55227 14.0338 9.55227 13.1384 9 12.5861L4 7.58609L9 2.58609C9.55228 2.03381 9.55228 1.13837 9 0.58609C8.44772 0.0338054 7.55228 0.0338053 7 0.586089L2 5.58609L0.999999 6.58609Z" fill="#C4C4C4"/>
    </svg>
  );
}

export default Prev;
