import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import auth from "./auth/reducer";
import dashboard from "./dashboard/reducer";
import billing from "./billing/reducer";
import schedule from "./schedule/reducer";
import sponsorship from "./sponsorship/reducer";
import account from "./account/reducer";
import onboarding from "./onboarding/reducer";
import gallery from "./gallery/reducer";
import challenge from "./challenge/reducer";
import forgotpassword from "./forgotpassword/reducer";
import employees from "./employees/reducer";
export default function createRootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    auth,
    dashboard,
    billing,
    schedule,
    sponsorship,
    account,
    onboarding,
    gallery,
    challenge,
    forgotpassword,
    employees,
  });
}
