export const types = {

  GET_CSV_SUCCESS:"GET_CSV_SUCCESS",
  GET_CSV_FAILURE:'GET_CSV_FAILURE',

  CLEAR_CSV: "CLEAR_CSV",

  CSV_REGISTERED_USERS_REQUEST: "CSV_REGISTERED_USERS_REQUEST",
  CSV_IMPACT_REQUEST: "CSV_IMPACT_REQUEST",
  CSV_SPONSORSHIP_REQUEST: "CSV_SPONSORSHIP_REQUEST",
  CSV_MILES_COMPLETED_REQUEST: "CSV_MILES_COMPLETED_REQUEST",
  CSV_LEADERBOARD_REQUEST: "CSV_LEADERBOARD_REQUEST",
  CSV_CHALLENGES_REQUEST: "CSV_CHALLENGE_REQUEST",
  GET_CSV_PAST_CHALLENGE_REQUEST: "GET_CSV_PAST_CHALLENGE_REQUEST",
  CSV_CHALLENGES_BY_ID_REQUEST: "CSV_CHALLENGES_BY_ID_REQUEST",
  GET_CSV_DETAIL_CHALLENGE_REQUEST: "GET_CSV_DETAIL_CHALLENGE_REQUEST",
  GET_CSV_ACTIVITEIE_LEADERBOARD_REQUEST:"GET_CSV_ACTIVITEIE_LEADERBOARD_REQUEST",

  OPEN_MODAL_EDIT_SPONSORSHIP: "OPEN_MODAL_EDIT_SPONSORSHIP",

  REGISTERED_USERS_REQUEST: "REGISTERED_USERS_REQUEST",
  REGISTERED_USERS_SUCCESS: "REGISTERED_USERS_SUCCESS",
  REGISTERED_USERS_FAILURE: "REGISTERED_USERS_FAILURE",

  TOTAL_IMPACT_REQUEST: "TOTAL_IMPACT_REQUEST",
  TOTAL_IMPACT_SUCCESS: "TOTAL_IMPACT_SUCCESS",
  TOTAL_IMPACT_FAILURE: "TOTAL_IMPACT_FAILURE",

  CURRENT_SPONSORSHIP_REQUEST: "CURRENT_SPONSORSHIP_REQUEST",
  CURRENT_SPONSORSHIP_SUCCESS: "CURRENT_SPONSORSHIP_SUCCESS",
  CURRENT_SPONSORSHIP_FAILURE: "CURRENT_SPONSORSHIP_FAILURE",

  EDIT_SPONSORSHIP_BY_ID_REQUEST: "EDIT_SPONSORSHIP_BY_ID_REQUEST",
  EDIT_SPONSORSHIP_BY_ID_SUCCESS: "EDIT_SPONSORSHIP_BY_ID_SUCCESS",
  EDIT_SPONSORSHIP_BY_ID_FAILURE: "EDIT_SPONSORSHIP_BY_ID_FAILURE",

  GET_SPONSORSHIP_BY_ID_REQUEST: "GET_SPONSORSHIP_BY_ID_REQUEST",
  GET_SPONSORSHIP_BY_ID_SUCCESS: "GET_SPONSORSHIP_BY_ID_SUCCESS",
  GET_SPONSORSHIP_BY_ID_FAILURE: "GET_SPONSORSHIP_BY_ID_FAILURE",

  MILES_COMPLETED_REQUEST: "MILES_COMPLETED_REQUEST",
  MILES_COMPLETED_SUCCESS: "MILES_COMPLETED_SUCCESS",
  MILES_COMPLETED_FAILURE: "MILES_COMPLETED_FAILURE",

  LEADERBOARD_REQUEST: "LEADERBOARD_REQUEST",
  LEADERBOARD_SUCCESS: "LEADERBOARD_SUCCESS",
  LEADERBOARD_FAILURE: "LEADERBOARD_FAILURE",

  CHALLENGES_REQUEST: "CHALLENGE_REQUEST",
  CHALLENGES_SUCCESS: "CHALLENGE_SUCCESS",
  CHALLENGES_FAILURE: "CHALLENGE_FAILURE",

  SET_MESSAGE_DASHBOARD: "SET_MESSAGE_DASHBOARD",
  UPDATE_MESSAGE_DASHBOARD: "UPDATE_MESSAGE_DASHBOARD",

};

export const urls = {
  REGISTERED_USERS: "companyAdmin/users/stats",
  TOTAL_IMPACT: "companyAdmin/impact",
  CURRENT_SPONSORSHIP: "companyAdmin/sponsorships",
  MILES_COMPLETED: "companyAdmin/chart",
  LEADERBOARD: "companyAdmin/leaderboard",
  CHALLENGES: "companyAdmin/challenges",
  CSV_CHALLENGES: "companyAdmin/export/challenges",
  CSV_MILES_COMPLETED: "companyAdmin/export/company",
  CSV_LEADERBOARD: "companyAdmin/export/leaderboard",
  CSV_ACTIVITIES_LEADERBOARD: "companyAdmin/export/activities",
  CSV_IMPACT: "companyAdmin/export/impact",
  CSV_REGISTERED_USERS: "companyAdmin/export/users",
  CSV_SPONSORSHIP: "companyAdmin/export/sponsorships",
  EDIT_SPONSORSHIP_BY_ID: (sponsorshipUnid) =>
    `companyAdmin/sponsorships/${sponsorshipUnid}`,
  SPONSORSHIP_BY_ID: (sponsorshipUnid) =>
    `companyAdmin/sponsorships/${sponsorshipUnid}`,
  GET_CSV_PAST_CHALLENGE: (challengeId) =>
    `companyAdmin/export/challenges/${challengeId}`,
  CSV_CHALLENGES_BY_ID: (challengeId) =>
    `companyAdmin/export/challenges/${challengeId}`,
  GET_CSV_DETAIL_CHALLENGE: (challengeId) =>
    `companyAdmin/export/challenges/${challengeId}/detailed`,
};
