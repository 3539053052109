import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from 'src/components/TextField';
import styles from './index.module.scss';
import { useFormik } from 'formik';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { forgotPassword } from 'src/redux/forgotpassword/action';
const mapStateToProps = (state) => {
    return {
        forgotPassword: state.forgotpassword.forgotPassword,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators({
            forgotPassword,
        }, dispatch),
    };
};

export const ForgotPasswordContainer = (props) => {
    const { forgotPassword } = props;
    const [isSuccess, setSuccess] = useState(false)
    useEffect(() => {
        if (forgotPassword.isSuccess) {
            setSuccess(true)
        }
    }, [forgotPassword])

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Email is valid').required('Required')
        }),
        onSubmit: values => {
            props.action.forgotPassword(values);
        },
    });

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <div className={styles.logo}>
                        <img src={require('src/assets/images/logo.svg')} alt="CM" />
                    </div>
                    <h1>Charity Miles Forgot Password</h1>
                    <div className={styles.subTitle}>{isSuccess ? 'Please check your inbox to recover your password.' : 'Please provide your email to recover your password.'}</div>
                </div>

                <div className={styles.form}>

                    <form onSubmit={formik.handleSubmit}>
                        {
                            !isSuccess && (
                                <>
                                    <div className={styles.inputWrapUsername}>
                                        <TextField
                                            label="Email"
                                            name="email"
                                            placeholder="janelovestorun@amcecompany.com"
                                            formik={formik}
                                        />
                                    </div>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        className={styles.btnNext}
                                        type="submit"
                                    >
                                        Next
                                </Button>
                                </>
                            )
                        }
                        {
                            isSuccess && (
                                <>
                                    <p>Message has been sent to your email, please check your email for updated information.</p>
                                    <p>If you don't see email. Click <span className={styles.txtAgain} onClick={formik.handleSubmit}>here</span> to send again.</p>
                                </>
                            )
                        }
                    </form>
                </div>


            </div>
        </div>
    );
};

ForgotPasswordContainer.propTypes = {
    signIn: PropTypes.object.isRequired,
    action: PropTypes.shape({
        signIn: PropTypes.func.isRequired,
    }).isRequired,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(ForgotPasswordContainer));