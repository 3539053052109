import { types } from './index';

export const forgotPassword = (payload) => ({
    type: types.FORGOT_PASSWORD_REQUEST,
    payload
});

export const resetPassword = (payload) => ({
    type: types.RESET_PASSWORD_REQUEST,
    payload
})