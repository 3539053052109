import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const upper = str => str.replace(/^\w/, c => c.toUpperCase());

export const TextFieldComponent = (props) => {
    const { name, type, label, formik, readOnly, ...rest } = props;
    
    return (
        <TextField
            fullWidth
            name={name}
            type={(name === 'password' && name) || type}
            label={label || upper(name)}
            error={!!(formik.touched[name] && formik.errors[name])}
            value={formik.values[name]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                readOnly
            }}
            onClick={(e) => { e.target.value && e.target.setSelectionRange(0, e.target.value.length); }}
            {...rest}
        />
	);
};

TextFieldComponent.propTypes = {
    formik: PropTypes.object,
    name: PropTypes.string,
    type: PropTypes.string,
};

TextFieldComponent.defaultProps = {
    type: 'text',
};

export default (TextFieldComponent);