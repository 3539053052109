import { call, put, takeLatest } from 'redux-saga/effects';
import { types, urls } from './index';
import {apiGet, apiPost} from 'src/utils/api';
import notify from 'src/utils/notify';

function* setupAccount(action) {
    try {
        const response = yield call(apiPost, {
            path: urls.ACCOUNT_SETUP,
            payload: action.payload
        });
        if (response.error) {
            yield put({
                type: types.ACCOUNT_SETUP_FAILURE,
                payload: response,
            });
            notify.error(response.error);
        } else {
            yield put({
                type: types.ACCOUNT_SETUP_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        yield put({
            type: types.ACCOUNT_SETUP_FAILURE,
            payload: error,
        });
        notify.error(error.message);
    }
}


function* getAccountInfo() {
    try {
        const response = yield call(apiGet, {
            path: urls.ACCOUNT_INFO
        });
        if (response.error) {
            yield put({
                type: types.ACCOUNT_INFO_FAILURE,
                payload: response,
            });
        } else {
            yield put({
                type: types.ACCOUNT_INFO_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        yield put({
            type: types.ACCOUNT_INFO_FAILURE,
            payload: error,
        });
        notify.error(error.message);
    }
}

function* updateAccount(action) {
    try {
        const response = yield call(apiPost, {
            path: urls.UPDATE_ACCOUNT,
            payload: action.payload
        });
        if (response.error) {
            yield put({
                type: types.UPDATE_ACCOUNT_FAILURE,
                payload: response,
            });
            notify.error(response.error);
        } else {
            yield put({
                type: types.UPDATE_ACCOUNT_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        yield put({
            type: types.UPDATE_ACCOUNT_FAILURE,
            payload: error,
        });
        notify.error(error.message);
    }
}

function* clearUpdateAccount() {
    yield put({
        type: types.UPDATE_ACCOUNT_SUCCESS,
        payload: {},
    });
}


export default function* accountWatcher() {
    yield takeLatest(types.ACCOUNT_SETUP_REQUEST, setupAccount);
    yield takeLatest(types.ACCOUNT_INFO_REQUEST, getAccountInfo);
    yield takeLatest(types.UPDATE_ACCOUNT_REQUEST, updateAccount);
    yield takeLatest(types.CLEAR_UPDATE_ACCOUNT, clearUpdateAccount);
}
