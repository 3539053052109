import { call, put, takeLatest } from "redux-saga/effects";
import { types, urls } from "./index";
import { apiGet, apiDelete } from "src/utils/api";
import notify from "src/utils/notify";

function* getEmployees() {
  try {
    const response = yield call(apiGet, {
      path: urls.GET_EMPLOYEES,
    });
    if (response.error) {
      yield put({
        type: types.GET_EMPLOYEES_FAIURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.GET_EMPLOYEES_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_EMPLOYEES_FAIURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* getEmployeesByName(payload) {
  const { name } = payload;
  try {
    const response = yield call(apiGet, {
      path: urls.GET_EMPLOYEES_BY_NAME(name),
    });
    if (response.error) {
      yield put({
        type: types.GET_EMPLOYEES_BY_NAME_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.GET_EMPLOYEES_BY_NAME_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_EMPLOYEES_BY_NAME_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* getEmployeeSessions(payload) {
  const { empUnid } = payload;
  try {
    const response = yield call(apiGet, {
      path: urls.GET_EMPLOYEES_SESSION(empUnid),
    });
    if (response.error) {
      yield put({
        type: types.GET_EMPLOYEES_SESSION_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.GET_EMPLOYEES_SESSION_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_EMPLOYEES_SESSION_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* deleteSpecificEmployeeSessions(payload) {
  const { empUnid, workoutIds } = payload;
  try {
    const response = yield call(apiDelete, {
      path: urls.DELETE_SPECIFIC_EMPLOYEE_SESSION(empUnid, workoutIds),
    });
    if (response.error) {
      yield put({
        type: types.DELETE_SPECIFIC_EMPLOYEE_SESSION_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.DELETE_SPECIFIC_EMPLOYEE_SESSION_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.DELETE_SPECIFIC_EMPLOYEE_SESSION_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* deleteEmployee(payload) {
  const { empUnid } = payload;
  try {
    const response = yield call(apiDelete, {
      path: urls.DELETE_EMPLOYEE(empUnid),
    });
    if (response.error) {
      yield put({
        type: types.DELETE_EMPLOYEE_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.DELETE_EMPLOYEE_SUCCESS,
        payload: response.data,
      });
      yield getEmployees();
    }
  } catch (error) {
    yield put({
      type: types.DELETE_EMPLOYEE_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* inactiveEmployee(payload) {
  const { empUnids } = payload;
  try {
    const response = yield call(apiDelete, {
      path: urls.INACTIVE_EMPLOYEE(empUnids),
    });
    if (response.error) {
      yield put({
        type: types.INACTIVE_EMPLOYEE_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.INACTIVE_EMPLOYEE_SUCCESS,
        payload: response.data,
      });
      yield getEmployees();
    }
  } catch (error) {
    yield put({
      type: types.INACTIVE_EMPLOYEE_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

export default function* employeeWatcher() {
  yield takeLatest(types.GET_EMPLOYEES_REQUEST, getEmployees);
  yield takeLatest(types.GET_EMPLOYEES_BY_NAME_REQUEST, getEmployeesByName);
  yield takeLatest(types.GET_EMPLOYEES_SESSION_REQUEST, getEmployeeSessions);
  yield takeLatest(
    types.DELETE_SPECIFIC_EMPLOYEE_SESSION_REQUEST,
    deleteSpecificEmployeeSessions
  );
  yield takeLatest(types.DELETE_EMPLOYEE_REQUEST, deleteEmployee);
  yield takeLatest(types.INACTIVE_EMPLOYEE_REQUEST, inactiveEmployee)
}
