import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Chart from 'chart.js';

import styles from './index.module.scss';

export const ChartComponent = (props) => {
    const { data } = props;
    const canvasRef = useRef(undefined);
    const [myChart, setMyChart] = useState(undefined);

    useEffect(() => {
        if(canvasRef) {
            const ctx = canvasRef.current.getContext('2d');
            if (myChart) {
                myChart.destroy();
            }
            setMyChart(new Chart(ctx, data));
        }

        // eslint-disable-next-line
    }, [data]);

    return (
        <div className={styles.chartContainer}>
            <canvas ref={canvasRef}/>
        </div>
    );
};

ChartComponent.propTypes = {
    data: PropTypes.object,
};

ChartComponent.defaultProps = {
    // data: {},
};

export default (ChartComponent);
