import { types } from './index';

const initialState = {
    signIn: {},
    signUp: {},
    activate: {},
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case types.SIGN_IN_REQUEST:
            return { ...state, signIn: { loading: true } };
        case types.SIGN_IN_SUCCESS:
        case types.SIGN_IN_FAILURE:
            return { ...state, signIn: action.payload };

        case types.SIGN_UP_REQUEST:
            return { ...state, signUp: { loading: true } };
        case types.SIGN_UP_SUCCESS:
        case types.SIGN_UP_FAILURE:
            return { ...state, signUp: action.payload };

        case types.ACTIVATE_REQUEST:
            return { ...state, activate: { loading: true } };
        case types.ACTIVATE_SUCCESS:
        case types.ACTIVATE_FAILURE:
            return { ...state, activate: action.payload };

        case types.SIGN_OUT_SUCCESS:
            return { ...state, signIn: {} };
        default:
            return state;
    }
};
export default auth;
