import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const upper = str => str.replace(/^\w/, c => c.toUpperCase());

export const CheckboxComponent = (props) => {
    const { name, label, formik, ...rest } = props;
    
    return (
        <FormControlLabel
            control={
            <Checkbox
                name={name}
                color="primary"
                checked={formik.values[name]}
                onChange={(event) => {
                    formik.setFieldValue(name, event.target.checked);
                }}
                {...rest}
            />
            }
            label={label || upper(name)}
        />
	);
};

CheckboxComponent.propTypes = {
    formik: PropTypes.object,
    name: PropTypes.string,
};

CheckboxComponent.defaultProps = {
    // type: 'text',
};

export default (CheckboxComponent);
