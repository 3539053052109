import {types} from './index';

const initialState = {
  payNow: {},
  dataPaymentPlan: {},
  dataBillingPlan: {},
  dataInvoices: {},
  dataUpdateCard: {},
};

const billing = (state = initialState, action) => {
  switch (action.type) {
    case types.PAY_NOW_REQUEST:
      return {...state, payNow: {loading: true}};
    case types.PAY_NOW_SUCCESS:
    case types.PAY_NOW_FAILURE:
      return {...state, payNow: action.payload ? action.payload : {}};

    case types.PAYMENT_PLAN_REQUEST:
      return {...state, dataPaymentPlan: {loading: true}};
    case types.PAYMENT_PLAN_SUCCESS:
    case types.PAYMENT_PLAN_FAILURE:
      return {...state, dataPaymentPlan: action.payload};

    case types.BILLING_PLAN_REQUEST:
      return {...state, dataBillingPlan: {loading: true}};
    case types.BILLING_PLAN_SUCCESS:
    case types.BILLING_PLAN_FAILURE:
      return { ...state, dataBillingPlan: action.payload };

    case types.INVOICES_REQUEST:
      return {...state, dataInvoices: {loading: true}};
    case types.INVOICES_SUCCESS:
    case types.INVOICES_FAILURE:
      return { ...state, dataInvoices: action.payload };

    case types.UPDATE_CARD_REQUEST:
      return {...state, dataUpdateCard: {loading: true}};
    case types.UPDATE_CARD_SUCCESS:
    case types.UPDATE_CARD_FAILURE:
      return { ...state, dataUpdateCard: action.payload };

    default:
      return state;
  }
};
export default billing;
