

import moment from 'moment'

class DateUtils {
    apiFormatOfDate = (date) => {
        if (typeof date === 'undefined' || date === null) {
            return null;
        }

        let mm = moment(date);
        return mm.format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    }  
    
    localDateFromMoment = (mm) => {
        let str = mm.format('YYYY-MM-DDTHH:mm:ss') + 'Z';
        //TODO
    }
}

export default new DateUtils();