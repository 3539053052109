import React, { useEffect } from "react";
import { Redirect, withRouter } from "react-router-dom";
import AuthStorage from "src/utils/authStorage";

import Layout from "src/components/Layout";
import Grid from "@material-ui/core/Grid";
import TotalImpact from "./TotalImpact";
import RegisteredUsers from "./RegisteredUsers";
import CurrentSponsorship from "./CurrentSponsorship";
import MilesCompleted from "./MilesCompleted";
import EmployeeLeaderboard from "./EmployeeLeaderboard";

import styles from "./index.module.scss";
import Challenge from "./Challenges";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { bindActionCreators } from "redux";
import { setMessageDashboard } from "src/redux/dashboard/action";
import Users from "./Users";
import Button from "@material-ui/core/Button";
import CompanyChanger from "./CompanyChanger";

export const DashboardContainer = (props) => {
  const { messageDashboard, dataAccountInfo } = props;
  const [displayUsers, setDisplayUser] = React.useState(false);
  const clearMessage = () => {
    props.action.setMessageDashboard("");
  };
  useEffect(() => {
    if (window.location.pathname.indexOf('register-user') !== -1) {
      setDisplayUser(true);
    } else {
      setDisplayUser(false);
    }
  }, [window.location.pathname])
  // if (dataAccountInfo.account && !dataAccountInfo.account.user.finishedOnBoarding) {
  //   if (dataAccountInfo.account.user.onBoardingStep !== 0) {
  //     return <Redirect to='/onboarding' />;
  //   }
  //   return <Redirect to='/checkout' />;
  // }
  const handleRedirectToUser = (e) => {
    props.history.push('/dashboard/register-user')
  };
  const handleBack = () => {
    props.history.push('/dashboard')
  };

  return (
    <>
      {displayUsers ? (
        <Layout>
          <Users handleBack={handleBack} />
        </Layout>
      ) : (
          <Layout>
            <div className={styles.root}>
              <div className={styles.welcome}>
                <h1>Welcome {AuthStorage.getName()}</h1>
                <CompanyChanger dataAccountInfo={props.dataAccountInfo}/>
              </div>

              <Grid className={styles.line1} container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <TotalImpact />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <RegisteredUsers handleRedirectToUser={handleRedirectToUser} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CurrentSponsorship />
                </Grid>
              </Grid>

              <Grid className={styles.line1} container spacing={4}>
                <Grid item xs={12}>
                  <MilesCompleted />
                </Grid>
              </Grid>

              <Grid className={styles.line1} container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <EmployeeLeaderboard />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Challenge />
                </Grid>
              </Grid>
            </div>
            {messageDashboard && (
              <Modal
                className={styles.modalAlert}
                open={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
                onClose={clearMessage}
              >
                <Fade in={true}>
                  <div className={styles.contentModal}>
                    <div className={styles.message}>
                      <img
                        src={require("src/assets/images/checked.svg")}
                        alt=""
                      />
                      <p>{messageDashboard}</p>
                    </div>
                    <div className={styles.modalFooter}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        type="button"
                        onClick={clearMessage}
                      >
                        Go to dashboard
                    </Button>
                    </div>
                  </div>
                </Fade>
              </Modal>
            )}
          </Layout>
        )}
    </>
  );
};

DashboardContainer.propTypes = {
  messageDashboard: PropTypes.string.isRequired,
  dataAccountInfo: PropTypes.object.isRequired,
  action: PropTypes.shape({
    setMessageDashboard: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    messageDashboard: state.dashboard.messageDashboard,
    dataAccountInfo: state.account.dataAccountInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      {
        setMessageDashboard,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardContainer));
