import React, {useState, useRef, useEffect} from 'react';
import {withRouter} from 'react-router-dom';

import Container from '@material-ui/core/Container';

import styles from "./index.module.scss";
import {bindActionCreators} from "redux";
import {setupAccount} from "src/redux/account/action";
import {nextStep} from "src/redux/onboarding/action";
import {saveStep} from "src/redux/onboarding/action";
import {getAccountInfo} from 'src/redux/account/action'
import {connect} from "react-redux";
import PropTypes from "prop-types";
import TextField from 'src/components/TextField';
import {Redirect} from "react-router";
import {useFormik} from "formik";
import * as Yup from "yup";
import Button from '@material-ui/core/Button';
import notify from 'src/utils/notify';
import * as filestack from 'filestack-js';
import config from "../../config/config";
import TextFieldMaterial from '@material-ui/core/TextField';


const filestackKey = config.fileStack.key;
const client = filestack.init(filestackKey);

export const AccountSetupContainer = (props) => {
  const {dataAccount} = props;
  const [redirect, setRedirect] = useState(null);
  const refInputFile = useRef(null);
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileLogo, setFileLogo] = useState(null);
  const [isRequiredEmailAndCode, setRequireEmailAndCode] = useState(false);
  const [emailDomain, setEmailDomain ] = useState();

  const formik = useFormik({
    initialValues: {
      adminName: '',
      companyName: '',
      logo: '',
      employeeMessage: '',
    },
    validationSchema: Yup.object().shape({
      adminName: Yup.string(),
      companyName: Yup.string(),
      employeeMessage: Yup.string(),
    }),
    onSubmit: values => {
      
      if(isRequiredEmailAndCode){
        if(!emailDomain){
          notify.error('Email domain is require.')
          return;
        }else{
          values.requireEmailAndCode = true;
          values.companyEmailDomain = emailDomain;
        }
      }else{
        values.requireEmailAndCode = false;
        values.companyEmailDomain = emailDomain;
      }
      uploadFile(values)
    },
  });

  useEffect(() => {
    if (dataAccount.company) {
      props.action.saveStep({
        onBoardingStep: 2,
        finishedOnBoarding: false
      });
      props.action.nextStep({
        accountSetup: 'done',
        challenge: 'doing'
      });
      props.action.getAccountInfo();
      setRedirect('/dashboard');
    }

    // eslint-disable-next-line
  }, [dataAccount]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      notify.error('Sorry, only jpg, jpeg and png files are allowed');
      event.target.value = null;
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      const img = new Image();
      img.src = e.target.result;

      img.onload = function () {
        if (this.width !== this.height) {
          notify.error('Please upload a square image!');
          e.target.value = null;
        } else {
          setFileLogo(file);
          setLogo(reader.result)
        }
      };
    };
  };

  const uploadFile = (values) => {
   
  
    if (!logo) {
      //set default logo as charity miles logo
      props.action.setupAccount({
        ...values,
        logo: "https://cdn.filestackcontent.com/yDnHZmwRS1Cf3PdDyEfE"
      });
      return;
    }
    setLoading(true);
    const token = {};
    const onRetry = (obj) => {
      console.log(`Retrying ${obj.location} for ${obj.filename}. Attempt ${obj.attempt} of 10.`);
    };
    const storeOptions = {
      filename: fileLogo.name.trim(),
      location: "s3"
    };
    client.upload(fileLogo, {onRetry, retry: 2}, storeOptions, token)
      .then(res => {
        setLoading(false);
        props.action.setupAccount({
          ...values,
          logo: res.url
        });
      })
      .catch(err => {
        setLoading(false);
        console.error('err: ', err);
        notify.error('Error');
      });
  };

  const openFileUpload = () => {
    refInputFile.current.click();
  };

  const onChangeEmailDomain = (e)=>{
    let VALUE = e.target.value;
    setEmailDomain(VALUE);
  }

  const onCheckRequireEmailAndCode = (e)=>{
    let isCheck = e.target.checked;
    setRequireEmailAndCode(isCheck);
  }

  if (redirect) {
    return <Redirect to={redirect}/>;
  }

  return (
    <Container className={styles.container}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.inner}>
          <h1 className={styles.title}>Complete Account Setup</h1>
          <div className={styles.accountInformation}>
            <div className={styles.title}>
              Account information
            </div>
            <div className={styles.formData}>
              <div className={styles.inputWrap}>
                <TextField
                  label="Your Name"
                  name="adminName"
                  placeholder="Johnny Appleseed"
                  readOnly={dataAccount.loading}
                  formik={formik}
                  inputProps={{maxLength: 20}}
                />
              </div>
              <div className={styles.inputWrap}>
                <TextField
                  label="Company Name"
                  name="companyName"
                  placeholder="Acme Company"
                  readOnly={dataAccount.loading}
                  formik={formik}
                  inputProps={{maxLength: 20}}
                />
              </div>
              <div className={styles.domainEmailCompany}>
                <div className={styles.domainEmailCompanyCheckbox}>
                  <input checked={isRequiredEmailAndCode} onChange={onCheckRequireEmailAndCode} type="checkbox" />
                  <p>Require Company Email For Sign-In Instead In Addition to Company Code</p>
                </div >
                <div className={styles.inputWrap}>
                  <TextFieldMaterial
                    fullWidth
                    name="companyEmailDomain"
                    value={emailDomain}
                    onChange={onChangeEmailDomain}
                    placeholder="domainmane.com (separate by commas)"
                    readOnly={dataAccount.loading}
                  />
                </div>
              </div>
              
            </div>
          </div>
          <div className={styles.accountInformation}>
            <div className={styles.title}>
              Customize what your employees see in the app
            </div>
            <div className={styles.customizeInfo}>
              <div className={styles.formData}>
                <div className={styles.companyLogo}>
                  <label>Company Logo</label>
                  <p onClick={openFileUpload}>Upload <span className={styles.imageSize}>(size: 400px X 400px)</span></p>
                  <input type="file" accept="image/x-png,image/jpeg"
                         className={styles.inputFile}
                         ref={refInputFile}
                         onChange={(event) => handleFileChange(event)}
                  />
                </div>
                <div className={`${styles.inputWrap} ${styles.message}`}>
                  <TextField
                    label="Message to employees"
                    multiline={true}
                    name="employeeMessage"
                    placeholder="Write a note that will inspire your employees to get excited and start moving. "
                    readOnly={dataAccount.loading}
                    formik={formik}
                    inputProps={{maxLength: 120}}
                  />
                  <div className={styles.maxChar}>
                    max char 120
                  </div>
                </div>
              </div>
              <div className={styles.previewMobile}>
                {
                  logo &&
                  <img src={logo} alt="logo" className={styles.logo}/>
                }
                {
                  formik.values.companyName &&
                  <div className={styles.companyName}>{formik.values.companyName}</div>
                }
                {
                  formik.values.employeeMessage &&
                  <div className={styles.employeeMessage}>{formik.values.employeeMessage}</div>
                }
                <img src={require('src/assets/images/preview-mobile-account.svg')}
                     alt="preview-mobile"/>
              </div>
            </div>
          </div>
          <div className={styles.actionBtn}>
            <Button
              variant="outlined"
              color="secondary"
              type="submit"
              disabled={dataAccount.loading || loading}
              className={styles.btn}
            >
              Next
            </Button>
          </div>
        </div>
      </form>
    </Container>
  );
};

AccountSetupContainer.propTypes = {
  dataAccount: PropTypes.object.isRequired,
  action: PropTypes.shape({
    setupAccount: PropTypes.func.isRequired,
    saveStep: PropTypes.func.isRequired,
    getAccountInfo: PropTypes.func.isRequired
  }).isRequired,
};


const mapStateToProps = (state) => {
  return {
    dataAccount: state.account.dataAccount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({
      setupAccount,
      nextStep,
      saveStep,
      getAccountInfo
    }, dispatch),
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AccountSetupContainer));
