import {types} from './index';

const initialState = {
  dataGallery: {},
};

const gallery = (state = initialState, action) => {
  switch (action.type) {
    case types.GALLERY_REQUEST:
      return {...state, dataGallery: {loading: true}};
    case types.GALLERY_SUCCESS:
    case types.GALLERY_FAILURE:
      return { ...state, dataGallery: action.payload };

    default:
      return state;
  }
};
export default gallery;
