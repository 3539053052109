import React,{Component}  from "react";
import axios from 'axios';
import config from "src/config/config";
import {getHeader} from "../../../utils/api";

class CompanyChanger extends Component{

    state={
        companies: []
    };

    componentDidMount() {
        let h = getHeader();
        let headers = {};
        for (let entry of h.entries()) {
            headers[entry[0]] = entry[1];
        }
        axios.request({
            method:"GET",
            url: config.apiEndpoint+"companyAdmin/superadmin/companies",
            headers: headers
        }).then((response)=>{
            if(response.data.data && response.data.data.companies && response.data.data.companies.length>0){
                this.setState({
                    companies: response.data.data.companies
                })
            }
        })
    }

    setNewCompany= (e)=>{
        let payload = {
            companyUnid: e.target.value
        };
        let h = getHeader();
        let headers = {};
        for (let entry of h.entries()) {
            headers[entry[0]] = entry[1];
        }
        axios.request({
            method:"POST",
            data: payload,
            url: config.apiEndpoint+"companyAdmin/superadmin",
            headers: headers
        }).then((response)=>{
            if(response.data.success===0){
                window.location.reload(false);
            }
        })
    };

    render() {
        if(this.state.companies.length<=0 || !this.props.dataAccountInfo.account) {
            return null;
        }
        return(
            <div style={{paddingTop:"10px"}}>
                <span style={{paddingRight:"10px"}}>Viewing Company:</span>
                <select value={this.props.dataAccountInfo.account.company.unid} onChange={this.setNewCompany}>
                    {this.state.companies.map((company)=>{
                       return(
                           <option value={company.unid}>{company.name}</option>
                       );
                    })}
                </select>
            </div>
        );
    }

}

export default CompanyChanger