import React, {useEffect, useState} from 'react';
import styles from '../index.module.scss';
import TextField from 'src/components/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Button} from '@material-ui/core';
import PropTypes from "prop-types";
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const BillingAddressComponent = (props) => {
  const {currentStep, maxStep, contactAddress, billingAddress} = props;
  const [isSameContact, setIsSameContact] = useState(false);

  const formik = useFormik({
    initialValues: {
      ...billingAddress
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string(),
      lastName: Yup.string(),
      address: Yup.string(),
      address2: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      zipCode: Yup.string(),
      country: Yup.string(),
    }),
    onSubmit: values => {
      props.updateBillingAddress(values);
    },
  });

  const editStep = () => {
    props.editStep(2)
  };

  useEffect(() => {
    setIsSameContact(false);
    // eslint-disable-next-line
  }, [formik.values]);

  const onChangeIsSameContact = (value) => {
    setIsSameContact(value);
    if (value) {
      formik.values.firstName = contactAddress.firstName;
      formik.values.lastName = contactAddress.lastName;
      formik.values.address = contactAddress.address;
      formik.values.address2 = contactAddress.address2;
      formik.values.city = contactAddress.city;
      formik.values.state = contactAddress.state;
      formik.values.zipCode = contactAddress.zipCode;
      formik.values.country = contactAddress.country;
    }
  };

  return (
    <div className={styles.detailInfo}>
      <div className={styles.header}>
        <div className={styles.title}>
          Billing address
        </div>
        {
          parseInt(currentStep) !== 2 && parseInt(maxStep) > 1 && (
            <Button
              color="secondary"
              className={styles.btnEdit}
              endIcon={<img src={require('src/assets/images/arrow-right-edit.svg')} alt=""/>}
              onClick={editStep}
            >
              Edit
            </Button>
          )
        }
      </div>
      {
        parseInt(currentStep) === 2 &&
        (
          <div className={styles.form}>
            <div className={styles.dFlexBetween}>
              <div className={styles.checkboxWrap}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isSameContact"
                      color="primary"
                      checked={isSameContact}
                      onChange={(event) => {
                        onChangeIsSameContact(event.target.checked);
                      }}
                    />
                  }
                  label="Same as contact address"
                />
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.dFlexBetween}>
                <div className={styles.inputWrap}>
                  <TextField
                    label="First name"
                    name="firstName"
                    formik={formik}
                  />
                </div>
                <div className={styles.inputWrap}>
                  <TextField
                    label="Last name"
                    name="lastName"
                    formik={formik}
                  />
                </div>
              </div>
              <div className={styles.inputWrap}>
                <TextField
                  label="Address"
                  name="address"
                  formik={formik}
                />
              </div>
              <div className={styles.inputWrap}>
                <TextField
                  label="Apartment, suite, etc."
                  name="address2"
                  formik={formik}
                />
              </div>
              <div className={styles.inputWrap}>
                <TextField
                  label="City"
                  name="city"
                  formik={formik}
                />
              </div>
              <div className={styles.dFlexBetween}>
                <div className={styles.inputWrap}>
                  <TextField
                    label="Country/Region"
                    name="country"
                    formik={formik}
                  />
                </div>
                <div className={styles.inputWrap}>
                  <TextField
                    label="State"
                    name="state"
                    formik={formik}
                  />
                </div>
                <div className={styles.inputWrap}>
                  <TextField
                    label="Zip code"
                    name="zipCode"
                    formik={formik}
                  />
                </div>
              </div>
              <div className={styles.inputWrap}>
                <Button
                  variant="outlined"
                  color="secondary"
                  type="submit"
                  className={styles.btnNextStep}
                >
                  Continue to Billing
                </Button>
              </div>
            </form>
          </div>
        )
      }
    </div>
  );
};

BillingAddressComponent.propTypes = {
  billingAddress: PropTypes.object.isRequired,
  currentStep: PropTypes.number.isRequired,
  editStep: PropTypes.func.isRequired,
  onChangeSameContact: PropTypes.func.isRequired,
  updateBillingAddress: PropTypes.func.isRequired,
};

export default BillingAddressComponent;
