import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Splash from 'src/containers/Splash';
import Dashboard from 'src/containers/Dashboard';
import SignUp from 'src/containers/SignUp';
import SignIn from 'src/containers/SignIn';
import Challenges from 'src/containers/Challenges';
import Gallery from 'src/containers/Gallery';
import NotFound from 'src/containers/NotFound';
import Activation from 'src/containers/Activation';
import Checkout from 'src/containers/Checkout';
import Onboarding from 'src/containers/Onboarding';
import Schedule from 'src/containers/Schedule';
import PreviewChallenge from 'src/containers/PreviewChallenge';
import Sponsorship from 'src/containers/Sponsorship';
import Confirmation from 'src/containers/Confirmation';
import AccountSetup from 'src/containers/AccountSetup';
import Account from 'src/containers/Account';
import Billing from 'src/containers/Billing';
import Resource from 'src/containers/Resource';
import DetailInvoice from 'src/containers/Billing/DetailInvoice';
import ForgotPassword from 'src/containers/ForgotPassword';
import ResetPassword from 'src/containers/ForgotPassword/resetPassword';
import PrivateRoute from 'src/utils/privateRoute';

export default () => (
  <Switch>
    <Redirect exact path="/" to="/splash" />
    <PrivateRoute exact path="/dashboard" component={Dashboard} />
    <PrivateRoute exact path="/dashboard/register-user" component={Dashboard} />
    <PrivateRoute path="/challenges" component={Challenges} />
    <PrivateRoute path="/gallery" component={Gallery} />
    <PrivateRoute path="/checkout" component={Checkout} />
    <PrivateRoute path="/confirmation" component={Confirmation} />
    <PrivateRoute path="/onboarding" component={Onboarding} />
    <PrivateRoute path="/schedule" component={Schedule} />
    <PrivateRoute path="/preview-challenge/:unid" component={PreviewChallenge} />
    <PrivateRoute path="/sponsorship/:unid" component={Sponsorship} />
    <PrivateRoute path="/account-setup" component={AccountSetup} />
    <PrivateRoute path="/account" component={Account} />
    <PrivateRoute path="/billing" component={Billing} />
    <PrivateRoute path="/invoices/:unid" component={DetailInvoice} />
    <PrivateRoute path="/resources" component={Resource} />
    <Route path='/resetPassword' component={ResetPassword} />
    <Route path='/forgot-password' component={ForgotPassword}></Route>
    <Route path="/splash" component={Splash} />
    <Route path="/signin" component={SignIn} />
    <Route path="/signup" component={SignUp} />
    <Route path="/activation" component={Activation} />
    <Route component={NotFound} />
  </Switch>
);
