import React,{useEffect}  from 'react';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { signUp } from 'src/redux/auth/action';
import AuthStorage from 'src/utils/authStorage';

import Button from '@material-ui/core/Button';
import TextField from 'src/components/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './index.module.scss';

const mapStateToProps = (state) => {
	return {
        signUp: state.auth.signUp,
    };
};

const mapDispatchToProps = (dispatch) => {
	return {
		action: bindActionCreators({
            signUp,
        }, dispatch),
	};
};

export const SignUpContainer = (props) => {
    const { signUp } = props;
    const [registerIn, setRegisterIn]= React.useState(false)

    const formik = useFormik({
        initialValues: {
            email: '',
            name: '',
            password: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required('Required'),
            name: Yup.string().required('Required'),
            password: Yup.string().required('Required'),
        }),
        onSubmit: values => {
            props.action.signUp(values);
        },
    });

    useEffect(() => {
        if (signUp && signUp.token) {
          AuthStorage.setAccessToken(signUp.token);
          AuthStorage.setName(signUp.user.name);
          AuthStorage.setActive(signUp.user.active);
          setRegisterIn(true)
        }
    }, [signUp]);

    if (registerIn) {
        return <Redirect to="/onboarding" />;
    }

    // if (AuthStorage.getAccessToken()) {
    //     return <Redirect to="/" />
    // }

    // if (signUp && signUp.token) {
    //     return (
    //         <div className={styles.root}>
    //             <div className={styles.container}>
    //                 <div className={`${styles.title} ${styles.titleMailed}`}>
    //                     <div className={styles.logo}>
    //                         <img src={require('src/assets/images/logo.svg')} alt="CM"/>
    //                     </div>
    //                     <h1>Please check your email</h1>
    //                     <div className={styles.subTitle}>
    //                         We sent an email to {signUp.user.email} with a link to activate your account.
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <div className={styles.logo}>
                        <img src={require('src/assets/images/logo.svg')} alt="CM"/>
                    </div>
                    <h1>Let’s get you set up with an account —</h1>
                    <div className={styles.subTitle}>Engage employees around what they truely care about.
                        Build team morale. Create challenges. Sponsor employees. Already have an account?
                        <Button
                            component={Link}
                            className={styles.btnLoginInline}
                            to="/signin"
                        >
                            Log in
                        </Button>
                    </div>
                </div>
                <div className={styles.form}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className={styles.inputWrapUsername}>
                            <TextField
                                name="email"
                                placeholder="janelovestorun@amcecompany.com"
                                readOnly={signUp.loading}
                                formik={formik}
                            />
                        </div>
                        <div className={styles.inputWrapUsername}>
                            <TextField
                                label="Name"
                                name="name"
                                placeholder="janelovestorun"
                                readOnly={signUp.loading}
                                formik={formik}
                            />
                        </div>
                        <div className={styles.inputWrapUsername}>
                            <TextField
                                name="password"
                                placeholder="********"
                                readOnly={signUp.loading}
                                formik={formik}
                            />
                        </div>
                        <div>
                            <Button
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                type="submit"
                                disabled={signUp.loading}
                                className={styles.btnLogin}
                            >
                                Get Started!
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
	);
};

SignUpContainer.propTypes = {
    signUp: PropTypes.object.isRequired,
    action: PropTypes.shape({
        signUp: PropTypes.func.isRequired,
    }).isRequired,
};

export default connect(
    mapStateToProps,
	mapDispatchToProps,
)(withRouter(SignUpContainer));
