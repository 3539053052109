import React from 'react';
import {Link, withRouter} from 'react-router-dom';

import Container from '@material-ui/core/Container';

import styles from "./index.module.scss";
import Button from '@material-ui/core/Button';

export const ConfirmationContainer = (props) => {
    return (
        <Container className={styles.container}>
            <div className={styles.inner}>
                <h1 className={styles.welcome}>
                  Thank you!  Let’s get you setup.
                </h1>
                <Button
                    variant="outlined"
                    color="secondary"
                    className={styles.setUp}
                    component={Link}
                    to="/onboarding"
                >
                    Set up account
                </Button>
            </div>
        </Container>
    );
};

export default withRouter(ConfirmationContainer);
