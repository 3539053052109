import React, { useEffect, useState } from "react";

import Paper from "src/components/Paper";
import Button from "@material-ui/core/Button";
import styles from "../box.module.scss";
import stylesEmployeeLeaderboard from "./index.module.scss";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { bindActionCreators } from "redux";
import {
  getLeaderboard,
  getCSVLeaderboard,
  clearCSV,
  getCSVActivitiesLeaderboard,
} from "src/redux/dashboard/action";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import config from "src/config/config";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Popover from "@material-ui/core/Popover";
import ExportCsvModal from '../../../components/Modal/ExportCsvModal';

const filters = [
  {
    text: "WTD",
    value: "week",
  },
  {
    text: "MTD",
    value: "month",
  },
  {
    text: "YTD",
    value: "year",
  },
  {
    text: "ALL",
    value: "all",
  },
];

export const EmployeeLeaderboardComponent = (props) => {
  let rows = [];
  let leaderBoard = [];
  const {
    dataLeaderboard,
    dataCSV
  } = props;
  const [isCopied, setIsCopied] = useState(false);

  if (dataLeaderboard && dataLeaderboard.leaderBoard) {
    leaderBoard = dataLeaderboard.leaderBoard;
    if (leaderBoard.length > 3) {
      rows = leaderBoard.slice(3, leaderBoard.length);
    }
  }
  const [isClickExport, setClickExport]= useState(false);
  const [openExportCsvModal, setOpenExportCsvModal]= useState(false);
  const [current, setCurrent] = useState(filters[3]);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const query = { by: current.value };
    props.action.getLeaderboard(query);
    // eslint-disable-next-line
  }, [current]);

  useEffect(()=>{
    if(dataCSV.isSuccess){
      setOpenExportCsvModal(true)
    }
  },[dataCSV])

  const handleCloseExportCsvModal =()=>{
    // clear data CSV
    props.action.clearCSV();
    setClickExport(false);
    setOpenExportCsvModal(false)
  }

  const showCurrentFilter = (by) => {
    if (by === "all" || !leaderBoard.length) {
      return null;
    }
    if (by === "year") {
      return moment.utc().format("YYYY");
    }
    if (by === "month") {
      return moment.utc().format("MMMM YYYY");
    }
    if (
      moment.utc().format("MMM") === moment.utc().add(6, "days").format("MMM")
    ) {
      return `${moment.utc().format("MMMM DD")} - ${moment
        .utc()
        .add(6, "days")
        .format("DD")}`;
    }
    return `${moment.utc().format("MMM DD")} - ${moment
      .utc()
      .add(6, "days")
      .format("MMM DD")}`;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Paper
      className={styles.box}
      loading={!dataLeaderboard || dataLeaderboard.loading}
    >
      <div className={stylesEmployeeLeaderboard.leaderboard}>
        <div className={stylesEmployeeLeaderboard.title}>
          Employee Leaderboard
        </div>
        <div className={stylesEmployeeLeaderboard.header}>
          <div className={stylesEmployeeLeaderboard.month}>
            {showCurrentFilter(dataLeaderboard.by)}
          </div>
          <Box display="flex" alignItems="center">
            <div className={stylesEmployeeLeaderboard.actions}>
              {filters.map((filter) => {
                return (
                  <Button
                    key={filter.text}
                    // disabled={!leaderBoard.length}
                    className={
                      styles.btn +
                      (filter.text === current.text ? ` ${styles.active}` : "")
                    }
                    onClick={() => setCurrent(filter)}
                  >
                    {filter.text}
                  </Button>
                );
              })}
            </div>
            {leaderBoard && leaderBoard.length > 0 && (
              <div style={{ cursor: "pointer" }}>
                <img
                  onClick={handleClick}
                  src={require("src/assets/images/ExportIcon.png")}
                  alt=""
                  width="40px"
                  height="40px"
                />
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <ul>
                    <li
                      className="p-1"
                      onClick={() => {
                        props.action.getCSVLeaderboard({
                          by: current.value,
                        });
                        setClickExport(true);
                        handleClose();
                      }}
                    >
                      Leaderboard
                    </li>
                    <li
                      onClick={() => {
                        props.action.getCSVActivitiesLeaderboard({
                          by: current.value,
                        });
                        setClickExport(true);
                        handleClose();
                      }}
                    >
                      Activities
                    </li>
                  </ul>
                </Popover>
              </div>
            )}
          </Box>
        </div>

        <div className={stylesEmployeeLeaderboard.body}>
          <div className={stylesEmployeeLeaderboard.leader}>
            <div className={stylesEmployeeLeaderboard.top3}>
              <div className={stylesEmployeeLeaderboard.content}>
                <div className={stylesEmployeeLeaderboard.userAvatar}>
                  {leaderBoard[2] && leaderBoard[2].profilePhoto ? (
                    <div className={stylesEmployeeLeaderboard.borderImage}>
                      <img src={leaderBoard[2].profilePhoto} alt="" />
                    </div>
                  ) : null}
                </div>
                <div className={stylesEmployeeLeaderboard.bgImage}>
                  <div className={stylesEmployeeLeaderboard.name}>
                    {leaderBoard[2] && <p>{leaderBoard[2].name}</p>}
                  </div>
                  <div className={stylesEmployeeLeaderboard.info}>
                    <div className={stylesEmployeeLeaderboard.detail}>
                      {leaderBoard[2] ? (
                        <div className={stylesEmployeeLeaderboard.detail}>
                          <span>
                            {Math.round(parseFloat(leaderBoard[2].totalMiles))}{" "}
                            mi
                          </span>
                          <span className={stylesEmployeeLeaderboard.divide}>
                            |
                          </span>
                          <span>
                            ${parseFloat(leaderBoard[2].totalMoney).toFixed(2)}
                          </span>
                        </div>
                      ) : (
                        <div className={stylesEmployeeLeaderboard.detail}></div>
                      )}
                    </div>
                  </div>
                  <div className={stylesEmployeeLeaderboard.no}>3</div>
                </div>
              </div>
            </div>
            <div className={stylesEmployeeLeaderboard.top1}>
              <div className={stylesEmployeeLeaderboard.content}>
                <div className={stylesEmployeeLeaderboard.userAvatar}>
                  {leaderBoard[0] && leaderBoard[0].profilePhoto ? (
                    <div className={stylesEmployeeLeaderboard.borderImage}>
                      <img src={leaderBoard[0].profilePhoto} alt="" />
                    </div>
                  ) : null}
                </div>
                <div className={stylesEmployeeLeaderboard.bgImage}>
                  <div className={stylesEmployeeLeaderboard.name}>
                    {leaderBoard[0] && <p>{leaderBoard[0].name}</p>}
                  </div>
                  <div className={stylesEmployeeLeaderboard.info}>
                    {leaderBoard[0] ? (
                      <div className={stylesEmployeeLeaderboard.detail}>
                        <span>
                          {Math.round(parseFloat(leaderBoard[0].totalMiles))} mi
                        </span>
                        <span className={stylesEmployeeLeaderboard.divide}>
                          |
                        </span>
                        <span>
                          ${parseFloat(leaderBoard[0].totalMoney).toFixed(2)}
                        </span>
                      </div>
                    ) : (
                      <div className={stylesEmployeeLeaderboard.detail}></div>
                    )}
                  </div>
                  <div className={stylesEmployeeLeaderboard.no}>1</div>
                </div>
              </div>
            </div>
            <div className={stylesEmployeeLeaderboard.top2}>
              <div className={stylesEmployeeLeaderboard.content}>
                <div className={stylesEmployeeLeaderboard.userAvatar}>
                  {leaderBoard[1] && leaderBoard[1].profilePhoto ? (
                    <div className={stylesEmployeeLeaderboard.borderImage}>
                      <img src={leaderBoard[1].profilePhoto} alt="" />
                    </div>
                  ) : null}
                </div>
                <div className={stylesEmployeeLeaderboard.bgImage}>
                  <div className={stylesEmployeeLeaderboard.name}>
                    {leaderBoard[1] && <p>{leaderBoard[1].name}</p>}
                  </div>
                  <div className={stylesEmployeeLeaderboard.info}>
                    {leaderBoard[1] ? (
                      <div className={stylesEmployeeLeaderboard.detail}>
                        <span>
                          {Math.round(parseFloat(leaderBoard[1].totalMiles))} mi
                        </span>
                        <span className={stylesEmployeeLeaderboard.divide}>
                          |
                        </span>
                        <span>
                          ${parseFloat(leaderBoard[1].totalMoney).toFixed(2)}
                        </span>
                      </div>
                    ) : (
                      <div className={stylesEmployeeLeaderboard.detail}></div>
                    )}
                  </div>
                  <div className={stylesEmployeeLeaderboard.no}>2</div>
                </div>
              </div>
            </div>
          </div>
          {(!leaderBoard || !leaderBoard.length) && (
            <div className={styles.noContent} style={{ height: "90px" }}>
              <CopyToClipboard
                text={config.appUrl}
                onCopy={() => setIsCopied(true)}
              >
                <Button className={styles.btnInvite}>Invite Employees</Button>
              </CopyToClipboard>
              {isCopied && (
                <div className={styles.copiedClipboard}>
                  <div className={styles.message}>Copied to clipboard!</div>
                </div>
              )}
            </div>
          )}
          {rows.length ? (
            <div className={stylesEmployeeLeaderboard.tableBoard}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell
                      align="center"
                      className={stylesEmployeeLeaderboard.run}
                    >
                      Miles
                    </TableCell>
                    <TableCell
                      align="center"
                      className={stylesEmployeeLeaderboard.cash}
                    >
                      Cash
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <Table>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={row.userUnid}>
                      <TableCell
                        align="center"
                        className={stylesEmployeeLeaderboard.no}
                      >
                        {index + 4}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={stylesEmployeeLeaderboard.userAvatar}
                      >
                        <img src={row.profilePhoto} alt="" />
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell
                        align="center"
                        className={stylesEmployeeLeaderboard.run}
                      >
                        {Math.round(parseFloat(row.totalMiles))} mi
                      </TableCell>
                      <TableCell
                        align="center"
                        className={stylesEmployeeLeaderboard.cash}
                      >
                        ${parseFloat(row.totalMoney).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : null}
        </div>
      </div>
      {
        openExportCsvModal && isClickExport && (
<ExportCsvModal 
        handleCloseExportCsvModal={handleCloseExportCsvModal}
      />
        )
      }
      
    </Paper>
  );
};

EmployeeLeaderboardComponent.propTypes = {
  dataLeaderboard: PropTypes.object.isRequired,
  action: PropTypes.shape({
    getLeaderboard: PropTypes.func.isRequired,
    clearCSV: PropTypes.func.isRequired,
    getCSVActivitiesLeaderboard: PropTypes.func,
    getCSVLeaderboard: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    dataLeaderboard: state.dashboard.dataLeaderboard,
    dataCSV: state.dashboard.dataCSV,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      {
        getLeaderboard,
        getCSVLeaderboard,
        clearCSV,
        getCSVActivitiesLeaderboard,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeLeaderboardComponent);
