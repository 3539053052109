import { call, put, takeLatest } from "redux-saga/effects";
import { types, urls } from "./index";
import { apiGet, apiGetText, apiPut } from "src/utils/api";
import notify from "src/utils/notify";

function* getCsvActivitiesLeaderboard(action) {
  try {
    const response = yield call(apiGetText, {
      path: urls.CSV_ACTIVITIES_LEADERBOARD,
      payload: action.payload,
    });
    if (response.error) {
      yield put({
        type: types.GET_CSV_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.GET_CSV_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_CSV_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* getCsvDetailChallenge({ challengeUnid }) {
  try {
    const response = yield call(apiGetText, {
      path: urls.GET_CSV_DETAIL_CHALLENGE(challengeUnid),
      payload: {},
    });
    if (response.error) {
      yield put({
        type: types.GET_CSV_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.GET_CSV_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_CSV_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* getCsvPastChallenge({ challengeUnid }) {
  try {
    const response = yield call(apiGetText, {
      path: urls.GET_CSV_PAST_CHALLENGE(challengeUnid),
      payload: {},
    });
    if (response.error) {
      yield put({
        type: types.GET_CSV_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.GET_CSV_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_CSV_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* getRegisteredUsers() {
  try {
    const response = yield call(apiGet, {
      path: urls.REGISTERED_USERS,
    });
    if (response.error) {
      yield put({
        type: types.REGISTERED_USERS_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.REGISTERED_USERS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.REGISTERED_USERS_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* getTotalImpact(action) {
  try {
    const response = yield call(apiGet, {
      path: urls.TOTAL_IMPACT,
      payload: action.payload,
    });
    if (response.error) {
      yield put({
        type: types.TOTAL_IMPACT_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.TOTAL_IMPACT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.TOTAL_IMPACT_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* getCurrentSponsorship() {
  try {
    const response = yield call(apiGet, {
      path: urls.CURRENT_SPONSORSHIP,
    });
    if (response.error) {
      yield put({
        type: types.CURRENT_SPONSORSHIP_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.CURRENT_SPONSORSHIP_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.CURRENT_SPONSORSHIP_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* getMilesCompleted(action) {
  try {
    const response = yield call(apiGet, {
      path: urls.MILES_COMPLETED,
      payload: action.payload,
    });
    if (response.error) {
      yield put({
        type: types.MILES_COMPLETED_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.MILES_COMPLETED_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.MILES_COMPLETED_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* getChallenges(action) {
  try {
    const response = yield call(apiGet, {
      path: urls.CHALLENGES,
      payload: action.payload,
    });
    if (response.error) {
      yield put({
        type: types.CHALLENGES_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.CHALLENGES_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.CHALLENGES_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* getLeaderboard(action) {
  try {
    const response = yield call(apiGet, {
      path: urls.LEADERBOARD,
      payload: action.payload,
    });
    if (response.error) {
      yield put({
        type: types.LEADERBOARD_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.LEADERBOARD_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.LEADERBOARD_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* getCSVLeaderboard(action) {
  try {
    const response = yield call(apiGetText, {
      path: urls.CSV_LEADERBOARD,
      payload: action.payload,
    });
    if (response.error) {
      yield put({
        type: types.GET_CSV_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.GET_CSV_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_CSV_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* getCSVChallenges(action) {
  try {
    const response = yield call(apiGetText, {
      path: urls.CSV_CHALLENGES,
      payload: action.payload,
    });
    if (response.error) {
      yield put({
        type: types.GET_CSV_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.GET_CSV_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_CSV_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* getCSVSponsorship(action) {
  try {
    const response = yield call(apiGetText, {
      path: urls.CSV_SPONSORSHIP,
      payload: action.payload,
    });
    if (response.error) {
      yield put({
        type: types.GET_CSV_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.GET_CSV_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_CSV_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* getCSVImpact(action) {
  try {
    const response = yield call(apiGetText, {
      path: urls.CSV_IMPACT,
      payload: action.payload,
    });
    if (response.error) {
      yield put({
        type: types.GET_CSV_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.GET_CSV_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_CSV_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* getCSVUsers(action) {
  try {
    const response = yield call(apiGetText, {
      path: urls.CSV_REGISTERED_USERS,
      payload: action.payload,
    });
    if (response.error) {
      yield put({
        type: types.GET_CSV_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.GET_CSV_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_CSV_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* getCSVMilesCompleted(action) {
  try {
    const response = yield call(apiGetText, {
      path: urls.CSV_MILES_COMPLETED,
      payload: action.payload,
    });
    if (response.error) {
      yield put({
        type: types.GET_CSV_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.GET_CSV_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_CSV_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* setMessageDashboard(action) {
  yield put({
    type: types.UPDATE_MESSAGE_DASHBOARD,
    payload: action.payload,
  });
}

function* getSponsorshipById({ unid }) {
  try {
    const response = yield call(apiGet, {
      path: urls.SPONSORSHIP_BY_ID(unid),
      payload: {},
    });
    if (response.error) {
      yield put({
        type: types.GET_SPONSORSHIP_BY_ID_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.GET_SPONSORSHIP_BY_ID_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_SPONSORSHIP_BY_ID_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* getCsvChallengesById(action) {
  try {
    const response = yield call(apiGetText, {
      path: urls.CSV_CHALLENGES_BY_ID(action.challengesId),
      payload: action.payload,
    });
    if (response.error) {
      yield put({
        type: types.GET_CSV_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.GET_CSV_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_CSV_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* editCurrentSponsorship({ sponsorship, unid, status }) {
  try {
    const response = yield call(apiPut, {
      path: urls.EDIT_SPONSORSHIP_BY_ID(unid),
      payload: sponsorship,
    });
    if (response.error) {
      yield put({
        type: types.EDIT_SPONSORSHIP_BY_ID_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.OPEN_MODAL_EDIT_SPONSORSHIP,
      });
      yield put({
        type: types.CURRENT_SPONSORSHIP_REQUEST
      })
      yield put({
        type: types.EDIT_SPONSORSHIP_BY_ID_SUCCESS,
        payload: response,
        status,
      });
      yield put({
        type: types.CURRENT_SPONSORSHIP_REQUEST,
      });
      /* https://github.com/bohe-in/cm-ee-portal/issues/60
      if (sponsorship.closeSponsorship) {
        yield put({
          type: types.CLOSE_SPONSORSHIP,
        });
        return;
      }*/
    }
  } catch (error) {
    yield put({
      type: types.EDIT_SPONSORSHIP_BY_ID_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}
export default function* dashboardWatcher() {
  yield takeLatest(types.REGISTERED_USERS_REQUEST, getRegisteredUsers);
  yield takeLatest(types.TOTAL_IMPACT_REQUEST, getTotalImpact);
  yield takeLatest(types.CURRENT_SPONSORSHIP_REQUEST, getCurrentSponsorship);
  yield takeLatest(types.MILES_COMPLETED_REQUEST, getMilesCompleted);
  yield takeLatest(types.CHALLENGES_REQUEST, getChallenges);
  yield takeLatest(types.LEADERBOARD_REQUEST, getLeaderboard);
  yield takeLatest(types.CSV_LEADERBOARD_REQUEST, getCSVLeaderboard);
  yield takeLatest(types.CSV_CHALLENGES_REQUEST, getCSVChallenges);
  yield takeLatest(types.CSV_SPONSORSHIP_REQUEST, getCSVSponsorship);
  yield takeLatest(types.CSV_IMPACT_REQUEST, getCSVImpact);
  yield takeLatest(types.CSV_REGISTERED_USERS_REQUEST, getCSVUsers);
  yield takeLatest(types.CSV_MILES_COMPLETED_REQUEST, getCSVMilesCompleted);
  yield takeLatest(types.SET_MESSAGE_DASHBOARD, setMessageDashboard);
  yield takeLatest(
    types.EDIT_SPONSORSHIP_BY_ID_REQUEST,
    editCurrentSponsorship
  );
  yield takeLatest(types.GET_SPONSORSHIP_BY_ID_REQUEST, getSponsorshipById);
  yield takeLatest(types.GET_CSV_PAST_CHALLENGE_REQUEST, getCsvPastChallenge);
  yield takeLatest(types.CSV_CHALLENGES_BY_ID_REQUEST, getCsvChallengesById);
  yield takeLatest(
    types.GET_CSV_DETAIL_CHALLENGE_REQUEST,
    getCsvDetailChallenge
  );
  yield takeLatest(
    types.GET_CSV_ACTIVITEIE_LEADERBOARD_REQUEST,
    getCsvActivitiesLeaderboard
  );
}
