import { types } from './index';

export const nextStep = (payload,) => ({
    type: types.NEXT_STEP,
    payload,
});

export const saveStep = (payload,) => ({
  type: types.ONBOARDING_REQUEST,
  payload,
});
