import {call, put, takeLatest} from 'redux-saga/effects';
import {types, urls} from './index';
import {apiPost} from 'src/utils/api';
import notify from 'src/utils/notify';

function* calcAmount(action) {
  try {
    const response = yield call(apiPost, {
      path: urls.CALC_AMOUNT,
      payload: action.payload
    });
    if (response.error) {
      yield put({
        type: types.CALC_AMOUNT_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.CALC_AMOUNT_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.CALC_AMOUNT_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* createSponsorship(action) {
  try {
    let url;
    if (action.payload.challengeUnid) {
      url = urls.CREATE_SPONSORSHIP.replace('unid', action.payload.challengeUnid);
    } else {
      url = urls.CREATE_SPONSORSHIP_WITHOUT_CHALLENGE;
    }
    delete action.payload.challengeUnid;
    delete action.payload.charityOthers;
    const response = yield call(apiPost, {
      path: url,
      payload: action.payload
    });
    if (response.error) {
      yield put({
        type: types.CREATE_SPONSORSHIP_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.CREATE_SPONSORSHIP_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.CREATE_SPONSORSHIP_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

export default function* sponsorshipWatcher() {
  yield takeLatest(types.CALC_AMOUNT_REQUEST, calcAmount);
  yield takeLatest(types.CREATE_SPONSORSHIP_REQUEST, createSponsorship);
}
