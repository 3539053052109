import { types } from './index';

export const payNow = (payload) => ({
    type: types.PAY_NOW_REQUEST,
    payload,
});

export const getPaymentPlan = () => ({
    type: types.PAYMENT_PLAN_REQUEST
});

export const getBillingPlan = () => ({
  type: types.BILLING_PLAN_REQUEST,
});

export const getInvoices = (payload) => ({
  type: types.INVOICES_REQUEST,
});

export const updateCard = (payload) => ({
  type: types.UPDATE_CARD_REQUEST,
  payload,
});
