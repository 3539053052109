import React from 'react';
import styles from '../index.module.scss';
import {Button} from '@material-ui/core';
import PropTypes from "prop-types";
import Paper from 'src/components/Paper';

export const PayNowComponent = (props) => {
    const {loading, dataPaymentPlan, currentStep} = props;

    const plan = dataPaymentPlan.plans ? dataPaymentPlan.plans[0] : null;

    return (
        <Paper
            className={styles.paper}
            loading={loading || dataPaymentPlan.loading}
        >
            {
                plan && (
                    <div className={styles.payNowDetail}>
                        <div className={styles.box}>
                            <p><b>{plan.name}</b></p>
                            <p>Billed monthly</p>
                            <p>1 year commitment</p>
                        </div>
                        <div className={styles.box}>
                            <div className={styles.dFlexBetween}>
                                <span>{plan.name}</span>
                                <span>${parseFloat(plan.price).toFixed(2)}</span>
                            </div>
                            <div className={styles.dFlexBetween}>
                                <span>Sales Tax</span>
                                <span>${parseFloat(plan.taxes).toFixed(2)}</span>
                            </div>
                        </div>
                        <div className={styles.box}>
                            <div className={styles.dFlexBetween}>
                                <span><b>Due now</b></span>
                                <span><b>${(parseFloat(plan.taxes) + parseFloat(plan.price)).toFixed(2)}</b></span>
                            </div>
                        </div>
                        <div className={styles.box}>
                            <Button onClick={props.onPayNow} disabled={parseInt(currentStep) !== 3} fullWidth
                                    className={parseInt(currentStep) === 3 ? `${styles.btnPayNow} ${styles.btnPayNowActive}` : styles.btnPayNow}>
                                Pay now
                            </Button>
                        </div>
                    </div>
                )
            }
        </Paper>
    );
};

PayNowComponent.propTypes = {
    currentStep: PropTypes.number.isRequired,
    maxStep: PropTypes.number.isRequired,
    onPayNow: PropTypes.func.isRequired,
    dataPaymentPlan: PropTypes.object.isRequired,
};

export default PayNowComponent;
