import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { Modal, Input, Button } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { red, lightBlue } from '@material-ui/core/colors';
import MomentUtils from "@date-io/moment";
import styles from '../modal.module.scss'
import moment from 'moment';
import _ from 'lodash';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import timezone from 'moment-timezone'
import dateUtils from '../../../utils/date'
import Status from './SponsorshipStatus';

timezone.tz.setDefault();
//#region Modal
function getModalStyle() {
    const top = 50;
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        borderRadius: 10,
        background: 'linear-gradient(198.99deg, #FCFEFF 7.06%, #D9EAE4 92.38%)',
    };
}
const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '100%',
        // border: '1px solid #000',
        padding: theme.spacing(2, 4, 3),
        background: '#c4c4c4',
        maxWidth: '700px',
        outline: 'none',
        boxShadow: '0px 15px 80px rgba(91, 109, 98, 1)'
    },

    h1: {
        fontSize: "40px",
        color: "#333",
        fontWeight: 300,
    }
}));

const DeleteButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
}))(Button);
const EndTodayButton = withStyles((theme) => ({
root: {
    color: theme.palette.getContrastText(lightBlue[500]),
    backgroundColor: lightBlue[500],
    '&:hover': {
    backgroundColor: lightBlue[700],
    },
},
}))(Button);
//#endregion

export default function EditSponsorship(props) {

    // modal styles
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    // system state
    const [errorBudget, setErrorBudget] = useState(false);
    const [errorEndDate, setErrorEndDate] = useState(false);
    const [errorValidateEndDate, setErrorValidateEndDate] = useState(false);
    const [budget, setBudget] = useState(0)
    const [budgetMock, setBudgetMock] = useState(0)
    const [endDate, setEndDate] = useState(new Date())
    const [status, setStatus] = useState(Status.NO_SPONSOR)
    const [isMonthly, setMonthly] = useState(false)

    const [errorEmployeeRun, setErrorEmployeeRun] = useState(false);
    const [errorEmployeeBike, setErrorEmployeeBike] = useState(false);
    const [errorCompanyRun, setErrorCompanyRun] = useState(false);
    const [errorCompanyBike, setErrorCompanyBike] = useState(false);

    const [charity, setCharity] = useState({
        companyCharityMPMRun: 0,
        companyCharityMPMBike: 0,
        employeeCharityMPMRun: 0,
        employeeCharityMPMBike: 0,
    })

    useEffect(() => {
        if (props.currentSponsorship.upcomingSponsorships && props.currentSponsorship.upcomingSponsorships[0]) {
            const { budget, endDate, monthly, compCharityMPMBike, compCharityMPMRun, emplCharityMPMRun, emplCharityMPMBike } = props.currentSponsorship.upcomingSponsorships[0]
            setBudget(budget)
            setBudgetMock(budget)
            setEndDate(moment(endDate))
            setMonthly(monthly)
            setCharity({
                companyCharityMPMRun: compCharityMPMRun,
                companyCharityMPMBike: compCharityMPMBike,
                employeeCharityMPMRun: emplCharityMPMRun,
                employeeCharityMPMBike: emplCharityMPMBike,
            })
        }
    }, [props.currentSponsorship])

    useEffect(() => {
        if (props.sponsorshipById && props.sponsorshipById.data) {
            const { budget, endDate, monthly,
                compCharityMPMBike,
                compCharityMPMRun,
                emplCharityMPMBike,
                emplCharityMPMRun, } = props.sponsorshipById.data.data.sponsorship
            setEndDate(moment(endDate))
            setMonthly(monthly)
            setBudget(budget)
            setBudgetMock(budget)
            setCharity({
                companyCharityMPMRun: compCharityMPMRun,
                companyCharityMPMBike: compCharityMPMBike,
                employeeCharityMPMRun: emplCharityMPMRun,
                employeeCharityMPMBike: emplCharityMPMBike,
            })
        }
    }, [props.sponsorshipById])

    useEffect(() => {
        setStatus(props.status)
    }, [props.status])

    const handleChange = (e, name) => {
        const obj = {}
        obj[name] = e.target.value
        setCharity({ ...charity, ...obj })
        if (name === 'companyCharityMPMBike' && e.target.value) {
            setErrorCompanyBike(false)
        }
        if (name === 'companyCharityMPMRun' && e.target.value) {
            setErrorCompanyRun(false)
        }
        if (name === 'employeeCharityMPMRun' && e.target.value) {
            setErrorEmployeeRun(false)
        }
        if (name === 'employeeCharityMPMBike' && e.target.value) {
            setErrorEmployeeBike(false)
        }
    }

    const isNull = (value) => {
        return value === '' || value === null;
    };

    const validateData = () => {
        let isValid = true;
        const { companyCharityMPMRun, companyCharityMPMBike, employeeCharityMPMRun, employeeCharityMPMBike } = charity;

        if (budget < budgetMock) {
            setErrorBudget(true)
            isValid = false
        }
        if (!companyCharityMPMRun && isNull(companyCharityMPMBike) && isNull(employeeCharityMPMRun) && isNull(employeeCharityMPMBike)) {
            setErrorEmployeeRun(true);
            setErrorEmployeeBike(true);
            isValid = false;
        } else if (companyCharityMPMRun && isNull(companyCharityMPMBike) && isNull(employeeCharityMPMRun) && isNull(employeeCharityMPMBike)) {
            setErrorCompanyBike(true);
            isValid = false;
        } else if (isNull(companyCharityMPMRun) && companyCharityMPMBike && isNull(employeeCharityMPMRun) && isNull(employeeCharityMPMBike)) {
            setErrorCompanyRun(true);
            isValid = false;
        } else if ((isNull(companyCharityMPMRun) || isNull(companyCharityMPMBike)) && isNull(employeeCharityMPMRun) && employeeCharityMPMBike) {
            setErrorEmployeeRun(true);
            isValid = false;
        } else if ((isNull(companyCharityMPMRun) || isNull(companyCharityMPMBike)) && employeeCharityMPMRun && isNull(employeeCharityMPMBike)) {
            setErrorEmployeeBike(true);
            isValid = false;
        }

        return isValid;
    };

    const handleEditSponsorship = (data) => {
        if (!validateData()) {
            return
        } else {
            let bodyData={
                budget: Number(budget),
                endDate: isMonthly ? null : dateUtils.apiFormatOfDate(endDate.startOf("day")),
                companyCharityMPMRun: Number(charity.companyCharityMPMRun),
                companyCharityMPMBike: Number(charity.companyCharityMPMBike),
                employeeCharityMPMRun: Number(charity.employeeCharityMPMRun),
                employeeCharityMPMBike: Number(charity.employeeCharityMPMBike),
                ...data
            };
            props.handleEditSponsorship(bodyData)
        }
    }

    const handleChangeBudget = (event) => {
        if (!_.isNaN(Number(event.target.value))) {
            setBudget(event.target.value);
        }

        if ((!event.target.value || !Number.isInteger(parseInt(event.target.value))
            || parseInt(event.target.value) < 1)) {
            setErrorBudget(true);
        } else {
            setErrorBudget(false);
        }
    };

    const handleEndDateChange = date => {
        setEndDate(date);
    };

    const onErrorEndDate = (err, value) => {
        if (err || !value) {
            setErrorEndDate(true);
        } else {
            setErrorEndDate(false);
            setErrorValidateEndDate(false);
        }
    };

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpenModalEditSponsorship}
            onClose={props.handleOpenSponsorship}
        >
            <div style={modalStyle} className={classes.paper}>
                <h1 className={classes.h1} id="simple-modal-title">Edit Sponsorship</h1>
                <div className={styles.modalFormInput}>
                    <div className={styles.middle}>
                        <p style={{ minWidth: '100px' }}> Budget: </p>&ensp;
                        <FormControl className={styles.inputData}>
                            <Input
                                id="standard-adornment-amount"
                                value={budget}
                                onChange={handleChangeBudget}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            />

                        </FormControl>
                        {errorBudget && <p style={{ color: 'red' }}>New budget can not be less than old budget.</p>}

                    </div>

                    {
                        !isMonthly && (
                            <div className={styles.middle}>
                                <p style={{ minWidth: '100px' }}>Ending on:</p> &ensp;
                                <div className={styles.inputDate}>
                                    <MuiPickersUtilsProvider utils={MomentUtils} className={styles.inputDate}>
                                        <KeyboardDatePicker
                                            onError={onErrorEndDate}
                                            error={errorValidateEndDate}
                                            disableToolbar
                                            variant="inline"
                                            format="MM/DD/YYYY"
                                            margin="normal"
                                            value={endDate}
                                            autoOk={true}
                                            onChange={handleEndDateChange}
                                            name="endDate"
                                            minDate={Date.now()}
                                            placeholder="mm/dd/yyyy"
                                            keyboardIcon={<img src={require('src/assets/images/icon-calendar.svg')} alt="" />}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className={styles.charity}>
                    <div>
                        <div className={styles.typeCharity}>
                            <p>
                                Employee’s Charity
                  </p>
                            <img src={require('src/assets/images/hint.svg')} alt="hint" />
                        </div>
                        <div className={styles.inputData}>
                            <img src={require('src/assets/images/walk.svg')} alt="walk" />
                            <Input placeholder="0.05" type="number"
                                onChange={(e) => {
                                    handleChange(e, 'employeeCharityMPMRun')
                                }}
                                value={charity.employeeCharityMPMRun} error={errorEmployeeRun} name="employeeCharityMPMRun" />
                        </div>
                        <div className={styles.inputData}>
                            <img src={require('src/assets/images/bike.svg')} alt="bike" />
                            <Input placeholder="0.05" type="number"
                                onChange={(e) => {
                                    handleChange(e, 'employeeCharityMPMBike')
                                }}
                                value={charity.employeeCharityMPMBike} error={errorEmployeeBike}
                                name="employeeCharityMPMBike" />
                        </div>
                    </div>
                    <div>
                        <div className={styles.typeCharity}>
                            <p>
                                Company’s Charity
                  </p>
                            <img src={require('src/assets/images/hint.svg')} alt="hint" />
                        </div>
                        <div className={styles.inputData}>
                            <img src={require('src/assets/images/walk.svg')} alt="walk" />
                            <Input placeholder="0.05" type="number"
                                onChange={(e) => {
                                    handleChange(e, 'companyCharityMPMRun')
                                }}
                                value={charity.companyCharityMPMRun} error={errorCompanyRun} name="companyCharityMPMRun" />
                        </div>
                        <div className={styles.inputData}>
                            <img src={require('src/assets/images/bike.svg')} alt="bike" />
                            <Input placeholder="0.05" type="number"
                                onChange={(e) => {
                                    handleChange(e, 'companyCharityMPMBike')
                                }}
                                value={charity.companyCharityMPMBike}
                                error={errorCompanyBike} name="companyCharityMPMBike" />
                        </div>
                    </div>
                </div>
                <div style={{
                    marginTop: '1rem'
                }}>

                    <Button onClick={()=>{
                        handleEditSponsorship({
                            "delete": false,
                            "closeSponsorship": false
                        })
                    }} variant="contained" color="primary">Save</Button>&ensp;
                    {
                        status === Status.IN_PROGRESS && 
                        <><EndTodayButton onClick={()=>{
                                handleEditSponsorship({
                                    "delete": false,
                                    "closeSponsorship": true
                                })
                            }} variant="contained">End Sponsorship Today</EndTodayButton>&ensp;</>
                    }
                    <DeleteButton onClick={()=>{
                        handleEditSponsorship({
                            "delete": true,
                            "closeSponsorship": false
                        })
                        props.handleDeleteSponsorship()
                    }} variant="contained">Delete</DeleteButton>&ensp;
                    <Button onClick={props.handleOpenSponsorship} variant="contained" color="secondary">Cancel</Button>
                </div>
            </div>
        </Modal >
    )
}
EditSponsorship.propTypes = {
    isOpenModalEditSponsorship: PropTypes.bool,
    handleOpenSponsorship: PropTypes.func,
    handleEditSponsorship: PropTypes.func,
    handleDeleteSponsorship: PropTypes.func,
    currentSponsorship: PropTypes.shape({
        current: PropTypes.shape({
            budget: PropTypes.number
        })
    }),
    sponsorshipById: PropTypes.shape({
        data: PropTypes.shape({
            data: PropTypes.shape({
                unid: PropTypes.string,
                name: PropTypes.string,
                endDate: PropTypes.string,
                budget: PropTypes.number,
                monthly: PropTypes.bool
            })
        })
    }),
    status: PropTypes.string
}

