import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import styles from "../index.module.scss";
import Paper from "src/components/Paper";
import {bindActionCreators} from "redux";
import {getBillingPlan, updateCard} from "src/redux/billing/action";
import {connect} from "react-redux";
import {Button} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import Fade from '@material-ui/core/Fade';
import {useFormik} from "formik";
import * as Yup from "yup";
import TextField from 'src/components/TextField';
import AuthStorage from "../../../utils/authStorage";
import moment from "moment";
import {FormatMoney} from "../../../utils";
import config from "../../../config/config";

export const YourPlanComponent = (props) => {
  const {dataBillingPlan, dataUpdateCard} = props;
  const {billingPlan} = dataBillingPlan;
  const [openModalPayment, setOpenModalPayment] = useState(false);
  const [stripe, setStripe] = useState(null);
  const [elementsStripe, setElementsStripe] = useState([]);

  useEffect(() => {
    // props.action.getBillingPlan();
    const stripeKey = config.stripe.key;
    const stripeUrl = "https://js.stripe.com/v3/";
    if (!document.querySelector("#stripe-js")) {
      const script = document.createElement("script");
      script.async = true;
      script.id = "stripe-js";
      script.onload = () => {
        setStripe(window.Stripe(stripeKey));
      };
      document.head.appendChild(script);
      script.src = stripeUrl;
    } else if (window.Stripe) {
      setStripe(window.Stripe(stripeKey));
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (parseInt(dataUpdateCard.success) === 0) {
      setOpenModalPayment(false);
      // props.action.getBillingPlan()
    }
    // eslint-disable-next-line
  }, [dataUpdateCard]);

  const getTokenStripe = (values) => {
    const checkoutForm = document.querySelector('.checkout-stripe');
    const form = checkoutForm.querySelector('form');
    let plainInputsValid = true;
    Array.prototype.forEach.call(form.querySelectorAll('input'), function (
      input
    ) {
      if (input.checkValidity && !input.checkValidity()) {
        plainInputsValid = false;
        return;
      }
    });
    if (!plainInputsValid) {
      triggerBrowserValidation(form);
      return;
    }

    checkoutForm.classList.add('submitting');
    disableInputs(form);
    let additionalData = {
      name: AuthStorage.getName(),
      address_line1: values.address ? values.address : undefined,
      address_city: values.city ? values.city : undefined,
      address_state: values.state ? values.state : undefined,
      address_zip: values.zipCode ? values.zipCode : undefined,
    };

    stripe.createToken(elementsStripe[0], additionalData).then(function (result) {
      checkoutForm.classList.remove('submitting');
      if (result.token) {
        savePaymentInfo(result.token, values)
      } else {
        enableInputs(form);
      }
    });
  };

  const initStripe = () => {
    let elements = stripe.elements();
    let inputs = document.querySelectorAll('.checkout-stripe .input');
    Array.prototype.forEach.call(inputs, function (input) {
      input.addEventListener('focus', function () {
        input.classList.add('focused');
      });
      input.addEventListener('blur', function () {
        input.classList.remove('focused');
      });
      input.addEventListener('keyup', function () {
        if (input.value.length === 0) {
          input.classList.add('empty');
        } else {
          input.classList.remove('empty');
        }
      });
    });

    const elementStyles = {
      base: {
        color: '#32325D',
        fontWeight: 300,
        fontFamily: '"Charity Miles", Roboto, Helvetica, Arial, sans-serif',
        fontSize: '16px',

        '::placeholder': {
          color: '#5C8571',
        },
        ':-webkit-autofill': {
          color: '#e39f48',
        },
      },
      invalid: {
        color: '#f44336',

        '::placeholder': {
          color: '#5C8571',
        },
      },
    };

    let elementClasses = {
      focus: 'focused',
      empty: 'empty',
      invalid: 'invalid',
    };

    let cardNumber = elements.create('cardNumber', {
      style: elementStyles,
      classes: elementClasses,
    });
    cardNumber.mount('#checkout-card-number');

    let cardExpiry = elements.create('cardExpiry', {
      style: elementStyles,
      classes: elementClasses,
    });
    cardExpiry.mount('#checkout-card-expiry');

    let cardCvc = elements.create('cardCvc', {
      style: elementStyles,
      classes: elementClasses,
    });
    cardCvc.mount('#checkout-card-cvc');
    setElementsStripe([cardNumber, cardExpiry, cardCvc]);
    registerElements([cardNumber, cardExpiry, cardCvc], 'checkout-stripe');
  };

  const triggerBrowserValidation = (form) => {
    let submit = document.createElement('input');
    submit.type = 'submit';
    submit.style.display = 'none';
    form.appendChild(submit);
    submit.click();
    submit.remove();
  };

  const enableInputs = (form) => {
    Array.prototype.forEach.call(
      form.querySelectorAll(
        "input[type='text'], input[type='email'], input[type='tel']"
      ),
      function (input) {
        input.removeAttribute('disabled');
      }
    );
  };

  const disableInputs = (form) => {
    Array.prototype.forEach.call(
      form.querySelectorAll(
        "input[type='text'], input[type='email'], input[type='tel']"
      ),
      function (input) {
        input.setAttribute('disabled', 'true');
      }
    );
  };

  const registerElements = (elements, checkoutName) => {
    let formClass = '.' + checkoutName;
    let checkoutForm = document.querySelector(formClass);

    let form = checkoutForm.querySelector('form');

    let savedErrors = {};
    elements.forEach(function (element, idx) {
      element.on('change', function (event) {
        if (event.error) {
          savedErrors[idx] = event.error.message;
        } else {
          savedErrors[idx] = null;
        }
      });
    });

    form.addEventListener('submit', function (e) {
      e.preventDefault();

    });
  };

  const updatePaymentInfo = () => {
    setOpenModalPayment(true);
    setTimeout(() => {
      initStripe();
    }, 300)
  };

  const closeModalPayment = () => {
    setOpenModalPayment(false);
  };

  const savePaymentInfo = (stripeToken, values) => {
    const data = {
      "cardID": stripeToken.card.id,
      "newCard": {
        "tokenID": stripeToken.id
      },
      "billingAddress": values
    };
    props.action.updateCard(data)
  };

  const formik = useFormik({
    initialValues: {
      firstName: billingPlan ? billingPlan.membership.card.billingAddress.firstName : '',
      lastName: billingPlan ? billingPlan.membership.card.billingAddress.lastName : '',
      address: billingPlan ? billingPlan.membership.card.billingAddress.address : '',
      address2: billingPlan ? billingPlan.membership.card.billingAddress.address2 : '',
      city: billingPlan ? billingPlan.membership.card.billingAddress.city : '',
      state: billingPlan ? billingPlan.membership.card.billingAddress.state : '',
      zipCode: billingPlan ? billingPlan.membership.card.billingAddress.zipCode : '',
      country: billingPlan ? billingPlan.membership.card.billingAddress.country : '',
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string(),
      lastName: Yup.string(),
      address: Yup.string(),
      address2: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      zipCode: Yup.string(),
      country: Yup.string(),
    }),
    onSubmit: values => {
      getTokenStripe(values);
    },
  });

  return (
    <Paper loading={dataBillingPlan.loading}
           className={styles.paper}
    >
      {
        billingPlan &&
        <div className={styles.yourPlan}>
          <div className={styles.planInfo}>
            <div className={styles.name}>
              {billingPlan.plan.name}
            </div>
            <p>
              ${billingPlan.plan.price} per month<br/>
              2 challenge limit<br/>
              up to 250 employees
            </p>
          </div>
          <Grid className={styles.paymentInfo} container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div className={styles.blockInfo}>
                <div className={styles.title}>
                  Membership Payment (1 Year Commitment)
                </div>
                <div className={styles.detail}>
                  <img src={require("src/assets/images/visa.svg")} alt="visa"/>
                  <div className={styles.cardInfo}>
                    <p>
                      xxxx xxxx xxxx xxxx {billingPlan.membership.card.last4}&nbsp;&nbsp;&nbsp;&nbsp;{billingPlan.membership.card.expMonth}/{billingPlan.membership.card.expYear}<br/>
                      {billingPlan.membership.card.billingAddress.address}, {billingPlan.membership.card.billingAddress.city},&nbsp;
                      {billingPlan.membership.card.billingAddress.state}, {billingPlan.membership.card.billingAddress.zipCode}<br/>
                      <br/>
                    </p>
                    <p>
                      Paid (5 of 12 payments)<br/>
                      Expires on {moment.utc(billingPlan.membership.membershipExpires).format('MMMM DD')}, {moment.utc(billingPlan.membership.membershipExpires).format('YYYY')}<br/>
                      Next due: {moment.utc(billingPlan.membership.upcomingInvoice.date).format('MM/DD/YYYY')} - {FormatMoney(billingPlan.membership.upcomingInvoice.amount)}<br/>
                    </p>
                    <div className={styles.upgrade}>
                      <Button
                        color="secondary"
                        onClick={updatePaymentInfo}
                        className={styles.btnUpgrade}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={styles.blockInfo}>
                <div className={styles.title}>
                  Sponsorship Payment
                </div>
                <div className={styles.detail}>
                  {
                    !billingPlan.sponsorship &&
                      <p>You have no sponsorships set up at this time.</p>
                  }
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      }
      <Modal
        className={styles.modalPayment}
        open={openModalPayment}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onClose={closeModalPayment}
      >
        <Fade in={openModalPayment}>
          <div className={styles.contentModal}>
            <div className={styles.title}>
              Add Credit Card
            </div>
            <img src={require("src/assets/images/icon-remove.svg")}
                 alt=""
                 className={styles.iconClose}
                 onClick={closeModalPayment}/>
            <div className="checkout-stripe update">
              <form action="">
                <div className="row">
                  <div className="field card-number">
                    <div id="checkout-card-number" className="input empty"></div>
                    <label htmlFor="checkout-card-number" data-tid="elements_examples.form.card_number_label">Credit
                      Card
                      Number</label>
                    <div className="baseline"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="field">
                    <div id="checkout-card-expiry" className="input empty"></div>
                    <label htmlFor="checkout-card-expiry"
                           data-tid="elements_examples.form.card_expiry_label">Expiration Date</label>
                    <div className="baseline"></div>
                  </div>
                  <div className="field">
                    <div id="checkout-card-cvc" className="input empty"></div>
                    <label htmlFor="checkout-card-cvc"
                           data-tid="elements_examples.form.card_cvc_label">CVC</label>
                    <div className="baseline"></div>
                  </div>
                </div>
              </form>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.body}>
                <p className={styles.titleBilling}>Billing Address</p>
                <div className={styles.dFlexBetween}>
                  <div className={styles.inputWrap}>
                    <TextField
                      label="First name"
                      name="firstName"
                      formik={formik}
                    />
                  </div>
                  <div className={styles.inputWrap}>
                    <TextField
                      label="Last name"
                      name="lastName"
                      formik={formik}
                    />
                  </div>
                </div>
                <div className={styles.inputWrap}>
                  <TextField
                    label="Address"
                    name="address"
                    formik={formik}
                  />
                </div>
                <div className={styles.inputWrap}>
                  <TextField
                    label="Apartment, suite, etc."
                    name="address2"
                    formik={formik}
                  />
                </div>
                <div className={styles.inputWrap}>
                  <TextField
                    label="City"
                    name="city"
                    formik={formik}
                  />
                </div>
                <div className={styles.dFlexBetween}>
                  <div className={styles.inputWrap}>
                    <TextField
                      label="Country/Region"
                      name="country"
                      formik={formik}
                    />
                  </div>
                  <div className={styles.inputWrap}>
                    <TextField
                      label="State"
                      name="state"
                      formik={formik}
                    />
                  </div>
                  <div className={styles.inputWrap}>
                    <TextField
                      label="Zip code"
                      name="zipCode"
                      formik={formik}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.modalFooter}>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={closeModalPayment}
                  disabled={dataBillingPlan.loading}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  type="submit"
                  disabled={dataUpdateCard.loading}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
    </Paper>
  );
};

YourPlanComponent.propTypes = {
  dataBillingPlan: PropTypes.object.isRequired,
  dataUpdateCard: PropTypes.object.isRequired,
  action: PropTypes.shape({
    getBillingPlan: PropTypes.func.isRequired,
    updateCard: PropTypes.func.isRequired
  }).isRequired,
};


const mapStateToProps = (state) => {
  return {
    dataBillingPlan: state.billing.dataBillingPlan,
    dataUpdateCard: state.billing.dataUpdateCard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({
      getBillingPlan,
      updateCard
    }, dispatch),
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(YourPlanComponent);

