import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@material-ui/core";
import styles from "../modal.module.scss";
//#region Modal
function getModalStyle() {
  const top = 40;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    borderRadius: "4px",
    background: "white",
    width: "60%",
  };
}
export default function EditSponsorship(props) {
  const {
    handleConfirmModal,
    isOpenConfirmModal,
    handleCloseConfirmModal,
    counts,
    typeModal
  } = props;
  // modal styles
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpenConfirmModal}
      onClose={handleCloseConfirmModal}
    >
      <div style={modalStyle} className={styles.paper}>
        <div className={styles.header}>
          <p className={styles.title}>
            {typeModal === 'inactive' ? 'Inactivate' : 'Delete'}  User{counts.length > 1 ? "s" : ""} ({counts})?
          </p>
          <span onClick={handleCloseConfirmModal} className={styles.close}>
            +
          </span>
        </div>
        <div className={styles.body}>
          <p className={styles.text}>
            {
              typeModal === 'inactive' ? 'This will inactivate the user from the company account. This will not delete previous activity from this participant including miles logged and any sponsorship earned.' : 'This will remove the user from the company account and remove all data including miles logged and any sponsorship earned.'
            }
          </p>
        </div>
        <div className={styles.footer}>
          <button
            onClick={handleCloseConfirmModal}
            className={styles.btnCancel}
          >
            <span className={styles.txtCancel}>Cancel</span>
          </button>
          <button onClick={() => {
            handleConfirmModal(typeModal)
          }} className={styles.btnDelete}>
            <span className={styles.txtDelete}>{typeModal === 'inactive' ? 'Inactive' : 'Delete'}</span>
          </button>
        </div>
      </div>
    </Modal>
  );
}
EditSponsorship.propTypes = {
  handleConfirmDelete: PropTypes.func,
  handleCloseConfirmModal: PropTypes.func,
  isOpenConfirmModal: PropTypes.bool,
  counts: PropTypes.number,
  typeModal: PropTypes.string
};
