import {types} from './index';

const initialState = {
  dataAmount: {},
  dataSponsorship: {},
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case types.CALC_AMOUNT_REQUEST:
      return {...state, dataAmount: {loading: true}};
    case types.CALC_AMOUNT_SUCCESS:
    case types.CALC_AMOUNT_FAILURE:
      return {...state, dataAmount: action.payload};

    case types.CREATE_SPONSORSHIP_REQUEST:
      return {...state, dataSponsorship: {loading: true}};
    case types.CREATE_SPONSORSHIP_SUCCESS:
    case types.CREATE_SPONSORSHIP_FAILURE:
      return {...state, dataSponsorship: action.payload};

    case types.CLEAR_DATA_SPONSORSHIP:
      return {...state, dataAmount: {}, dataSponsorship: {}};

    default:
      return state;
  }
};
export default dashboard;
