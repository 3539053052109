import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';

import styles from "./index.module.scss";
import Layout from 'src/components/Layout';
import Edit from './Edit';
import Detail from './Detail';
import Button from '@material-ui/core/Button';

export const AccountContainer = (props) => {
  const [isEdit, setIsEdit] = useState(false);

  const editAccount = () => {
    setIsEdit(true);
  };

  const _editedSuccessfully = () => {
    setIsEdit(false);
  };

  return (
    <Layout>
      <div className={styles.yourAccount}>
        <h1 className={styles.title}>Your Account</h1>
        {
          !isEdit &&
          <Button className={styles.btnEdit}
                  color="secondary"
                  onClick={editAccount}
          >
            Edit
          </Button>
        }
        {
          isEdit ? (
            <Edit editedSuccessfully={_editedSuccessfully}/>
          ) : (
            <Detail/>
          )
        }
      </div>
    </Layout>
  );
};

export default withRouter(AccountContainer);
