import React, { useEffect, useState } from "react";
import Paper from "src/components/Paper";
import SearchBox from "src/components/SearchBox";
import Modal from "./modalConfirm";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import {
  getEmployees,
  getEmployeesByName,
  deleteEmployee,
  inactiveEmployee
} from "src/redux/employees/action";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import styles from "../index.module.scss";

export const UsersComponent = (props) => {
  const { handleBack, employeesState, loading } = props;
  const [employees, setEmployees] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isCheckAll, setCheckAll] = useState(false);
  const [isChecked, setChecked] = useState({});
  const [isOpenConfirmModal, setOpenConfirmModal] = useState(false);
  const [typeModal, setTypeModal] = useState('');
  useEffect(() => {
    props.action.getEmployees();
  }, []);

  useEffect(() => {
    if(employeesState){
      setEmployees(employeesState);
      employeesState.forEach((emp) => {
        const obj = {};
        obj[emp.unid] = false;
        setChecked({ ...Object.assign(isChecked, obj) });
      });
    }
  }, [employeesState]);

  useEffect(() => {
    if (
      employeesState && Object.values(isChecked).filter((item) => item).length ===
      employeesState.length
    ) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [isChecked]);

  const back = () => {
    handleBack();
  };

  const handleChangeSearchText = (e) => {
    setSearchText(e.target.value);
    if (
      (e.target.value.length > 3 &&
        e.target.value.length > searchText.length) ||
      e.target.value.length === 0
    ) {
      props.action.getEmployeesByName(e.target.value);
      setChecked({});
      setCheckAll(false)
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      props.action.getEmployeesByName(e.target.value);
      setChecked({});
      setCheckAll(false)
    }
  };

  const handleSearch = () => {
    props.action.getEmployeesByName(searchText);
    setChecked({});
    setCheckAll(false)
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const handleConfirmModal = (typeModal) => {
    let result = [];
    if(Object.keys(isChecked).length > 0){
      result = Object.keys(isChecked).filter(item => isChecked[item]);
    }

    typeModal === 'inactive' 
    ? props.action.inactiveEmployee(result.join(','))
    : props.action.deleteEmployee(result.join(","));

    setOpenConfirmModal(false);
    setCheckAll(false)
    setChecked({})
  };



  const renderTableBody = () => {
    if (employees.length > 0) {
      return employees.map((emp) => {
        return (
          <tr>
            <td>
              <Checkbox
                name=""
                color="primary"
                checked={isChecked[emp.unid] ? true : false}
                onChange={(event) => {
                  const obj = {};
                  obj[emp.unid] = event.target.checked;
                  setChecked({
                    ...isChecked,
                    ...obj,
                  });
                }}
              />
            </td>
            <td>{emp.fName || "-"}</td>
            {/* <td>{emp.miles || "-"}</td>
            <td>{emp.sponsored || "-"}</td>
            <td>{emp.pledge || "-"}</td> */}
            <td>{emp.companyEmail || "-"}</td>
            <td>{emp.code || "-"}</td>
            <td>{emp.active ? 'active' : 'inactive'}</td>
          </tr>
        );
      });
    }
    return false;
  };

  return (
    <Paper className={styles.users} loading={loading}>
      <div>
        <Button
          color="secondary"
          className={styles.btnBack}
          startIcon={
            <img
              src={require("src/assets/images/arrow-left-pink.svg")}
              alt=""
            />
          }
          onClick={back}
        >
          Back
        </Button>
        <h1 className={styles.title}>{`Registered User${
          employees.length === 1 ? "" : "s"
          } (${employees.length})`}</h1>
        <SearchBox
          value={searchText}
          onChange={handleChangeSearchText}
          onKeyDown={handleKeyDown}
          onSearchClick={handleSearch}
        />
        <div className={styles.pagination}>
          <div>
            <Button
              style={
                Object.values(isChecked).indexOf(true) !== -1
                  ? {}
                  : { opacity: 0, cursor: "none", zIndex: -1 }
              }
              color="secondary"
              className={styles.btnDelete}
              startIcon={
                // <img src={require("src/assets/images/icon-recycle.svg")} alt="" />
                <span style={{ fontSize: '14px' }}>Mark as inactive</span>
              }
              onClick={() => {
                setOpenConfirmModal(true);
                setTypeModal('inactive')
              }}
            ></Button>
            <Button
              style={
                Object.values(isChecked).indexOf(true) !== -1
                  ? {}
                  : { opacity: 0, cursor: "none", zIndex: -1 }
              }
              color="secondary"
              className={styles.btnDelete}
              startIcon={
                // <img src={require("src/assets/images/icon-recycle.svg")} alt="" />
                <span style={{ fontSize: '14px' }}>Delete user and all data</span>
              }
              onClick={() => {
                setOpenConfirmModal(true);
                setTypeModal('delete')
              }}
            ></Button>
          </div>


          {/* <div>
            <span>{`${page}-${
              employees.length > pageLimit ? pageLimit : employees.length
              } of ${employees.length}`}</span>
            <span className={page > 1 ? styles.isCorrectButton : ""}>
              {"  <  "}
            </span>
            <span className={page > 0 ? styles.isCorrectButton : ""}>
              {"  >  "}
            </span>
          </div> */}
        </div>
        <div className={styles.table}>
          <table>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    name=""
                    color="primary"
                    checked={isCheckAll}
                    onChange={(event) => {
                      setCheckAll(event.target.checked);
                      Object.keys(isChecked).forEach((i) => {
                        isChecked[i] = event.target.checked;
                        setChecked({ ...isChecked });
                      });
                    }}
                  />
                </th>
                <th>Name</th>
                {/* <th>Miles</th>
                <th>$ Sponsored</th>
                <th>P2P Pledge $</th> */}
                <th>Company email</th>
                <th>Code</th>
                <th>Active</th>
              </tr>
            </thead>
            <tbody>{renderTableBody()}</tbody>
          </table>
        </div>
      </div>
      <Modal
        typeModal={typeModal}
        handleConfirmModal={handleConfirmModal}
        isOpenConfirmModal={isOpenConfirmModal}
        handleCloseConfirmModal={handleCloseConfirmModal}
        counts={Object.keys(isChecked).filter((item) => isChecked[item]).length}
      />
    </Paper>
  );
};

UsersComponent.propTypes = {
  employeesState: PropTypes.array,
  loading: PropTypes.bool,
  action: PropTypes.shape({
    getEmployees: PropTypes.func.isRequired,
    getEmployeesByName: PropTypes.func.isRequired,
    deleteEmployee: PropTypes.func.isRequired,
    inactiveEmployee: PropTypes.func.isRequired
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    employeesState: state.employees.employees.data,
    loading: state.employees.employees.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      {
        getEmployees,
        getEmployeesByName,
        deleteEmployee,
        inactiveEmployee
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersComponent);
