export const types = {
  GET_CHALLENGE_REQUEST: 'GET_CHALLENGE_REQUEST',
  GET_CHALLENGE_SUCCESS: 'GET_CHALLENGE_SUCCESS',
  GET_CHALLENGE_FAILURE: 'GET_CHALLENGE_FAILURE',

  UPDATE_CHALLENGE_REQUEST: 'UPDATE_CHALLENGE_REQUEST',
  UPDATE_CHALLENGE_SUCCESS: 'UPDATE_CHALLENGE_SUCCESS',
  UPDATE_CHALLENGE_FAILURE: 'UPDATE_CHALLENGE_FAILURE',

  CLEAR_DATA_UPDATE_CHALLENGE: 'CLEAR_DATA_UPDATE_CHALLENGE'
};

export const urls = {
  GET_CHALLENGE: 'challenges/unid',
  UPDATE_CHALLENGE: 'companyAdmin/challenges/unid',
};
