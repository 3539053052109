import { types } from './index';

export const getTemplates = () => ({
    type: types.TEMPLATES_REQUEST,
});

export const createChallenge = (payload) => ({
    type: types.CREATE_CHALLENGE_REQUEST,
    payload,
});

export const clearDataChallenge = () => ({
  type: types.CLEAR_DATA_CHALLENGE
});

export const setRefUrl = (payload) => ({
  type: types.SET_REF_URL,
  payload,
});

