export const types = {
  GET_EMPLOYEES_REQUEST: "GET_EMPLOYEES_REQUEST",
  GET_EMPLOYEES_SUCCESS: "GET_EMPLOYEES_SUCCESS",
  GET_EMPLOYEES_FAIURE: "GET_EMPLOYEES_FAIURE",

  GET_EMPLOYEES_BY_NAME_REQUEST: "GET_EMPLOYEES_BY_NAME_REQUEST",
  GET_EMPLOYEES_BY_NAME_SUCCESS: "GET_EMPLOYEES_BY_NAME_SUCCESS",
  GET_EMPLOYEES_BY_NAME_FAILURE: "GET_EMPLOYEES_BY_NAME_FAILURE",

  GET_EMPLOYEES_SESSION_REQUEST: "GET_EMPLOYEES_SESSION_REQUEST",
  GET_EMPLOYEES_SESSION_SUCCESS: "GET_EMPLOYEES_SESSION_SUCCESS",
  GET_EMPLOYEES_SESSION_FAILURE: "GET_EMPLOYEES_SESSION_FAILURE",

  DELETE_SPECIFIC_EMPLOYEE_SESSION_REQUEST:
    "DELETE_SPECIFIC_EMPLOYEE_SESSION_REQUEST",
  DELETE_SPECIFIC_EMPLOYEE_SESSION_SUCCESS:
    "DELETE_SPECIFIC_EMPLOYEE_SESSION_SUCCESS",
  DELETE_SPECIFIC_EMPLOYEE_SESSION_FAILURE:
    "DELETE_SPECIFIC_EMPLOYEE_SESSION_FAILURE",

  DELETE_EMPLOYEE_REQUEST: "DELETE_EMPLOYEE_REQUEST",
  DELETE_EMPLOYEE_SUCCESS: "DELETE_EMPLOYEE_SUCCESS",
  DELETE_EMPLOYEE_FAILURE: "DELETE_EMPLOYEE_FAILURE",

  INACTIVE_EMPLOYEE_REQUEST: "INACTIVE_EMPLOYEE_REQUEST",
  INACTIVE_EMPLOYEE_SUCCESS: "INACTIVE_EMPLOYEE_SUCCESS",
  INACTIVE_EMPLOYEE_FAILURE: "INACTIVE_EMPLOYEE_FAILURE",
};

export const urls = {
  GET_EMPLOYEES: "companyAdmin/employees",
  GET_EMPLOYEES_BY_NAME: (empName) => `companyAdmin/employees?name=${empName}`,
  GET_EMPLOYEES_SESSION: (empUnid) =>
    `companyAdmin/employees/${empUnid}/sessions`,
  DELETE_SPECIFIC_EMPLOYEE_SESSION: (empUnid, workoutIDs) =>
    `companyAdmin/employees/${empUnid}/sessions?workoutIDs=${workoutIDs}`,
  DELETE_EMPLOYEE: (empUnids) => `companyAdmin/data/employees?userUnids=${empUnids}`,
  INACTIVE_EMPLOYEE: (empUnids) => `companyAdmin/employees?userUnids=${empUnids}`
};
