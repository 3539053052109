import React, {Fragment} from 'react';
import styles from "../index.module.scss";
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types";

export const ConfirmationComponent = (props) => {
  const {dataOnboarding, gotoDashboard} = props;
  return (
    <Fragment>
      <h1 className={styles.welcome}>
        Thank you! You’re all set up. Let’s go to your dashboard.
      </h1>
      <Button
        variant="outlined"
        color="secondary"
        className={styles.setUp}
        onClick={gotoDashboard}
        disabled={dataOnboarding.loading}
      >
        Go to dashboard
      </Button>
    </Fragment>
  );
};

ConfirmationComponent.propTypes = {
  dataOnboarding: PropTypes.object.isRequired,
  gotoDashboard: PropTypes.func.isRequired,
};

export default ConfirmationComponent;
