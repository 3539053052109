import {types} from './index';

export const getChallenge = (payload) => ({
  type: types.GET_CHALLENGE_REQUEST,
  payload
});

export const updateChallenge = (payload) => ({
  type: types.UPDATE_CHALLENGE_REQUEST,
  payload,
});

export const clearDataUpdateChallenge = () => ({
  type: types.CLEAR_DATA_UPDATE_CHALLENGE
});
