import React from 'react';

import PropTypes from "prop-types";
import stylesChallenge from '../index.module.scss';
import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from "@material-ui/core/styles";

import moment from 'moment';
import {Link} from "react-router-dom";

const styles = {
  tooltip: {
    height: "22px",
    borderRadius: "4px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#fff",
    color: "#444444"
  }
};

const CustomTooltip = withStyles(styles)(Tooltip);

export const PreviousComponent = (props) => {
  const {pastChallenges} = props;
  return (
    <>
      <div className={stylesChallenge.header}>
        <div className={stylesChallenge.month}>
          Previous Challenges
        </div>
        <Link to="/schedule">
          <div className={stylesChallenge.previousCreateSchedule}>
            Schedule a Challenge
          </div>
        </Link>
      </div>
      <div className={stylesChallenge.pastChallenges}>
        {pastChallenges.map((pastChallenge, index) => (
          <div className={stylesChallenge.pastChallenge} key={index}>
            <div className={stylesChallenge.infoChallenge}>
              <p className={stylesChallenge.name}>
                {pastChallenge.challenge.name}
              </p>
              <p>
                {pastChallenge.challenge.distance} miles, in {pastChallenge.challenge.days} days
              </p>
              <p>
                {moment(pastChallenge.challenge.startDate).format('MMM DD YYYY')} - {moment(pastChallenge.challenge.endDate).format('MMM DD YYYY')}
              </p>
              <p>
                65% completion 123
              </p>
              {
                pastChallenge.totalMoney ?
                <p>${Math.round(pastChallenge.totalMoney)} donated</p> : null
              }
            </div>
            <div className={stylesChallenge.viewDetail}>
              View details
            </div>
            {pastChallenge.leaderboard &&
            <div className={stylesChallenge.leaderboard}>
              {pastChallenge.leaderboard.map((leader, index) => {
                return (index < 3) ?
                  <CustomTooltip
                    key={index}
                    title={(index === 0 ? "1st: " : index === 1 ? "2nd: " : "3rd: ") + leader.name}
                    placement="top-start" classes={{tooltip: stylesChallenge.tooltipNo}}>
                    <div className={stylesChallenge.avatar}>
                      <img src={leader.profilePhoto} alt="leader"/>
                    </div>
                  </CustomTooltip> : null
              })}
            </div>
            }
          </div>
        ))}
      </div>
    </>
  );
};


PreviousComponent.propTypes = {
  pastChallenges: PropTypes.array.isRequired,
};

export default PreviousComponent;
