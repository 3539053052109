import { types } from './index';

const initialState = {
    forgotPassword: {
        loading: false,
        isSuccess: false,
        isFailure: false
    },
    resetPassword: {
        loading: false,
        isSuccess: false,
        isFailure: false
    }
}

const forgotReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FORGOT_PASSWORD_REQUEST:
            return { ...state, forgotPassword: { loading: true, isSuccess: false, isFailure: false } };
        case types.FORGOT_PASSWORD_SUCCESS:
            return { ...state, forgotPassword: { loading: false, isSuccess: true } }
        case types.FORGOT_PASSWORD_FAILURE:
            return { ...state, forgotPassword: { loading: false, isFailure: true } };

        case types.RESET_PASSWORD_REQUEST:
            return { ...state, resetPassword: { loading: true, isSuccess: false, isFailure: false } };
        case types.RESET_PASSWORD_SUCCESS:
            return { ...state, resetPassword: { loading: false, isSuccess: true } }
        case types.RESET_PASSWORD_FAILURE:

            return { ...state, resetPassword: { loading: false, isFailure: true } };
        default:
            return state;
    }
};
export default forgotReducer;
