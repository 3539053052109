import React from 'react';
import styles from '../index.module.scss';
import TextField from 'src/components/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@material-ui/core';
import PropTypes from "prop-types";

export const ContactAddressComponent = (props) => {
    const { currentStep, contactAddress } = props;

    const formik = useFormik({
        initialValues: contactAddress,
        validationSchema: Yup.object().shape({
            firstName: Yup.string(),
            lastName: Yup.string(),
            address: Yup.string(),
            address2: Yup.string(),
            city: Yup.string(),
            state: Yup.string(),
            zipCode: Yup.string(),
            country: Yup.string(),
        }),
        onSubmit: values => {
            props.updateContactAddress(values);
        },
    });

    const editStep =  () => {
      props.editStep(1)
    };

    return (
        <div className={styles.detailInfo}>
            <div className={styles.header}>
                <div className={styles.title}>
                    Contact address
                </div>
                {
                    currentStep > 1 && (
                        <Button
                            color="secondary"
                            className={styles.btnEdit}
                            endIcon={<img src={require('src/assets/images/arrow-right-edit.svg')} alt=""/>}
                            onClick={editStep}
                        >
                            Edit
                        </Button>
                    )
                }
            </div>
            {
                parseInt(currentStep) === 1 &&
                (<div className={styles.form}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className={styles.dFlexBetween}>
                            <div className={styles.inputWrap}>
                                <TextField
                                    label="First name"
                                    name="firstName"
                                    formik={formik}
                                />
                            </div>
                            <div className={styles.inputWrap}>
                                <TextField
                                    label="Last name"
                                    name="lastName"
                                    formik={formik}
                                />
                            </div>
                        </div>
                        <div className={styles.inputWrap}>
                            <TextField
                                label="Address"
                                name="address"
                                formik={formik}
                            />
                        </div>
                        <div className={styles.inputWrap}>
                            <TextField
                                label="Apartment, suite, etc."
                                name="address2"
                                formik={formik}
                            />
                        </div>
                        <div className={styles.inputWrap}>
                            <TextField
                                label="City"
                                name="city"
                                formik={formik}
                            />
                        </div>
                        <div className={styles.dFlexBetween}>
                            <div className={styles.inputWrap}>
                                <TextField
                                    label="Country/Region"
                                    name="country"
                                    formik={formik}
                                />
                            </div>
                            <div className={styles.inputWrap}>
                                <TextField
                                    label="State"
                                    name="state"
                                    formik={formik}
                                />
                            </div>
                            <div className={styles.inputWrap}>
                                <TextField
                                    label="Zip code"
                                    name="zipCode"
                                    formik={formik}
                                />
                            </div>
                        </div>
                        <div className={styles.inputWrap}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                type="submit"
                                className={styles.btnNextStep}
                            >
                                Continue to Billing
                            </Button>
                        </div>
                    </form>
                </div>)
            }
        </div>
    );
};

ContactAddressComponent.propTypes = {
    contactAddress: PropTypes.object.isRequired,
    currentStep: PropTypes.number.isRequired,
    editStep: PropTypes.func.isRequired,
    updateContactAddress: PropTypes.func.isRequired,
};

export default ContactAddressComponent;
