import { types } from "./index";

export const getEmployees = () => ({
  type: types.GET_EMPLOYEES_REQUEST,
});

export const getEmployeesByName = (name) => ({
  type: types.GET_EMPLOYEES_BY_NAME_REQUEST,
  name,
});

export const getEmployeeSessions = (empUnid) => ({
  type: types.GET_EMPLOYEES_SESSION_REQUEST,
  empUnid,
});

export const deleteSpecificEmployeeSessions = (empUnid, workoutIds) => ({
  type: types.DELETE_SPECIFIC_EMPLOYEE_SESSION_REQUEST,
  empUnid,
  workoutIds,
});

export const deleteEmployee = (empUnid) => ({
  type: types.DELETE_EMPLOYEE_REQUEST,
  empUnid,
});


export const inactiveEmployee = (empUnids) => ({
  type: types.INACTIVE_EMPLOYEE_REQUEST,
  empUnids
})