import AuthStorage from "src/utils/authStorage";
import config from "../config/config";

export const getEndpoint = (path) => {
  return config.apiEndpoint + path;
};

export const getHeader = () => {
  const header = new Headers();
  header.append("Content-Type", "application/json");
  if (AuthStorage.getAccessToken()) {
    header.append("token", AuthStorage.getAccessToken());
  }
  return header;
};

export const apiMethod = (
  { path, payload, method = "GET" },
  isGetText = false
) => {
  const parameters = {
    method,
    headers: getHeader(),
  };
  if (payload && typeof payload === "object") {
    if (payload.hasOwnProperty("token")) {
      parameters.headers.set("token", payload.token);
      delete payload.token;
    }

    if (method === "GET") {
      const query = new URLSearchParams(payload).toString();
      path = `${path}?${query}`;
    } else {
      if (Object.keys(payload).length > 0) {
        parameters.body = JSON.stringify(payload);
      }
    }
  }
  return fetch(getEndpoint(path), parameters)
    .then((response) => {
      if (isGetText) {
        return response.text();
      }
      return response.json();
    })
    .then((json) => {
      return json;
    });
};

export const apiGet = ({ path, payload }) => {
  return apiMethod({ path, payload, method: "GET" });
};

export const apiGetText = ({ path, payload }) => {
  return apiMethod({ path, payload, method: "GET" }, true);
};

export const apiPost = ({ path, payload }) => {
  return apiMethod({ path, payload, method: "POST" });
};

export const apiPut = ({ path, payload }) => {
  return apiMethod({ path, payload, method: "PUT" });
};

export const apiDelete = ({ path, payload }) => {
  return apiMethod({ path, payload, method: "DELETE" });
};
