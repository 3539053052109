import { types } from './index';

export const setupAccount = (payload) => ({
    type: types.ACCOUNT_SETUP_REQUEST,
    payload
});

export const getAccountInfo = () => ({
    type: types.ACCOUNT_INFO_REQUEST
});

export const updateAccount = (payload) => ({
    type: types.UPDATE_ACCOUNT_REQUEST,
    payload,
});

export const clearUpdateAccount = () => ({
    type: types.CLEAR_UPDATE_ACCOUNT
});
