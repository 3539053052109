import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { signIn } from "src/redux/auth/action";
import AuthStorage from "src/utils/authStorage";

import Button from "@material-ui/core/Button";
import TextField from "src/components/TextField";
import Checkbox from "src/components/Checkbox";
import { useFormik } from "formik";
import * as Yup from "yup";

import styles from "./index.module.scss";
import notify from "src/utils/notify";

const mapStateToProps = (state) => {
  return {
    signIn: state.auth.signIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      {
        signIn,
      },
      dispatch
    ),
  };
};

export const SignInContainer = (props) => {
  const { signIn } = props;
  const [loggedIn, setLoggedIn] = useState(false);
  const [onBoardingStep, setOnBoardingStep] = useState(null);
  const [finishedOnBoarding, setFinishedOnBoarding] = useState(null);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      stay: false,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      props.action.signIn(values);
    },
  });

  useEffect(() => {
    if (AuthStorage.getAccessToken()) {
      setLoggedIn(true);
    }
    if (signIn && signIn.token) {
      AuthStorage.setAccessToken(signIn.token);
      AuthStorage.setName(signIn.user.name);
      AuthStorage.setActive(signIn.user.active);
      if (signIn.user.active) {
        setFinishedOnBoarding(signIn.user.finishedOnBoarding);
        setOnBoardingStep(signIn.user.onBoardingStep);
        setLoggedIn(true);
      } else {
        notify.info("Your account is not active yet.");
      }
    }
  }, [signIn]);

  if (loggedIn && finishedOnBoarding !== null && onBoardingStep !== null) {
    if (!finishedOnBoarding) {
      if (onBoardingStep === 1) {
        //todo: change back to checkout if payment is added back
        return <Redirect to="/onboarding" />;
      }
      return <Redirect to="/" />;
    }
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.title}>
          <div className={styles.logo}>
            <img src={require("src/assets/images/logo.svg")} alt="CM" />
          </div>
          <h1>Charity Miles Employee Engagement</h1>
          {AuthStorage.getLoggedOut() && (
            <div className={styles.subTitle}>
              You’ve logged out successfully.
            </div>
          )}
        </div>
        <div className={styles.form}>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.inputWrapUsername}>
              <TextField
                label="Email"
                name="email"
                placeholder="janelovestorun@amcecompany.com"
                readOnly={signIn.loading}
                formik={formik}
              />
            </div>
            <div className={styles.inputWrapPassword}>
              <TextField
                name="password"
                placeholder="********"
                readOnly={signIn.loading}
                formik={formik}
              />
            </div>
            <div className={styles.inputWrapRemember}>
              <Checkbox
                label="Remember me"
                name="stay"
                disabled={signIn.loading}
                formik={formik}
              />
            </div>
            <div>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                type="submit"
                disabled={signIn.loading}
                className={styles.btnLogin}
              >
                Login
              </Button>
              <Button
                fullWidth
                variant="outlined"
                className={styles.btnForgotPassword}
                component={Link}
                disabled={signIn.loading}
                to="/forgot-password"
              >
                Forgot Password
              </Button>
            </div>
          </form>
        </div>

        <div className={styles.form}>
          <h2>Don’t have Charity Miles Employee Engagement yet?</h2>
          <form>
            <div>
              <Button
                fullWidth
                variant="outlined"
                component={Link}
                type="button"
                disabled={signIn.loading}
                className={styles.btnLogin}
                to="/signup"
              >
                Register
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

SignInContainer.propTypes = {
  signIn: PropTypes.object.isRequired,
  action: PropTypes.shape({
    signIn: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SignInContainer));
