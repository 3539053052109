export const types = {
    TEMPLATES_REQUEST: 'TEMPLATES_REQUEST',
    TEMPLATES_SUCCESS: 'TEMPLATES_SUCCESS',
    TEMPLATES_FAILURE: 'TEMPLATES_FAILURE',

    CREATE_CHALLENGE_REQUEST: 'CREATE_CHALLENGE_REQUEST',
    CREATE_CHALLENGE_SUCCESS: 'CREATE_CHALLENGE_SUCCESS',
    CREATE_CHALLENGE_FAILURE: 'CREATE_CHALLENGE_FAILURE',
    CLEAR_DATA_CHALLENGE: 'CLEAR_DATA_CHALLENGE',
    SET_REF_URL: 'SET_REF_URL'
};

export const urls = {
    TEMPLATES: 'companyAdmin/challenges/templates',
    CREATE_CHALLENGE: 'companyAdmin/challenges',
};
