import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import AuthStorage from 'src/utils/authStorage';

import Button from '@material-ui/core/Button';

import styles from './index.module.scss';

export const SplashContainer = (props) => {

    if (AuthStorage.getAccessToken()) {
        return <Redirect to="/dashboard" />
    }

    return <Redirect to="/signin" />
};

export default (SplashContainer);
