import React, {useEffect, useState} from 'react';

import styles from "../index.module.scss";
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import {useFormik} from "formik";
import * as Yup from "yup";
import Input from 'src/components/Input'
import { Link } from "react-router-dom";
import {FormatMoney} from 'src/utils'

export const DetailComponent = (props) => {
  const {data, dataAmount, dataBillingPlan} = props;
  const [additionalCharities, setAdditionalCharities] = useState(data.additionalCharities);
  const [errorEmployeeRun, setErrorEmployeeRun] = useState(false);
  const [errorEmployeeBike, setErrorEmployeeBike] = useState(false);
  const [errorCompanyRun, setErrorCompanyRun] = useState(false);
  const [errorCompanyBike, setErrorCompanyBike] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: data.name,
      companyCharityMPMRun: data.companyCharityMPMRun,
      companyCharityMPMBike: data.companyCharityMPMBike,
      employeeCharityMPMRun: data.employeeCharityMPMRun,
      employeeCharityMPMBike: data.employeeCharityMPMBike,
      additionalCharities: data.additionalCharities,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string(),
      companyCharityMPMRun: Yup.number().min(0),
      companyCharityMPMBike: Yup.number().min(0),
      employeeCharityMPMRun: Yup.number().min(0),
      employeeCharityMPMBike: Yup.number().min(0),
    }),
    onSubmit: values => {
      if (!validateData()) {
        return;
      }
      props.reviewAndConfirm(values);
    },
  });

  useEffect(() => {
    if (formik.values.name) {
      setErrorName(false);
    }
    if (formik.values.companyCharityMPMRun) {
      setErrorCompanyRun(false);
    }
    if (formik.values.companyCharityMPMBike) {
      setErrorCompanyBike(false);
    }
    if (formik.values.employeeCharityMPMRun) {
      setErrorEmployeeRun(false);
    }
    if (formik.values.employeeCharityMPMBike) {
      setErrorEmployeeBike(false);
    }
    if (!formik.values.companyCharityMPMRun && !formik.values.companyCharityMPMBike) {
      formik.values.additionalCharities = [];
      setAdditionalCharities([])
    } else {
      if (!formik.values.additionalCharities.length) {
        formik.values.additionalCharities = [''];
        setAdditionalCharities([''])
      }
    }
  }, [formik.values]);

  const validateData = () => {
    let isValid = true;
    const {name, companyCharityMPMRun, companyCharityMPMBike, employeeCharityMPMRun, employeeCharityMPMBike} = formik.values;
    if (!name) {
      setErrorName(true);
      isValid = false;
    }
    if (!companyCharityMPMRun && isNull(companyCharityMPMBike) && isNull(employeeCharityMPMRun) && isNull(employeeCharityMPMBike)) {
      setErrorEmployeeRun(true);
      setErrorEmployeeBike(true);
      isValid = false;
    } else if (companyCharityMPMRun && isNull(companyCharityMPMBike) && isNull(employeeCharityMPMRun) && isNull(employeeCharityMPMBike)) {
      setErrorCompanyBike(true);
      isValid = false;
    } else if (isNull(companyCharityMPMRun) && companyCharityMPMBike && isNull(employeeCharityMPMRun) && isNull(employeeCharityMPMBike)) {
      setErrorCompanyRun(true);
      isValid = false;
    } else if ((isNull(companyCharityMPMRun) || isNull(companyCharityMPMBike)) && isNull(employeeCharityMPMRun) && employeeCharityMPMBike) {
      setErrorEmployeeRun(true);
      isValid = false;
    } else if ((isNull(companyCharityMPMRun) || isNull(companyCharityMPMBike)) && employeeCharityMPMRun && isNull(employeeCharityMPMBike)) {
      setErrorEmployeeBike(true);
      isValid = false;
    }

    return isValid;
  };

  const isNull = (value) => {
    return value === '' || value === null;
  };

  const addCharity = () => {
    formik.values.additionalCharities.push('');
    setAdditionalCharities([...additionalCharities, '']);
  };

  const goBack = () => {
    props.goBack()
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.detailInfo}>
        <div className={styles.recommendAmount}>
          <h2>Your recommended sponsorship amount:</h2>
          <div className={styles.recommendDetail}>
            <div className={styles.detailCost}>
              <div className={styles.icon}>
                <img src={require('src/assets/images/walk.svg')} className={styles.imgRunWalk} alt="run-walk"/>
              </div>
              <p>
                <span className={styles.cost}>{FormatMoney(dataAmount.moneyPerMileRun)}</span>
                <span> per mile for run/walk</span>
              </p>
            </div>
            <div className={styles.detailCost}>
              <img src={require('src/assets/images/bike.svg')} alt="run-walk"/>
              <p>
                <span className={styles.cost}>{FormatMoney(dataAmount.moneyPerMileBike)}</span>
                <span> per mile for bike</span>
              </p>
            </div>
          </div>
          <div className={styles.description}>
            To get these numbers we took into account <b>your budget and timing</b> + <b>our data</b> on how many miles
            the average Charity Miler does in a day.
          </div>
        </div>
        <div className={styles.conservation}>
          <div className={styles.guest}>
            <div className={styles.message}>
              <img src={require('src/assets/images/bg-message-guest.svg')} alt="message"/>
              The average CM member runs or walks <b>{dataAmount.averageRunDistance}</b> miles per day.<br/>
              The average CM biker bikes <b>{dataAmount.averageBikeDistance}</b> miles per day.
            </div>
          </div>
          <div className={styles.owner}>
            <div className={styles.message}>
              <img src={require('src/assets/images/bg-message-owner.svg')} alt="message"/>
              {dataAmount.bikersPercentage}% of Charity Milers bike as well as run and walk.
            </div>
          </div>
        </div>
        <div className={styles.sponsorshipDetail}>
          <div className={styles.title}>
            Complete sponsorship details
          </div>
          <div className={styles.blockData}>
            <div className={styles.title}>
              Assign which charities you’d like to support with this sponsorship
            </div>
            <div className={styles.charity}>
              <div>
                <div className={styles.typeCharity}>
                  <p>
                    Employee’s Charity
                  </p>
                  <img src={require('src/assets/images/hint.svg')} alt="hint"/>
                </div>
                <div className={styles.inputData}>
                  <img src={require('src/assets/images/walk.svg')} alt="walk"/>
                  <Input placeholder="0.05" type="number" formik={formik} error={errorEmployeeRun} name="employeeCharityMPMRun"/>
                </div>
                <div className={styles.inputData}>
                  <img src={require('src/assets/images/bike.svg')} alt="bike"/>
                  <Input placeholder="0.05" type="number" error={errorEmployeeBike}
                         formik={formik} name="employeeCharityMPMBike"/>
                </div>
              </div>
              <div>
                <div className={styles.typeCharity}>
                  <p>
                    Company’s Charity
                  </p>
                  <img src={require('src/assets/images/hint.svg')} alt="hint"/>
                </div>
                <div className={styles.inputData}>
                  <img src={require('src/assets/images/walk.svg')} alt="walk"/>
                  <Input placeholder="0.05" type="number" formik={formik} error={errorCompanyRun} name="companyCharityMPMRun"/>
                </div>
                <div className={styles.inputData}>
                  <img src={require('src/assets/images/bike.svg')} alt="bike"/>
                  <Input placeholder="0.05" type="number" formik={formik} error={errorCompanyBike} name="companyCharityMPMBike"/>
                </div>
                {
                  (formik.values.companyCharityMPMRun || formik.values.companyCharityMPMBike) ?
                    <div className={`${styles.inputData} ${styles.charityName}`}>
                      <img src={require('src/assets/images/heart.svg')} alt="heart"/>
                      <div>
                        {
                          additionalCharities.map((charity, index) => (
                            <Input key={index} placeholder="charity name" formik={formik} name={'additionalCharities[' + index + ']'}/>
                          ))
                        }
                      </div>
                      <span className={styles.addAnother} onClick={addCharity}> add another</span>
                    </div> : null
                }
              </div>
            </div>
          </div>
          <div className={styles.blockData}>
            <div className={`${styles.titleName} ${styles.title}`}>
              Name your sponsorship
            </div>
            <div className={styles.inputName}>
              <Input placeholder="ex. Holiday Challenge 2019" error={errorName} formik={formik} name="name"/>
            </div>
            <p className={styles.descriptionName}>
              This will help you identify the sponsorship in your reports.
            </p>
          </div>
          <div className={styles.blockData}>
            <div className={`${styles.titleConfirm} ${styles.title}`}>
              Confirm sponsorship payment details
            </div>
            <p className={styles.charityInfo}>
              Company charities that you have added above will need to be paid by you as<br/>
              Charity Miles is not connected with them.
            </p>
          </div>
          <div className={styles.note}>
            * When sponsorship starts we’ll send employees an email to let them know the company is sponsoring their
            miles.
          </div>
        </div>
      </div>
      <div className={styles.actionBtn}>
        <Button
          variant="outlined"
          color="secondary"
          type="button"
          disabled={dataAmount.loading}
          className={styles.btnBack}
          onClick={goBack}
        >
          <img src={require('src/assets/images/arrow-left-pink.svg')} alt="back"/>
          Back
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          type="submit"
          className={styles.btn}
        >
          Review and Confirm
        </Button>
      </div>
    </form>
  );
};

DetailComponent.propTypes = {
  dataAmount: PropTypes.object.isRequired,
  dataSponsorship: PropTypes.object.isRequired,
  reviewAndConfirm: PropTypes.func.isRequired,
};


export default DetailComponent;
