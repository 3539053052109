import {call, put, fork, take, select, takeLatest} from 'redux-saga/effects';
import {types, urls} from './index';
import {apiPut} from "src/utils/api";
import notify from "src/utils/notify";

function* nextStep() {
  while (true) {
    const {payload} = yield take([types.NEXT_STEP]);
    const {states} = yield select(state => state.onboarding);
    const newState = {
      ...states,
      ...payload
    };
    yield put({
      type: types.UPDATE_STEP,
      payload: newState,
    });
  }
}

function* saveStep(action) {
  try {
    const response = yield call(apiPut, {
      path: urls.UPDATE_ONBOARDING,
      payload: action.payload
    });
    if (response.error) {
      yield put({
        type: types.ONBOARDING_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.ONBOARDING_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: types.ONBOARDING_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}


function* triggerWizard() {
  yield put({type: '__WIZARD__'});
}

export default function* onboardingWatcher() {
  yield fork(nextStep);
  yield fork(triggerWizard);
  yield takeLatest(types.ONBOARDING_REQUEST, saveStep);
}
