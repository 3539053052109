let local = {
    stripe:{
        key: "pk_test_yA9h0z75Nb5gPo238gKECaGs"
    },
    fileStack:{
        key: "AGrfQbmLqRgy2QOLKm3gKz"
    },
    logging: true,
    appUrl: "https://miles.test-app.link/tJSK2CY1g3",
    apiEndpoint: "https://o86a2uajrd.execute-api.us-east-1.amazonaws.com/dev/"
};

let dev = {
    stripe:{
        key: "pk_test_yA9h0z75Nb5gPo238gKECaGs"
    },
    fileStack:{
        key: "AGrfQbmLqRgy2QOLKm3gKz"
    },
    logging: true,
    appUrl: "https://miles.test-app.link/tJSK2CY1g3",
    apiEndpoint: "https://o86a2uajrd.execute-api.us-east-1.amazonaws.com/dev/"
};

let prod = {
    stripe:{
        key: "pk_live_NzYDcJ9gcVMai6XnZC67a1LP00zcyA2KfC"
    },
    fileStack:{
        key: "AGrfQbmLqRgy2QOLKm3gKz"
    },
    logging: false,
    appUrl: "https://miles.app.link/gEKGSU21g3",
    apiEndpoint: "https://o86a2uajrd.execute-api.us-east-1.amazonaws.com/prod/"
};

const config  = () =>{
    console.log(process.env.REACT_APP_ENV);
    return process.env.REACT_APP_ENV === "prod"?prod:process.env.REACT_APP_ENV === "dev"?dev:local;
};

export default config();
