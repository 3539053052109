import {types} from './index';

const initialState = {
  dataTemplates: {},
  dataChallenge: {},
  refUrl: "",
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case types.TEMPLATES_REQUEST:
      return {...state, dataTemplates: {loading: true}};
    case types.TEMPLATES_SUCCESS:
    case types.TEMPLATES_FAILURE:
      return {...state, dataTemplates: action.payload};

    case types.CREATE_CHALLENGE_REQUEST:
      return {...state, dataChallenge: {loading: true}};
    case types.CREATE_CHALLENGE_SUCCESS:
    case types.CREATE_CHALLENGE_FAILURE:
      return {...state, dataChallenge: action.payload};

    case types.CLEAR_DATA_CHALLENGE:
      return {...state, dataChallenge: {}};

    case types.SET_REF_URL:
      return {...state, refUrl: action.payload};

    default:
      return state;
  }
};
export default dashboard;
