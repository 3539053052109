import React, {useState, useEffect, Fragment} from 'react';
import PropTypes from "prop-types";
import styles from "./index.module.scss";
import {bindActionCreators} from "redux";
import {getBillingPlan, getInvoices} from "src/redux/billing/action";
import {connect} from "react-redux";
import Container from '@material-ui/core/Container';
import Paper from 'src/components/Paper';
import moment from "moment";
import { FormatMoney } from 'src/utils';
import {Link} from 'react-router-dom';

export const DetailInvoiceComponent = (props) => {
  const {dataInvoices, match, dataBillingPlan, dataAccountInfo} = props;
  const {billingPlan} = dataBillingPlan;
  const [invoice, setInvoice] = useState(null);
  const {
    params: {unid}
  } = match;


  useEffect(() => {
    props.action.getInvoices();
    if (!billingPlan) {
      // props.action.getBillingPlan();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataInvoices.billingInvoices && dataInvoices.billingInvoices.length) {
      const invoiceData = dataInvoices.billingInvoices.filter(iv => iv.unid === unid);
      if (invoiceData.length) {
        setInvoice(invoiceData[0])
      }
    }

    // eslint-disable-next-line
  }, [dataInvoices]);


  return (
    <div className={styles.invoiceDetail}>
      {
        invoice &&
        <div className={styles.containerFluid}>
          <header>
            <Container className={styles.container}>
              <div className={styles.logo}>
                <Link to='/'>
                <img src={require("src/assets/images/logo.svg")} alt="logo"/>
                </Link>
                <div className={styles.name}>Charity Miles</div>
              </div>
              <div className={styles.orderID}>
                Order ID: {unid}
              </div>
            </Container>
          </header>
          <Container className={styles.container}>
            <div className={styles.inner}>
              <div className={styles.headInvoice}>
                <h1 className={styles.title}>
                  Invoice
                </h1>
              </div>
              <Paper
                className={styles.paper}
                loading={dataInvoices.loading}
              >
                <div className={styles.invoiceInfo}>
                  <div className={styles.billTo}>
                    <div>
                      <b>Bill To:</b><br/>
                      {dataAccountInfo.account ? dataAccountInfo.account.company.name : null}<br/>
                      {
                        billingPlan &&
                          <Fragment>
                            {billingPlan.membership.card.billingAddress.address}<br/>
                            {billingPlan.membership.card.billingAddress.city}, {billingPlan.membership.card.billingAddress.state} {billingPlan.membership.card.billingAddress.zipCode}<br/>
                          </Fragment>
                      }
                    </div>
                    <div className={styles.companyInfo}>
                      <b>Charity Miles</b><br/>
                      401 Park Ave South<br/>
                      New York, NY 10010<br/>
                    </div>
                  </div>
                  <div className={styles.infoDetail}>
                    <b>Order ID:</b> {unid}<br/>
                    <b>Date:</b> {moment.utc(invoice.date).format('MMMM DD, YYYY')}<br/>
                    <b>Account:</b> {dataAccountInfo.account ? dataAccountInfo.account.user.email : null}<br/><br/>
                  </div>
                </div>
                <div className={styles.amount}>

                  <div className={styles.head}>
                    <p>Description</p>
                    <p>Amount</p>
                  </div>
                  {
                    invoice.items.map((item, index) => (
                      <div className={styles.detailAmount} key={index}>
                        <p>{item.description}</p>
                        <p>{FormatMoney(item.amount)}</p>
                      </div>
                      )
                    )
                  }
                </div>
                <div className={styles.total}>
                  <p>Total</p>
                  <p><b>{FormatMoney(invoice.amount)}</b></p>
                  <p>Paid in full</p>
                </div>
                <div className={styles.footer}>
                  If you have any questions with this invoice please contact: eesupport@charitymiles.org
                </div>
              </Paper>
            </div>
          </Container>
        </div>
      }
    </div>
  );
};


DetailInvoiceComponent.propTypes = {
  dataInvoices: PropTypes.object.isRequired,
  dataAccountInfo: PropTypes.object.isRequired,
  dataBillingPlan: PropTypes.object.isRequired,
  action: PropTypes.shape({
    getInvoices: PropTypes.func.isRequired,
    getBillingPlan: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    dataInvoices: state.billing.dataInvoices,
    dataAccountInfo: state.account.dataAccountInfo,
    dataBillingPlan: state.billing.dataBillingPlan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({
      getInvoices,
      getBillingPlan
    }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DetailInvoiceComponent);
