import React,{useState} from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Fade } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import styles from "./index.module.scss";
import MomentUtils from "@date-io/moment";
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { useEffect } from 'react';
import TextFieldComponent from 'src/components/TextField';
const DeleteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);
export default function EditModal(props){

    const {openModalChallenge, currentChallenge, updateDataChallenge, setOpenModalChallenge} = props;

    const [isDeleteChallenge, setDeleteChallenge]= useState(false);
    const [errorValidateEndDate, setErrorValidateEndDate] = useState(false);
    const [endDate, setEndDate] = useState(new Date())
    const [name, setName] = useState('');
    const [distance, setDistance] = useState(0);

    useEffect(()=>{
      if(currentChallenge.endDate){
        setEndDate(new Date(currentChallenge.endDate));
      }
      if(currentChallenge.name){
        setName(currentChallenge.name);
      }
      if(currentChallenge.distance){
        setDistance(currentChallenge.distance);
      }
    },[currentChallenge])

    const handleEndDateChange = date => {
      setEndDate(date);
    };

    const onErrorEndDate = (err, value) => {
      if (err || !value) {
      } else {
        setErrorValidateEndDate(false);
      }
    };

    const onNameChange = (e)=>{
      const VALUE = e.target.value;
      setName(VALUE);
    }

    const onDistanceChange = (e)=>{
      const VALUE = e.target.value;
      if(!isNaN(VALUE)){
        setDistance(VALUE);
      }
    }

    return (
        <Modal
        className={styles.modalSchedule}
        open={openModalChallenge}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalChallenge}>
          <div className={styles.contentCurrentModal}>
            <div className={styles.header}>
              <p>{currentChallenge && currentChallenge.name} challenge</p>
              <span onClick={()=>{
                setOpenModalChallenge(false);
              }}>x</span>
            </div>

            <div className={styles.middle}>
              <p style={{ minWidth: '100px' }}>Ending on:</p> &ensp;
              <div className={styles.inputDate}>
                  <MuiPickersUtilsProvider utils={MomentUtils} className={styles.inputDate}>
                      <KeyboardDatePicker
                          onError={onErrorEndDate}
                          error={errorValidateEndDate}
                          disableToolbar
                          variant="inline"
                          format="MM/DD/YYYY"
                          margin="normal"
                          value={endDate}
                          autoOk={true}
                          onChange={handleEndDateChange}
                          name="endDate"
                          minDate={Date.now()}
                          placeholder="mm/dd/yyyy"
                          keyboardIcon={<img src={require('src/assets/images/icon-calendar.svg')} alt="" />}
                      />
                  </MuiPickersUtilsProvider>
              </div>
            </div>

            <div className={styles.challengeInformation}>

              <div className={styles.challengeInformationItem}>
                <p className="">Name: </p>&ensp;
                <input  onChange={onNameChange} type="text" value={name} />
              </div>

              <div className={styles.challengeInformationItem}>
                <p className="">Distance: </p>&ensp;
                <input onChange={onDistanceChange} type="text" value={distance} />
              </div>

            </div>

            <div className={styles.sessionButton}>
            <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={()=>{
                  updateDataChallenge({
                    "delete": false,
                    "endToday": false,
                    "endDate": endDate ? moment(endDate).format("YYYY-MM-DD").toString()+'T00:00:00Z' : null,
                    "name" : name,
                    "distance" : distance
                  })
                }}
              >
                Save
              </Button>
              <DeleteButton
                variant="contained"
                type="button"
                onClick={()=>{
                  setDeleteChallenge(true)
                }}
              >
                Delete
              </DeleteButton>
              <Button
                variant="contained"
                color="secondary"
                type="button"
                onClick={()=>{
                  updateDataChallenge({
                    "delete": false,
                    "endToday": true,
                    "endDate": null,
                    "name" : name,
                    "distance" : distance
                  })
                }}
              >
                End Today
              </Button>
            </div>
            {isDeleteChallenge ? 
            <p className={styles.textDanger}>Are you sure you want to delete? This will delete all data associated with the challenge? 
              <span onClick={()=>{
                 updateDataChallenge({
                    "delete": true,
                    "endToday": false,
                    "endDate": null,
                    "name" : name,
                    "distance" : distance
                  })
              }}>Yes</span> 
              <span onClick={()=>{
                  setDeleteChallenge(false)
                }}>No</span> 
            </p> : ""}
          </div>
        </Fade>
      </Modal>
    )
}
EditModal.propTypes = {
    openModalChallenge: PropTypes.bool.isRequired,
    currentChallenge: PropTypes.object.isRequired,
    updateDataChallenge: PropTypes.func.isRequired,
    setOpenModalChallenge: PropTypes.bool.isRequired
  };
  