import React, { Fragment } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import PropTypes from "prop-types";
import styles from "../index.module.scss";
import moment from "moment";
import Popover from "@material-ui/core/Popover";

export const PastComponent = (props) => {
  const { challenges } = props;

  const renderPerMile = (row) => {
    if (
      !row.sponsorship ||
      (!row.sponsorship.emplCharityMPMRun && !row.emplCharityMPMBike)
    ) {
      return "-";
    }
    return (
      <p>
        {row.sponsorship.emplCharityMPMRun} <br />
        {row.sponsorship.emplCharityMPMBike}
      </p>
    );
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [row, setRow] = React.useState({});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const showDetail = () => {
    props.showDetail(row);
  };

  return (
    <div className={styles.tableChallenges}>
      {challenges.length && (
        <Fragment>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.name}>Challenge Name</TableCell>
                <TableCell className={styles.dates}>Dates</TableCell>
                <TableCell className={styles.winners}>Winners</TableCell>
                <TableCell className={styles.members}>Members</TableCell>
                <TableCell className={styles.budget}>Budget</TableCell>
                <TableCell className={styles.perMile}>Per mile</TableCell>
                <TableCell className={styles.donation}>Donation</TableCell>
                <TableCell className={styles.actions}></TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Table>
            <TableBody>
              {challenges.map((row, index) => (
                <TableRow key={index}>
                  <TableCell className={styles.name}>
                    {row.challenge.name}
                  </TableCell>
                  <TableCell className={styles.dates}>
                    {moment(row.challenge.startDate).format("MM/DD/YYYY")}
                    -
                    <br />
                    {moment(row.challenge.endDate).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell className={styles.winners}>
                    {row.leaderboard.map((leader, index) => {
                      return index < 3 ? (
                        <div className={styles.leaderInfo} key={index}>
                          <div>
                            {index === 0 ? "1st" : index === 1 ? "2nd" : "3rd"}
                          </div>{" "}
                          <div>{leader.name}</div>
                        </div>
                      ) : null;
                    })}
                  </TableCell>
                  <TableCell className={styles.members}>
                    {row.members}
                  </TableCell>
                  <TableCell className={styles.budget}>
                    {row.sponsorship && row.sponsorship.budget
                      ? `${row.sponsorship.budget}/per employee`
                      : "-"}
                  </TableCell>
                  <TableCell className={styles.perMile}>
                    {renderPerMile(row)}
                  </TableCell>
                  <TableCell className={styles.donation}>
                    ${parseFloat(row.totalMoney).toFixed(2)}
                  </TableCell>
                  <TableCell className={styles.actions}>
                    <div
                      className={styles.icon}
                      onClick={(e) => {
                        setRow(row);
                        handleClick(e);
                      }}
                    >
                      <img
                        src={require("src/assets/images/more.svg")}
                        alt="more"
                      />
                    </div>
                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <ul>
                        <li
                          onClick={() => {
                            showDetail();
                          }}
                        >
                          View
                        </li>
                      </ul>
                    </Popover>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Fragment>
      )}
    </div>
  );
};

PastComponent.propTypes = {
  challenges: PropTypes.array.isRequired,
  showDetail: PropTypes.func.isRequired,
};

export default PastComponent;
