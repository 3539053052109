const store = window.localStorage;
const expiredPeriod = 30 * 24 * 60 * 60 * 1000;

class Storage {
  constructor(name) {
    this.name = name;
  }

  setValue(key, value) {
    let obj = JSON.parse(store.getItem(this.name));
    if (!obj) {
      obj = {};
    }
    obj.expiredAt = new Date().getTime() + expiredPeriod;
    obj[key] = value;
    store.setItem(this.name, JSON.stringify(obj));
  };

  getValue(key) {
    const obj = JSON.parse(store.getItem(this.name));
    if (obj) {
      if (obj.expiredAt && obj.expiredAt < new Date().getTime()) {
        this.destroy();
      } else {
        return obj[key];
      }
    }
    return undefined;
  };

  setAccessToken(value) {
    this.setValue('accessToken', value);
  }

  setName(value) {
    this.setValue('name', value);
  }

  setActive(value) {
    this.setValue('active', value);
    this.setLoggedOut(false);
  }

  setLoggedOut(value) {
    this.setValue('loggedOut', value);
  }

  getAccessToken() {
    return this.getValue('active') && this.getValue('accessToken');
  }

  getName() {
    return this.getValue('name');
  }

  getActive() {
    return this.getValue('active');
  }

  getLoggedOut() {
    return this.getValue('loggedOut');
  }

  destroy = () => {
    store.removeItem(this.name);
  };
}

export default new Storage('__CM_TOKEN__');
