import React,{ useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";
import styles from "../index.module.scss";
import moment from "moment";
import Popover from '@material-ui/core/Popover';
import {clearDataUpdateChallenge, updateChallenge} from "src/redux/challenge/action";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import EditModal from 'src/components/Modal/EditChallengeModal';
import notify from "src/utils/notify";

export const CurrentComponent = (props) => {
  const {currentChallenge, currentSponsorship, currentLeaderboard, dataUpdateChallenge} = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalChallenge, setOpenModalChallenge] = useState(false);

  const BorderLinearProgress = withStyles({
    root: {
      height: 7,
      backgroundColor: '#D5EBE1',
    },
    bar: {
      borderRadius: 0,
      backgroundColor: '#4BA97C',
    },
  })(LinearProgress);

  useEffect(() => {
    if (dataUpdateChallenge.challenge) {
      setOpenModalChallenge(false)
      props.action.clearDataUpdateChallenge();
      props.getChallenges()
    }
    // eslint-disable-next-line
  }, [dataUpdateChallenge]);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const updateDataChallenge = (data) => {
    let errorCount = 0;
    if(data.name === ''){
      notify.error('Name is invalid.')
      errorCount++;
    }

    if(data.distance <= 0){
      notify.error('Distance is invalid.')
      errorCount++;
    }
    if(errorCount > 0){
      return;
    }

    const bodyData ={
      // unid: currentChallenge.unid,
      ...currentChallenge,
      ...data,
      distance: Number(data.distance)
    }

    props.action.updateChallenge(bodyData);

  };

  return (
    <div className={styles.currentChallenge}>
      <div className={`${styles.header} d-flex flex-row w-100`}>
        <div className={styles.name}>
          { currentChallenge.name }
        </div>
        <div>
          <div style={{ cursor:'pointer' }} onClick={handleClick}>
            <img src={require('src/assets/images/more.svg')} alt="more" />
          </div>
          <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <ul>
                <li onClick={()=> {
                  setAnchorEl(null);
                  setOpenModalChallenge(true)
                }}>Edit</li>
              </ul>
          </Popover>
        </div>
      </div>
      <div className={styles.moreInfo}>
        <p>
          <span>{currentChallenge.distance} miles</span> in {currentChallenge.days} days
        </p>
        <p>
          {moment(currentChallenge.startDate).format('MMM DD YYYY')} - {moment(currentChallenge.endDate).format('MMM DD YYYY')}
        </p>
        <p>
          {currentChallenge.daysRemaining} days remaining
        </p>
        {
          (currentSponsorship && currentSponsorship.budget) ? (currentSponsorship.budgetType==="person" ?
            <p>
              up to ${currentSponsorship.budget} per employee sponsorship
            </p> : <p>up to ${currentSponsorship.budget} for entire company sponsorship</p>
          ): null
        }
      </div>
      <div className={styles.detailInfo}>
        <div className={styles.tableBoard}>
          <Table>
            <TableBody>
              {currentLeaderboard.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center" className={styles.no}>{index + 1}</TableCell>
                  <TableCell align="center" className={styles.userAvatar}>
                    <img src={row.profilePhoto} alt=""/>
                  </TableCell>
                  <TableCell className={styles.name}>
                    {row.name}
                  </TableCell>
                  <TableCell align="center" className={styles.percent}>
                    <BorderLinearProgress
                      className={styles.progressBar}
                      variant="determinate"
                      color="secondary"
                      value={parseInt(parseInt(row.totalMiles) / parseInt(currentChallenge.distance) * 100) > 100 ? 100 :
                        parseInt(parseInt(row.totalMiles) / parseInt(currentChallenge.distance) * 100)}
                    />
                  </TableCell>
                  <TableCell align="center"
                             className={styles.run}>{Math.round(parseFloat(row.totalMiles))} mi</TableCell>
                  <TableCell align="center" className={styles.isPass}>
                    {parseInt(row.totalMiles) >= parseInt(currentChallenge.distance) && (
                      <img src={require('src/assets/images/completed.svg')} alt=""/>
                    )}
                  </TableCell>
                  <TableCell align="center"
                             className={styles.cash}>${parseFloat(row.totalMoney).toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      <EditModal 
        openModalChallenge={openModalChallenge}
        currentChallenge={currentChallenge}
        setOpenModalChallenge={setOpenModalChallenge}
        updateDataChallenge={updateDataChallenge}
      />
    </div>
  );
};

CurrentComponent.propTypes = {
  currentChallenge: PropTypes.object.isRequired,
  action: PropTypes.shape({
    updateChallenge: PropTypes.func.isRequired,
    clearDataUpdateChallenge: PropTypes.func.isRequired,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({
      updateChallenge,
      clearDataUpdateChallenge
    }, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    dataUpdateChallenge: state.challenge.dataUpdateChallenge,
    clearDataUpdateChallenge: state.challenge.clearDataUpdateChallenge,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CurrentComponent);

