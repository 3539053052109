import { types } from './index';

const initialState = {
    dataAccount: {},
    dataUpdateAccount: {},
    dataAccountInfo: {},
};

const account = (state = initialState, action) => {
    switch (action.type) {
        case types.ACCOUNT_SETUP_REQUEST:
            return { ...state, dataAccount: { loading: true } };
        case types.ACCOUNT_SETUP_SUCCESS:
        case types.ACCOUNT_SETUP_FAILURE:
            return { ...state, dataAccount: action.payload };

        case types.ACCOUNT_INFO_REQUEST:
            return { ...state, dataAccountInfo: { loading: true } };
        case types.ACCOUNT_INFO_SUCCESS:
        case types.ACCOUNT_INFO_FAILURE:
            return { ...state, dataAccountInfo: action.payload };

        case types.UPDATE_ACCOUNT_REQUEST:
            return { ...state, dataUpdateAccount: { loading: true } };
        case types.UPDATE_ACCOUNT_SUCCESS:
        case types.UPDATE_ACCOUNT_FAILURE:
            return { ...state, dataUpdateAccount: action.payload };
        case types.CLEAR_UPDATE_ACCOUNT:
            return { ...state, dataUpdateAccount: {} };

        default:
            return state;
    }
};
export default account;
