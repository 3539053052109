import React, {useState, useRef, useEffect} from 'react';
import {withRouter} from 'react-router-dom';

import styles from "../index.module.scss";
import {bindActionCreators} from "redux";
import {updateAccount, clearUpdateAccount} from "src/redux/account/action";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import TextField from 'src/components/TextField';
import {useFormik} from "formik";
import * as Yup from "yup";
import Button from '@material-ui/core/Button';
import notify from "src/utils/notify";
import * as filestack from "filestack-js";
import config from "../../../config/config";
import TextFieldMaterial from '@material-ui/core/TextField';

const filestackKey = config.fileStack.key;
const client = filestack.init(filestackKey);

export const EditComponent = (props) => {
  const {dataAccountInfo, dataUpdateAccount} = props;
  const {account} = dataAccountInfo;
  const refInputFile = useRef(null);
  const [companyLogo, setLogo] = useState(account.company ? account.company.logo : null);
  const [fileLogo, setFileLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isRequiredEmailAndCode, setRequireEmailAndCode] = useState(account.company ? account.company.requireEmailAndCode : false);
  const [emailDomain, setEmailDomain ] = useState(account.company ? account.company.emailDomain : '');

  const formik = useFormik({
    initialValues: {
      adminName: account.user.name,
      companyName: account.company ? account.company.name : "",
      employeeMessage: account.company ? account.company.employeeMessage : "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string(),
      companyName: Yup.string(),
      employeeMessage: Yup.string(),
    }),
    onSubmit: values => {
      if(isRequiredEmailAndCode){
        if(!emailDomain){
          notify.error('Email domain is require.')
          return;
        }else{
          values.requireEmailAndCode = true;
          values.companyEmailDomain = emailDomain;
        }
      }else{
        values.requireEmailAndCode = false;
        values.companyEmailDomain = emailDomain;
      }
      if ((account.company && account.company.logo) !== companyLogo) {
        uploadFile(values)
      } else {
        updateAccount({
          ...values,
          companyLogo: companyLogo
        })
      }
    },
  });

  useEffect(() => {
    if (dataUpdateAccount.account) {
      props.action.clearUpdateAccount()
      props.editedSuccessfully();
    }

    // eslint-disable-next-line
  }, [dataUpdateAccount]);

  const updateAccount = (values) => {
    props.action.updateAccount({
      ...values
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      notify.error('Sorry, only jpg, jpeg and png files are allowed');
      event.target.value = null;
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      const img = new Image();
      img.src = e.target.result;

      img.onload = function () {
        if (this.width !== this.height) {
          notify.error('Please upload a square image!');
          e.target.value = null;
        } else {
          setFileLogo(file);
          setLogo(reader.result)
        }
      };
    };
  };

  const uploadFile = (values) => {
    if (!companyLogo) {
      notify.error('Company Logo is required!');
      return;
    }
    setLoading(true);

    const token = {};
    const onRetry = (obj) => {
      console.log(`Retrying ${obj.location} for ${obj.filename}. Attempt ${obj.attempt} of 10.`);
    };
    const storeOptions = {
      filename: fileLogo.name.trim(),
      location: "s3"
    };
    client.upload(fileLogo, {onRetry, retry: 2}, storeOptions, token)
      .then(res => {
        setLoading(false);
        updateAccount({
          ...values,
          companyLogo: res.url
        })
      })
      .catch(err => {
        setLoading(false);
        console.error('err: ', err);
        notify.error('Error');
      });
  };

  const openFileUpload = () => {
    refInputFile.current.click();
  };

  const removeLogo = () => {
    setLogo(null)
  };

  const cancelEdit = () => {
    props.editedSuccessfully();
  };

  const onChangeEmailDomain = (e)=>{
    let VALUE = e.target.value;
    setEmailDomain(VALUE);
  }

  const onCheckRequireEmailAndCode = (e)=>{
    let isCheck = e.target.checked;
    setRequireEmailAndCode(isCheck);
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.accountInformation}>
        <div className={styles.title}>
          Account information
        </div>
        <div className={styles.formData}>
          <div className={styles.inputWrap}>
            <TextField
              label="Your Name"
              name="adminName"
              placeholder="Acme Company"
              readOnly={dataUpdateAccount.loading}
              formik={formik}
              inputProps={{maxLength: 20}}
            />
          </div>
          <div className={styles.inputWrap}>
            <TextField
              label="Company Name"
              name="companyName"
              placeholder="Acme Company"
              readOnly={dataUpdateAccount.loading}
              formik={formik}
              inputProps={{maxLength: 20}}
            />
          </div>
          <div className={styles.domainEmailCompany}>
                <div className={styles.domainEmailCompanyCheckbox}>
                  <input checked={isRequiredEmailAndCode} onChange={onCheckRequireEmailAndCode} type="checkbox" />
                  <p>Require Company Email For Sign-In Instead In Addition to Company Code</p>
                </div >
                <div className={styles.inputWrap}>
                  <TextFieldMaterial
                    fullWidth
                    name="companyEmailDomain"
                    value={emailDomain}
                    onChange={onChangeEmailDomain}
                    placeholder="domainmane.com (separate by commas)"
                    readOnly={dataUpdateAccount.loading}
                  />
                </div>
              </div>
          {/* <div className={styles.inputWrap}>
            <TextField
              label="Company Email Domain"
              name="companyEmailDomain"
              placeholder="domainmane.com"
              readOnly={dataUpdateAccount.loading}
              formik={formik}
            />
          </div> */}
        </div>
      </div>
      <div className={styles.accountInformation}>
        <div className={styles.title}>
          Customize what your employees see in the app
        </div>
        <div className={styles.customizeInfo}>
          <div className={styles.formData}>
            <div className={styles.companyLogo}>
              <label>Company Logo</label>
              {
                companyLogo &&
                <div className={styles.currentFile}>
                  <span>company_logo.png</span>
                  <img src={require('src/assets/images/icon-remove.svg')} alt="icon-remove" onClick={removeLogo}/>
                </div>
              }
              <p onClick={openFileUpload}>Upload <span className={styles.imageSize}>(size: 400px X 400px)</span></p>
              <input type="file" accept="image/x-png,image/jpeg"
                     className={styles.inputFile}
                     ref={refInputFile}
                     onChange={(event) => handleFileChange(event)}
              />
            </div>
            <div className={`${styles.inputWrap} ${styles.message}`}>
              <TextField
                label="Message to employees"
                multiline={true}
                name="employeeMessage"
                placeholder="Write a note that will inspire your employees to get excited and start moving. "
                readOnly={dataUpdateAccount.loading}
                formik={formik}
                inputProps={{maxLength: 120}}
              />
              <div className={styles.maxChar}>
                max char 120
              </div>
            </div>
          </div>
          <div className={styles.previewMobile}>
            {
              companyLogo &&
              <img src={companyLogo} alt="logo" className={styles.logo}/>
            }
            {
              formik.values.companyName &&
              <div className={styles.companyName}>{formik.values.companyName}</div>
            }
            {
              formik.values.employeeMessage &&
              <div className={styles.employeeMessage}>{formik.values.employeeMessage}</div>
            }
            <img src={require('src/assets/images/preview-mobile-account.svg')}
                 alt="preview-mobile"/>
          </div>
        </div>
      </div>
      <div className={styles.actionBtn}>
        <Button
          variant="outlined"
          type="button"
          disabled={dataUpdateAccount.loading}
          className={styles.btnCancel}
          onClick={cancelEdit}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          type="submit"
          disabled={dataUpdateAccount.loading || loading}
          className={styles.btn}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

EditComponent.propTypes = {
  dataUpdateAccount: PropTypes.object.isRequired,
  dataAccountInfo: PropTypes.object.isRequired,
  editedSuccessfully: PropTypes.func.isRequired,
  action: PropTypes.shape({
    updateAccount: PropTypes.func.isRequired,
    clearUpdateAccount: PropTypes.func.isRequired
  }).isRequired,
};


const mapStateToProps = (state) => {
  return {
    dataUpdateAccount: state.account.dataUpdateAccount,
    dataAccountInfo: state.account.dataAccountInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({
      updateAccount,
      clearUpdateAccount
    }, dispatch),
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(EditComponent));
