import { all } from "redux-saga/effects";
import authWatcher from "./auth/watcher";
import dashboardWatcher from "./dashboard/watcher";
import billingWatcher from "./billing/watcher";
import scheduleWatcher from "./schedule/watcher";
import sponsorshipWatcher from "./sponsorship/watcher";
import accountWatcher from "./account/watcher";
import onboardingWatcher from "./onboarding/watcher";
import galleryWatcher from "./gallery/watcher";
import challengeWatcher from "./challenge/watcher";
import forgotPasswodWatcher from "./forgotpassword/watcher";
import employeeWatcher from "./employees/watcher";
export default function* rootSaga() {
  yield all([
    authWatcher(),
    dashboardWatcher(),
    billingWatcher(),
    scheduleWatcher(),
    sponsorshipWatcher(),
    accountWatcher(),
    onboardingWatcher(),
    galleryWatcher(),
    challengeWatcher(),
    forgotPasswodWatcher(),
    employeeWatcher(),
  ]);
}
