import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';

import Container from '@material-ui/core/Container';

import styles from "./index.module.scss";
import {bindActionCreators} from "redux";
import {getTemplates, createChallenge, clearDataChallenge} from "src/redux/schedule/action";
import {setMessageDashboard, getCurrentSponsorship} from "src/redux/dashboard/action";
import {saveStep} from "src/redux/onboarding/action";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Grid from '@material-ui/core/Grid';
import Paper from 'src/components/Paper';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import MomentUtils from '@date-io/moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import {Redirect} from "react-router";
import {nextStep} from "src/redux/onboarding/action";
import moment from "moment";

const status = {
  NO_SPONSOR: 'NO_SPONSOR',
  IN_SCHEDULED: 'IN_SCHEDULED',
  IN_PROGRESS: 'IN_PROGRESS',
  ALL_COMPLETE: 'ALL_COMPLETE',
};

export const ScheduleContainer = (props) => {
  const {dataTemplates, dataChallenge, states, refUrl, dataAccountInfo, dataOnboarding, currentSponsorship} = props;
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [description, setDescription] = React.useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalSponsorship, setOpenModalSponsorship] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState({});
  const [redirect, setRedirect] = useState(null);
  const [errorStartDate, setErrorStartDate] = useState(false);
  const [errorEndDate, setErrorEndDate] = useState(false);
  const [errorValidateStartDate, setErrorValidateStartDate] = useState(false);
  const [errorValidateEndDate, setErrorValidateEndDate] = useState(false);

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  useEffect(() => {
    props.action.getTemplates();
    props.action.getCurrentSponsorship();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataChallenge.challenge) {
      setOpenModal(false);
      if (getSponsorStatus(currentSponsorship) === status.NO_SPONSOR) {
        setTimeout(() => {
          setOpenModalSponsorship(true);
        }, 300)
      } else {
        gotoDashboard();
      }
    }
    // eslint-disable-next-line
  }, [dataChallenge]);
  
  const openPreview = (unid) => {
    const url = `/preview-challenge/${unid}`;
    window.open(url, '_blank', 'location=yes,height=715,width=1920,scrollbars=yes,status=yes')
  };

  const openUseChallenge = (unid) => {
    let template = templates.find(template => template.unid === unid);
    setCurrentTemplate(template);
    setOpenModal(true);
  };

  const getSponsorStatus = (sponsorship) => {
    let result = status.NO_SPONSOR;
    if (sponsorship) {
      if (sponsorship.current && typeof sponsorship.current === 'object') {
        result = status.IN_PROGRESS;
      } else if (sponsorship.upcomingSponsorships && sponsorship.upcomingSponsorships.length > 0) {
        result = status.IN_SCHEDULED;
      } else if (sponsorship.totalSponsorship > 0) {
        result = status.ALL_COMPLETE;
      }
    }

    return result;
  };

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const gotoDashboard = () => {
    props.action.clearDataChallenge();
    setOpenModalSponsorship(false);
    if (states.challenge === 'doing' || (dataAccountInfo.account && dataAccountInfo.account.user &&
      dataAccountInfo.account.user.onBoardingStep === 2)) {
      let statusSponsorship = states.sponsorship === 'todo' ? 'doing' : states.sponsorship;
      props.action.nextStep({
        accountSetup: 'done',
        challenge: 'done',
        sponsorship: statusSponsorship
      });
      props.action.saveStep({
        onBoardingStep: 3,
        finishedOnBoarding: false
      });
      props.action.setMessageDashboard(`Excellent! Your challenge is confirmed! If its the next scheduled challenge you’ll see it in the dashboard, otherwise go to Challenges and you’ll find it there. We’re excited to see your progress. `);
      setRedirect(`/dashboard#onboarding`);
    } else {
      props.action.setMessageDashboard(`Excellent! Your challenge is confirmed! If its the next scheduled challenge you’ll see it in the dashboard, otherwise go to Challenges and you’ll find it there. We’re excited to see your progress. `);
      setRedirect('/dashboard')
    }

  };

  const sponsorEmployees = () => {
    props.action.clearDataChallenge();
    setOpenModalSponsorship(false);
    if (states.challenge === 'doing' || (dataAccountInfo.account && dataAccountInfo.account.user &&
      dataAccountInfo.account.user.onBoardingStep === 2)) {
      props.action.saveStep({
        onBoardingStep: 3,
        finishedOnBoarding: false
      });
      props.action.nextStep({
        accountSetup: 'done',
        challenge: 'done',
        sponsorship: 'doing'
      })
    }
    setRedirect(`/sponsorship/${dataChallenge.challenge.unid}`)
  };

  const cancelUseChallenge = () => {
    setOpenModal(false);
    setDescription("");
    setStartDate(null);
    setEndDate(null);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const createChallengeFromTemplate = () => {
    if (!validateInput()) {
      return;
    }
    const data = {
      "name": currentTemplate.name,
      "distance": currentTemplate.distance,
      "picture": currentTemplate.picture,
      "startDate": startDate ? moment(startDate).format("YYYY-MM-DD").toString()+'T00:00:00Z' : null,
      "endDate": endDate ? moment(endDate).format("YYYY-MM-DD").toString()+'T00:00:00Z' : null,
      "description": description,
      "templateUnid": currentTemplate.unid
    };
    props.action.createChallenge(data);
  };



  const validateInput = () => {
    let isValid = true;
    if (!startDate || errorStartDate) {
      isValid = false;
      setErrorValidateStartDate(true);
    }
    if (!endDate || errorEndDate) {
      isValid = false;
      setErrorValidateEndDate(true);
    }
    return isValid;
  };

  const templates = dataTemplates.templates ? dataTemplates.templates : [];

  const cancelSchedule = () => {
    if (refUrl) {
      setRedirect(refUrl);
    } else if (states.challenge === 'doing') {
      setRedirect(`/dashboard#onboarding`)
    } else {
      setRedirect(`/dashboard`)
    }
  };

  const gotoSponsorship = () => {
    setRedirect(`/sponsorship/0`)
  };

  const onErrorStartDate = (err, value) => {
    if (err || !value) {
      setErrorStartDate(true);
    } else {
      setErrorStartDate(false);
      setErrorValidateStartDate(false);
    }
  };

  const onErrorEndDate = (err, value) => {
    if (err || !value) {
      setErrorEndDate(true);
    } else {
      setErrorEndDate(false);
      setErrorValidateEndDate(false);
    }
  };

  const renderClassRoad = index => {
    if (index % 2 === 1) {
      return `${styles.road} ${styles.odd}`
    }
    return `${styles.road} ${styles.even}`
  };

  if (redirect && !dataOnboarding.loading) {
    return <Redirect to={redirect}/>;
  }
  if (dataAccountInfo.account && dataAccountInfo.account.user.onBoardingStep === 1 && !dataAccountInfo.account.user.finishedOnBoarding) {
    //return <Redirect to='/onboarding'/>;
  }

  return (
    <Container className={styles.container}>
      <div className={styles.inner}>
        <h1 className={styles.title}>Schedule a Challenge</h1>
        <img src={require('src/assets/images/icon-cancel-schedule.svg')} className={styles.imgCancel} alt="cancel"
             onClick={cancelSchedule}/>
        <div className={styles.challenges}>
          <Paper
            className={styles.paper}
            loading={(!dataTemplates || dataTemplates.loading)}
          >
            {
              templates.length ?
                <Grid container spacing={4}>
                  {
                    templates.map((template, index) => {
                      if (index === 0) {
                        return (
                          <Grid item xs={12} sm={12} key={index}>
                            <div className={`${styles.challenge} ${styles.firstChallenge}`}>
                              <div className={styles.action}>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  type="button"
                                  className={styles.useChallenge}
                                  onClick={() => {
                                    openUseChallenge(template.unid)
                                  }}
                                >
                                  Use Challenge
                                </Button>
                                <Button
                                  variant="contained"
                                  type="button"
                                  className={styles.previewChallenge}
                                  onClick={() => {
                                    openPreview(template.unid)
                                  }}
                                >
                                  Preview Challenge
                                </Button>
                              </div>
                              <div className={styles.challengeInfo}>
                                <h2 className={styles.name}>{template.name}</h2>
                                <p>Recommended duration: {template.recommendedDuration}</p>
                              </div>
                              <div className={styles.road}>
                                <img src={require('src/assets/images/road.svg')} alt="road" />
                                <div>
                                  <span className={styles.miles}>{template.distance}</span> miles
                                </div>
                              </div>

                            </div>
                          </Grid>
                        )
                      }
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <div className={styles.challenge}>
                            <div className={styles.action}>
                              <Button
                                variant="contained"
                                color="secondary"
                                type="button"
                                className={styles.useChallenge}
                                onClick={() => {
                                  openUseChallenge(template.unid)
                                }}
                              >
                                Use Challenge
                              </Button>
                              <Button
                                variant="contained"
                                type="button"
                                className={styles.previewChallenge}
                                onClick={() => {
                                  openPreview(template.unid)
                                }}
                              >
                                Preview Challenge
                              </Button>
                            </div>
                            <div className={renderClassRoad(index)}>
                              <span className={styles.miles}>{template.distance}</span> miles
                            </div>
                            <div className={styles.challengeInfo}>
                              <p className={styles.recommended}>Recommended duration: {template.recommendedDuration}</p>
                              <div className={styles.description}>
                                {template.description}
                              </div>
                            </div>
                          </div>
                        </Grid>
                      )
                    })
                  }
                </Grid> : null
            }
          </Paper>
        </div>
        <div className={styles.actionBtn}>
          <Button
            variant="outlined"
            color="secondary"
            type="button"
            disabled={dataTemplates.loading}
            className={styles.btnBack}
            onClick={cancelSchedule}
          >
            <img src={require('src/assets/images/arrow-left-pink.svg')} alt="back"/>
            Back
          </Button>
          {
            states.challenge === 'doing' || (dataAccountInfo.account && dataAccountInfo.account.user &&
              dataAccountInfo.account.user.onBoardingStep === 2) ?
              <Button
                variant="outlined"
                color="secondary"
                type="submit"
                disabled={dataTemplates.loading}
                className={styles.btn}
                onClick={gotoSponsorship}
              >
                Next
              </Button> : null
          }
        </div>
      </div>
      <Modal
        className={styles.modalSchedule}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {
          currentTemplate && (
            <Fade in={openModal}>
              <div className={styles.contentModal}>
                <h3 className={styles.titleModal}>Challenge Details for {currentTemplate.name}</h3>
                <div className={styles.labelDate}>
                  Start / End Dates
                </div>
                <div className={styles.inputDate}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      onError={onErrorStartDate}
                      error={errorValidateStartDate}
                      autoOk={true}
                      disableToolbar
                      variant="inline"
                      format="MM/DD/YYYY"
                      margin="normal"
                      value={startDate}
                      minDate={moment()}
                      onChange={handleStartDateChange}
                      name="startDate"
                      placeholder="mm/dd/yyyy"
                      keyboardIcon={<img src={require('src/assets/images/icon-calendar.svg')}
                                         alt=""/>}
                    />
                    <KeyboardDatePicker
                      onError={onErrorEndDate}
                      error={errorValidateEndDate}
                      autoOk={true}
                      disableToolbar
                      variant="inline"
                      format="MM/DD/YYYY"
                      margin="normal"
                      value={endDate}
                      minDate={moment()}
                      onChange={handleEndDateChange}
                      name="endDate"
                      placeholder="mm/dd/yyyy"
                      keyboardIcon={<img src={require('src/assets/images/icon-calendar.svg')}
                                         alt=""/>}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className={styles.description}>
                  Message to employees
                </div>
                <TextField
                  multiline={true}
                  className={styles.textDescription}
                  placeholder={"This message will appear in the email introducing the challenge. If you have any prizes for the winners we recommend you list them here."}
                  name="description"
                  value={description}
                  inputProps={{maxLength: 140}}
                  onChange={handleChangeDescription}
                  error={true}
                />
                <p className={styles.maxChar}>
                  140 char max
                </p>
                {isNaN(Date.parse(startDate))?null:datesAreOnSameDay(new Date(Date.parse(startDate)),new Date())?null:<div className={styles.note}>
                  <span>*</span>
                  <p>
                    Since this is scheduled for a date in the future you will see it in the upcoming
                    section of
                    challenges and on your dashboard.
                  </p>
                </div>}
                <div className={styles.modalFooter}>
                  <Button
                    variant="outlined"
                    type="button"
                    onClick={cancelUseChallenge}
                    disabled={dataChallenge.loading}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    type="button"
                    onClick={createChallengeFromTemplate}
                    disabled={dataChallenge.loading}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Fade>
          )
        }
      </Modal>
      <Modal
        className={styles.modalSponsorship}
        open={openModalSponsorship}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalSponsorship}>
          <div className={styles.contentModal}>
            <p className={styles.titleModal}>Sponsorship?</p>
            <p className={styles.confirmMessage}>Would you like to sponsor employees during this
              challenge?</p>
            <div className={styles.modalFooter}>
              <Button
                variant="outlined"
                type="button"
                onClick={gotoDashboard}
                disabled={dataChallenge.loading}
              >
                No
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                type="button"
                onClick={sponsorEmployees}
                disabled={dataChallenge.loading}
              >
                Yes
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </Container>
  );
};

ScheduleContainer.propTypes = {
  dataTemplates: PropTypes.object.isRequired,
  dataChallenge: PropTypes.object.isRequired,
  dataAccountInfo: PropTypes.object.isRequired,
  dataOnboarding: PropTypes.object.isRequired,
  states: PropTypes.object.isRequired,
  action: PropTypes.shape({
    getTemplates: PropTypes.func.isRequired,
    createChallenge: PropTypes.func.isRequired,
    setMessageDashboard: PropTypes.func.isRequired,
    clearDataChallenge: PropTypes.func.isRequired,
    saveStep: PropTypes.func.isRequired,
    getCurrentSponsorship: PropTypes.func.isRequired,
    currentSponsorship: PropTypes.object.isRequired,
  }).isRequired,
};

const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

const mapStateToProps = (state) => {
  return {
    dataTemplates: state.schedule.dataTemplates,
    dataChallenge: state.schedule.dataChallenge,
    dataAccountInfo: state.account.dataAccountInfo,
    dataOnboarding: state.onboarding.dataOnboarding,
    refUrl: state.schedule.refUrl,
    states: state.onboarding.states,
    currentSponsorship: state.dashboard.currentSponsorship,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({
      getTemplates,
      createChallenge,
      nextStep,
      setMessageDashboard,
      clearDataChallenge,
      saveStep,
      getCurrentSponsorship
    }, dispatch),
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ScheduleContainer));
