import React, {useEffect} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import PropTypes from "prop-types";
import styles from "../index.module.scss";
import moment from "moment";
import {FormatMoney} from 'src/utils';
import {bindActionCreators} from "redux";
import {getInvoices} from "src/redux/billing/action";
import {connect} from "react-redux";
import Paper from "src/components/Paper";

export const InvoicesComponent = (props) => {
  const {dataInvoices} = props;
  const invoices = dataInvoices.billingInvoices ? dataInvoices.billingInvoices : [];

  useEffect(() => {
    props.action.getInvoices();

    // eslint-disable-next-line
  }, []);

  const viewDetail = (unid) => {
    const url = `/invoices/${unid}`;
    window.open(url, '_blank', 'location=yes,height=715,width=1920,scrollbars=yes,status=yes')
  };

  return (
    <Paper loading={dataInvoices.loading}
           className={styles.paper}
    >
      {
        dataInvoices.billingInvoices &&
        <div className={styles.tableInvoices}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.date}>Date</TableCell>
                <TableCell className={styles.orderID}>Order ID</TableCell>
                <TableCell className={styles.product}>Product/Donations</TableCell>
                <TableCell className={styles.total}>Total</TableCell>
                <TableCell className={styles.status}>Status</TableCell>
                <TableCell className={styles.actions}></TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Table>
            <TableBody>
              {invoices.map((invoice, index) => (
                <TableRow key={index}>
                  <TableCell className={styles.date}>{moment.utc(invoice.date).format('MM/DD/YYYY')}</TableCell>
                  <TableCell className={styles.orderID}>{invoice.unid}</TableCell>
                  <TableCell className={styles.product}>{invoice.items ? invoice.items[0].description : null}</TableCell>
                  <TableCell className={styles.total}>{FormatMoney(invoice.amount)}</TableCell>
                  <TableCell className={styles.status}>{invoice.status.replace(/^\w/, c => c.toUpperCase())}</TableCell>
                  <TableCell className={styles.actions}>
                  <span className={styles.viewDetail} onClick={() => {
                    viewDetail(invoice.unid)
                  }}>View</span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      }
    </Paper>
  );
};


InvoicesComponent.propTypes = {
  dataInvoices: PropTypes.object.isRequired,
  onViewDetail: PropTypes.func.isRequired,
  action: PropTypes.shape({
    getInvoices: PropTypes.func.isRequired,
  }).isRequired,
};


const mapStateToProps = (state) => {
  return {
    dataInvoices: state.billing.dataInvoices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({
      getInvoices
    }, dispatch),
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvoicesComponent);
