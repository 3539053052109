import React from 'react';
import styles from "./index.module.scss";

export const LoadingComponent = (props) => {
    const { className, ...rest } = props;
    return (
        <div {...rest} className={`${styles["site-loading"]} ${className}`}>
            <div className={styles["lds-ellipsis"]}>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
	);
};

export default (LoadingComponent);
