import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import styles from "../index.module.scss";
import { bindActionCreators } from "redux";
import { getAccountInfo } from "src/redux/account/action";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Paper from "src/components/Paper";
import { Button } from '@material-ui/core';

export const DetailComponent = (props) => {
    const { dataAccountInfo } = props;
    const { account } = dataAccountInfo;
    const [message, setMessage] = useState('')
    useEffect(() => {
        props.action.getAccountInfo();
        // eslint-disable-next-line
    }, []);

    const copyToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setMessage('Copied to Clipboard!')
        setTimeout(() => {
            setMessage('');
            window.clearTimeout()
        }, 3000)
    };
    return (
        <Paper className={styles.paper}
            loading={dataAccountInfo.loading}
        >
            {
                dataAccountInfo.account &&
                <Fragment>
                    <div className={styles.accountInformation}>
                        <div className={styles.title}>
                            Account information
                            </div>
                        <p>{account.company &&account.user.name}</p>
                        <p>{account.company &&account.company.name}</p>
                        <p>{ account.company && account.company.emailDomain}</p>
                    </div>
                    <div className={styles.accountInformation}>
                        <div className={styles.title}>
                            Employee view in the app
                            </div>
                        <div className={styles.detail}>

                            <div className={styles.previewMobileDetail}>
                                {
                                    account.company && account.company.logo &&
                                    <img src={account.company.logo} alt="logo" className={styles.logo} />
                                }
                                {
                                    account.company && account.company.name &&
                                    <div className={styles.companyName}>{account.company.name}</div>
                                }
                                {
                                   account.company && account.company.employeeMessage &&
                                    <div className={styles.employeeMessage}>{account.company.employeeMessage}</div>
                                }
                                <img src={require('src/assets/images/preview-mobile-account.svg')}
                                    alt="preview-mobile" />
                            </div>
                            {
                                 account.company && account.company.joinCode &&
                                <div className={styles.codeInfo}>

                                    <div className={styles.code}>
                                        <p>Join Code</p>&nbsp;
                                        <span className={styles.txtCode}>{account.company.joinCode}</span>
                                        <Button onClick={() => {
                                            copyToClipboard(account.company.joinCode)
                                        }} className={styles.btnCoppy} variant="contained" color="primary">Copy</Button>
                                    </div>
                                    {
                                        message &&
                                        <p className={styles.message} >{message}</p>
                                    }
                                </div>
                            }

                        </div>

                    </div>
                </Fragment>
            }
        </Paper >
    );
};

DetailComponent.propTypes = {
    dataAccountInfo: PropTypes.object.isRequired,
    action: PropTypes.shape({
        getAccountInfo: PropTypes.func.isRequired
    }).isRequired,
};


const mapStateToProps = (state) => {
    return {
        dataAccountInfo: state.account.dataAccountInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators({
            getAccountInfo,
        }, dispatch),
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(DetailComponent));
