import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import {makeStyles} from "@material-ui/core";

export const InputComponent = (props) => {
  const {error, name, formik, type, readOnly, width, ...rest} = props;
  const useStylesInput = makeStyles({
    root: {
      width: width,
      marginBottom: 2,
      '&:before': {
        borderBottom: '1px solid #1B1B1B !important'
      },
      ' input': {
        paddingBottom: 2
      }
    },
  });
  const classes = useStylesInput();
  let value;
  if (name.includes('[')) {
    const nameArray = name.split('[')[0];
    const index = name.split('[')[1].slice(0, -1);
    value = formik.values[nameArray][index]
  } else {
    value = formik.values[name]
  }
  return (
    <Input
      className={classes.root}
      type={(name === 'password' && name) || type}
      name={name}
      error={!!(formik.touched[name] && formik.errors[name]) || error}
      value={value}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      readOnly={readOnly}
      {...rest}
    />
  );

};

InputComponent.propTypes = {
  formik: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.number,
  error: PropTypes.bool
};

InputComponent.defaultProps = {
  type: 'text',
  readOnly: false,
  width: 60,
  error: false
};

export default (InputComponent);
