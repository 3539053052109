import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import Layout from 'src/components/Layout';
import styles from './index.module.scss';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import YourPlan from './YourPlan';
import DetailInvoice from './DetailInvoice';
import Invoices from './Invoices';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const AntTabs = withStyles({
  root: {
    border: 'none',
  },
  indicator: {
    backgroundColor: '#4BA97C',
    height: 4
  },
})(Tabs);

const AntTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    marginRight: 50,
    fontWeight: 300,
    fontSize: 26,
    minWidth: 50,
    padding: 0,
    color: '#444444',
    fontFamily: [
      '"Charity Miles"',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    '&:hover': {
      color: '#444444',
      opacity: 1,
    },
    '&$selected': {
      color: '#444444'
    },
    '&:focus': {
      color: '#444444',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);


export const BillingContainer = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [invoiceDetail, setInvoiceDetail] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const _back = () => {
    setInvoiceDetail(null);
  };

  const _viewDetail = (invoice) => {
    setInvoiceDetail(invoice);
  };

  return (
    <Layout>
      {
        invoiceDetail ?
          <DetailInvoice invoiceInfo={invoiceDetail} back={_back} /> :
          <div className={styles.billing}>
            <h1 className={styles.title}>Billing</h1>
            <div className={classes.root}>
              <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                <AntTab label="Your Plan" />
                <AntTab label="Invoices" />
              </AntTabs>
              <TabPanel value={value} index={0}>
                <YourPlan/>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Invoices onViewDetail={_viewDetail}/>
              </TabPanel>
            </div>
          </div>
      }
    </Layout>
  );
};
export default withRouter(BillingContainer);
