import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Loading from '../Loading';
import styles from "./index.module.scss";

export const PaperComponent = (props) => {
    const { children, loading, className, ...rest } = props;
    const [loaded, setLoaded] = useState(true);

    useEffect(() => {
        setLoaded(loading);
    }, [loading]);

    return (
        <Paper {...rest} className={`${styles.root} ${className}`}>
            {loaded && <Loading/>}
            {children}
        </Paper>
	);
};

PaperComponent.propTypes = {
    children: PropTypes.any,
    loading: PropTypes.bool,
};

PaperComponent.defaultProps = {
    // loading: true,
};

export default (PaperComponent);
