import React, {useState, useEffect} from 'react';
import {NavLink, Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {signOut} from 'src/redux/auth/action';
import AuthStorage from 'src/utils/authStorage';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';

import styles from './index.module.scss';

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    dataAccountInfo: state.account.dataAccountInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({
      signOut,
    }, dispatch),
  };
};


export const NavigationComponent = (props) => {
  const {auth, navData, dataAccountInfo} = props;
  const [anchorEl, setAnchorEl] = useState(undefined);

  useEffect(() => {
    if (!AuthStorage.getAccessToken()) {
      window.location.href = '/signin'
    }
  }, [auth]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleLogout = () => {
    AuthStorage.destroy();
    window.location.href = '/signin'
  };

  return (
    <div className={styles.navigation}>
      <Link to="/">
        <div className={styles.logo}>
          <img src={require('src/assets/images/logo.svg')} alt="CM"/>
        </div>
      </Link>
      <div className={styles.user} onClick={handleClick}>
        <p>
          {dataAccountInfo.account ? dataAccountInfo.account.user.name : null}
        </p>
        <IconButton size="small" aria-controls="user-menu" aria-haspopup="true">
          <span className={'mdi mdi-menu-down ' + styles.icon}/>
        </IconButton>
      </div>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          className: styles.userMenu,
        }}
      >
        <MenuItem onClick={handleLogout} className={styles.userItem}>Logout</MenuItem>
        <MenuItem onClick={handleClose} component={NavLink} className={styles.userItem}
                  activeClassName={styles.userActive} exact to="/account">Account</MenuItem>
        <MenuItem onClick={handleClose} component={NavLink} className={styles.userItem}
                  activeClassName={styles.userActive} exact to="/resources">Resources</MenuItem>
        <MenuItem onClick={handleClose} component={NavLink} className={styles.userItem}
                  activeClassName={styles.userActive} exact to="/billing">Billing</MenuItem>
      </Menu>
      <div className={styles.menu}>
        {navData.map((el, i) => {
          if (el && el.type === 'group') {
            return (
              <div key={i} className={styles.group}>{el.text}</div>
            );
          }
          return (
            <Button
              key={i}
              fullWidth
              component={NavLink}
              to={el.url}
              className={styles.item}
              activeClassName={styles.active}
              exact
            >
                            <span className={styles.inner}>
                                {el.icon &&
                                <span className={`${styles.icon} ${el.icon.classes}`} style={el.icon.styles}/>}
                              <span>{el.text}</span>
                            </span>
            </Button>
          );
        })}
      </div>
    </div>
  );
};

NavigationComponent.propTypes = {
  auth: PropTypes.object.isRequired,
  dataAccountInfo: PropTypes.object.isRequired,
  navData: PropTypes.array,
  action: PropTypes.shape({
    signOut: PropTypes.func.isRequired,
  }).isRequired,
};

NavigationComponent.defaultProps = {
  navData: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavigationComponent);
