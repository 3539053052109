import React, { useEffect, Fragment, useState } from "react";
import Paper from "src/components/Paper";
import styles from "../box.module.scss";
import stylesChallenge from "./index.module.scss";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import {
  getChallenges,
  getCSVChallenges,
  getCSVChallengesById,
  clearCSV,
  getCSVDetailsChallenge,
} from "src/redux/dashboard/action";
import {clearDataUpdateChallenge, updateChallenge} from "src/redux/challenge/action";
import { connect } from "react-redux";
import moment from "moment";
import Occurring from "./Occurring";
import Previous from "./Previous";
import Upcoming from "./Upcoming";
import Schedule from "./Schedule";
import {Button} from '@material-ui/core';
import EditModal from '../../../components/Modal/EditChallengeModal';
import UpcomingChallengeModal from '../../../components/Modal/UpcomingChallengeModal';
import ExportCsvModal from "src/components/Modal/ExportCsvModal";
import notify from "src/utils/notify";
export const ChallengeComponent = (props) => {

  const {
    dataChallenges,
    dataCSV,
    action,
    dataUpdateChallenge
  } = props;

  const [openExportCsvModal, setOpenExportCsvModal]= useState(false);
  const [isClickExport, setClickExport]= useState(false);
  const [openModalChallenge, setOpenModalChallenge]= useState(false);
  const [pastChallenges, setpastChallenges]= useState([]);
  const [currentChallenge, setcurrentChallenge]= useState(null);
  const [leaderboard, setLeaderboard]= useState(null);
  const [upcomingChallenge, setupcomingChallenge]= useState(null);
  const [challengeActive, setChallengeActive] = useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [description, setDescription] = React.useState("");
  const [errorStartDate, setErrorStartDate] = useState(false);
  const [errorEndDate, setErrorEndDate] = useState(false);
  const [errorValidateStartDate, setErrorValidateStartDate] = useState(false);
  const [errorValidateEndDate, setErrorValidateEndDate] = useState(false);
  const [minDate, setMinDate] = useState(moment());
  const [name, setName] = useState(null);
  const [distance, setDistance] = useState(null);

  const actionChallenge = (challenge) => {
    setChallengeActive(challenge);
    setDescription(challenge.description);
    setStartDate(moment(challenge.startDate));
    setEndDate(moment(challenge.endDate));
  };

  useEffect(()=>{
    setcurrentChallenge(null);
    setupcomingChallenge(null);
    setLeaderboard(null);
    setpastChallenges([]);
    if (dataChallenges.current) {
      let current = dataChallenges.current.challenge;
      let leaders = dataChallenges.current.leaderboard
      current.days = moment(current.endDate).diff(
        moment(current.startDate),
        "d"
      );
      current.daysRemaining = moment(current.endDate).diff(
        moment(),
        "d"
      );
      setcurrentChallenge(current);
      setLeaderboard(leaders);
    }
    if (dataChallenges.upcoming && dataChallenges.upcoming.challenge) {
      let upcoming = dataChallenges.upcoming;
      upcoming.challenge.days = moment(
        upcoming.challenge.endDate
      ).diff(moment(upcoming.challenge.startDate), "d");
      upcoming.challenge.daysRemaining = moment().diff(
        moment(upcoming.challenge.startDate),
        "d"
      );
      console.log(dataChallenges.upcoming);
      console.log(upcoming);
      actionChallenge(upcoming.challenge)
      setupcomingChallenge(upcoming)
    }
    if (dataChallenges.past) {
      setpastChallenges(dataChallenges.past)
    }
  },[dataChallenges])

  const isSchedule =
    dataChallenges &&
    !currentChallenge &&
    !pastChallenges.length &&
    !upcomingChallenge;

  useEffect(()=>{
    if(dataCSV.isSuccess){
      setOpenExportCsvModal(true)
    }
  },[dataCSV])

  const handleCloseExportCsvModal =()=>{
    // clear data CSV
    props.action.clearCSV();
    setOpenExportCsvModal(false)
    setClickExport(false);
  }

  useEffect(() => {
    props.action.getChallenges();
  }, []);

  useEffect(() => {
    if (dataUpdateChallenge.challenge) {
      setOpenModalChallenge(false)
      props.action.clearDataUpdateChallenge();
      props.action.getChallenges()
    }
  }, [dataUpdateChallenge]);

  const onErrorStartDate = (err, value) => {
    if (err || !value) {
      setErrorStartDate(true);
    } else {
      setErrorStartDate(false);
      setErrorValidateStartDate(false);
    }
  };

  const onErrorEndDate = (err, value) => {
    if (err || !value) {
      setErrorEndDate(true);
    } else {
      setErrorEndDate(false);
      setErrorValidateEndDate(false);
    }
  };
  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };
  const cancelUpdateChallenge = () => {
    setOpenModalChallenge(false);
  };
  
  const validateInput = () => {
    let isValid = true;
    if (!startDate || errorStartDate) {
      isValid = false;
      setErrorValidateStartDate(true);
    }
    if (!endDate || errorEndDate) {
      isValid = false;
      setErrorValidateEndDate(true);
    }
    return isValid;
  };

  const updateDataUpComingChallenge = () => {
    if (!validateInput()) {
      return;
    }
    let errorCount = 0;
    if(name === ''){
      notify.error('Name is invalid.')
      errorCount++;
    }

    if(distance <= 0){
      notify.error('Distance is invalid.')
      errorCount++;
    }
    if(errorCount > 0){
      return;
    }

    const data = {
      ...challengeActive,
      "startDate": startDate ? moment(startDate).format("YYYY-MM-DD").toString()+'T00:00:00Z' : null,
      "endDate": endDate ? moment(endDate).format("YYYY-MM-DD").toString()+'T00:00:00Z' : null,
      "description": description,
      name:name,
      distance: Number(distance)
    };
   
    props.action.updateChallenge(data);
  };

  const renderBody = () => {
    if (!dataChallenges) {
      return null;
    }
    if (currentChallenge) {
      return (
        <>
          <Occurring
            challenge={currentChallenge}
            getCSVChallengesById={action.getCSVChallengesById}
            getCSVChallenges={action.getCSVChallenges}
            leaderboard={leaderboard}
            getCSVDetailsChallenge={action.getCSVDetailsChallenge}
            clickExport={setClickExport}
          />
          <EditModal
            openModalChallenge={openModalChallenge}
            currentChallenge={currentChallenge}
            setOpenModalChallenge={setOpenModalChallenge}
            updateDataChallenge={updateDataChallenge}
          />
        </>
      );
    }
    if (upcomingChallenge) {
      return (
        <>
        <Upcoming
          getCSVChallengesById={action.getCSVChallengesById}
          upcomingChallenge={upcomingChallenge}
          getCSVDetailsChallenge={action.getCSVDetailsChallenge}
          clickExport={setClickExport}
        />
        <UpcomingChallengeModal 
          openModalChallenge={openModalChallenge}
          challengeActive={challengeActive}
          startDate={startDate}
          endDate={endDate}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          isDisabledInput={false}
          minDate={minDate}
          onErrorStartDate={onErrorStartDate}
          onErrorEndDate={onErrorEndDate}
          errorValidateStartDate={errorValidateStartDate}
          errorValidateEndDate={errorValidateEndDate}
          handleChangeDescription={handleChangeDescription}
          cancelUpdateChallenge={cancelUpdateChallenge}
          dataUpdateChallenge={dataUpdateChallenge}
          updateDataChallenge={updateDataUpComingChallenge}
          description={description}
          name={name}
          setName={setName}
          distance={distance}
          setDistance={setDistance}
      />
      </>
      );
    }
    if (pastChallenges.length) {
      return <Previous pastChallenges={pastChallenges} />;
    }
    return null;
  };

  const handleEditChallenge =()=>{
    setOpenModalChallenge(!openModalChallenge)
  }

  const updateDataChallenge = (data) => {
    let errorCount = 0;
    if(data.name === ''){
      notify.error('Name is invalid.')
      errorCount++;
    }

    if(data.distance <= 0){
      notify.error('Distance is invalid.')
      errorCount++;
    }
    if(errorCount > 0){
      return;
    }

    const bodyData ={
      ...currentChallenge,
      ...data,
      distance: Number(data.distance)
    }
   
    props.action.updateChallenge(bodyData);
  };


  return (
    <Paper
      className={styles.box}
      loading={!dataChallenges || dataChallenges.loading}
    >
      <div className={stylesChallenge.challenges}>
        {isSchedule ? (
          <Schedule />
        ) : (
          <Fragment>
            <div className={styles.header}>
              <div className={stylesChallenge.title}>Challenges</div>
              {dataChallenges && (currentChallenge || upcomingChallenge ) && <div className={styles.actions}>
                  <Button onClick={handleEditChallenge} className={styles.btn}>EDIT</Button>
              </div>
              }
            </div>
            {renderBody()}
          </Fragment>
        )}
      </div>
      {
        openExportCsvModal && isClickExport && (
          <ExportCsvModal 
            handleCloseExportCsvModal={handleCloseExportCsvModal}
          />
        )
      }
    </Paper>
  );
};

ChallengeComponent.propTypes = {
  dataChallenges: PropTypes.object.isRequired,
  dataCSV: PropTypes.object.isRequired,
  action: PropTypes.shape({
    getChallenges: PropTypes.func.isRequired,
    getCSVChallenges: PropTypes.func.isRequired,
    getCSVChallengesById: PropTypes.func.isRequired,
    clearCSV: PropTypes.func,
    getCSVDetailsChallenge: PropTypes.func,
    updateChallenge: PropTypes.func.isRequired,
    clearDataUpdateChallenge: PropTypes.func.isRequired
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    dataChallenges: state.dashboard.dataChallenges,
    dataCSV: state.dashboard.dataCSV,
    dataUpdateChallenge: state.challenge.dataUpdateChallenge,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      {
        getChallenges,
        getCSVChallenges,
        getCSVChallengesById,
        clearCSV,
        getCSVDetailsChallenge,
        updateChallenge,
        clearDataUpdateChallenge
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeComponent);
