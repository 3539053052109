import React, { useState, Fragment } from 'react';

import styles from "../index.module.scss";
import PropTypes from "prop-types";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import moment from "moment"
import { Redirect } from "react-router";

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    paddingRight: 10,
    marginLeft: 0,
    paddingLeft: 0
  },
  icon: {
    borderRadius: '50%',
    width: 25,
    height: 25,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#fff',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#fff',
    },
  },
  checkedIcon: {
    backgroundColor: '#fff',
    '&:before': {
      display: 'block',
      width: 25,
      height: 25,
      backgroundImage: 'radial-gradient(#4BA97C,#4BA97C 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#fff',
    },
  },
});

const useStylesInput = makeStyles({
  root: {
    width: 62,
    marginBottom: 12,
    '&:before': {
      borderBottom: '1px solid #1B1B1B !important'
    },
  },
});

const StyledRadio = (props) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

const StyleInput = (props) => {
  const classes = useStylesInput();

  return (
    <Input
      className={classes.root}
      {...props}
    />
  );
};

export const CalculationComponent = (props) => {
  const { dataAmount, unid, states, dataCalculation } = props;
  const [recurring, setRecurring] = useState(dataCalculation.recurring);
  const [budgetType, setBudgetType] = useState(dataCalculation.budgetType);
  const [startDate, setStartDate] = useState(dataCalculation.startDate);
  const [endDate, setEndDate] = useState(dataCalculation.endDate);
  const [budget, setBudget] = useState(dataCalculation.budget);
  const [budgetEntire, setBudgetEntire] = useState(dataCalculation.budgetEntire);
  const [errorBudget, setErrorBudget] = useState(false);
  const [errorBudgetEntire, setErrorBudgetEntire] = useState(false);
  const [errorStartDate, setErrorStartDate] = useState(false);
  const [errorEndDate, setErrorEndDate] = useState(false);
  const [errorValidateStartDate, setErrorValidateStartDate] = useState(false);
  const [errorValidateEndDate, setErrorValidateEndDate] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [expectedEmployees, setExpectedEmployees] = useState(dataCalculation.expectedEmployees);
  const [errorExpectedEmployees, setErrorExpectedEmployees] = useState(false);

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleChangeRecurring = (event) => {
    setRecurring(event.target.value);
  };

  const handleChangeExpectedEmployees = (event) => {
    setExpectedEmployees(event.target.value);
    if (!event.target.value || !Number.isInteger(parseInt(event.target.value)) || parseInt(event.target.value) < 1) {
      setErrorExpectedEmployees(true);
    } else {
      setErrorExpectedEmployees(false);
    }
  };

  const handleChangeBudget = (event) => {
    setBudget(event.target.value);
    if (budgetType === 'person' && (!event.target.value || !Number.isInteger(parseInt(event.target.value))
      || parseInt(event.target.value) < 1)) {
      setErrorBudget(true);
    } else {
      setErrorBudget(false);
    }
  };

  const handleChangeBudgetEntireCompany = (event) => {
    setBudgetEntire(event.target.value);
    if (budgetType === 'company' && (!event.target.value || !Number.isInteger(parseInt(event.target.value)) ||
      parseInt(event.target.value) < 1)) {
      setErrorBudgetEntire(true);
    } else {
      setErrorBudgetEntire(false);
    }
  };

  const handleChangeBudgetType = (event) => {
    setErrorBudgetEntire(false);
    setErrorBudget(false);
    setBudgetType(event.target.value);
  };

  const calculateAmount = () => {
    if (!validateInput()) {
      return;
    }
    const dataCalculation = {
      startDate,
      endDate,
      budgetType,
      recurring,
      budgetEntire,
      expectedEmployees,
      budget
    };
    props.calcAmount(dataCalculation);
  };

  const validateInput = () => {
    let isValid = true;
    if (!expectedEmployees || !Number.isInteger(parseInt(expectedEmployees)) || parseInt(expectedEmployees) < 1) {
      isValid = false;
      setErrorExpectedEmployees(true);
    }
    if (budgetType === 'person' && (!budget || !Number.isInteger(parseInt(budget)) || parseInt(budget) < 1)) {
      isValid = false;
      setErrorBudget(true);
    }
    if (budgetType === 'company' && (!budgetEntire || !Number.isInteger(parseInt(budgetEntire)) ||
      parseInt(budgetEntire) < 1)) {
      isValid = false;
      setErrorBudgetEntire(true);
    }
    if (recurring === '2') {
      if (!startDate || errorStartDate) {
        isValid = false;
        setErrorValidateStartDate(true);
      }
      if (!endDate || errorEndDate) {
        isValid = false;
        setErrorValidateEndDate(true);
      }
    }
    return isValid;
  };

  const onErrorStartDate = (err, value) => {
    if (err || !value) {
      setErrorStartDate(true);
    } else {
      setErrorStartDate(false);
      setErrorValidateStartDate(false);
    }
  };

  const onErrorEndDate = (err, value) => {
    if (err || !value) {
      setErrorEndDate(true);
    } else {
      setErrorEndDate(false);
      setErrorValidateEndDate(false);
    }
  };

  const goBack = () => {
    // if (states.sponsorship === 'doing') {
    //   setRedirect(`/onboarding`)
    // } else {
    //   setRedirect('/dashboard')
    // }
    setRedirect('/dashboard#onboarding')
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Fragment>
      <div className={styles.sponsorshipInfo}>
        <div className={styles.introduce}>
          Woohoo! This is going to be great!<br />
          Sponsorship is per mile. This way employees see the money they earn go up with each mile<br />
          they do, giving them more incentive to keep at it. Get started below.
        </div>
        <div className={styles.questions}>
          {(parseInt(unid) === 0) ?
            <Fragment>
              <div className={styles.question}>
                <div className={styles.content}>How long do you want to sponsor employees?</div>
                <div className={styles.answers}>
                  <RadioGroup name="customized-radios">
                    <FormControlLabel checked={recurring === '1'}
                      onChange={handleChangeRecurring}
                      value="1"
                      control={<StyledRadio />
                      } label="I want sponsorship to reoccur monthly" />

                    <FormControlLabel checked={recurring === '2'}
                      onChange={handleChangeRecurring}
                      value="2"
                      control={<StyledRadio />
                      } label="I have specific dates for this sponsorship" />
                  </RadioGroup>
                  {
                    recurring === '2' && (
                      <div className={styles.period}>
                        <span>Starting on:</span>
                        <div className={styles.inputDate}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              onError={onErrorStartDate}
                              error={errorValidateStartDate}
                              autoOk={true}
                              disableToolbar
                              variant="inline"
                              format="MM/DD/YYYY"
                              margin="normal"
                              value={startDate}
                              minDate={Date.now()}
                              onChange={handleStartDateChange}
                              name="startDate"
                              placeholder="mm/dd/yyyy"
                              keyboardIcon={<img src={require('src/assets/images/icon-calendar.svg')} alt="" />}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <span>Ending on:</span>
                        <div className={styles.inputDate}>
                          <MuiPickersUtilsProvider utils={MomentUtils} className={styles.inputDate}>
                            <KeyboardDatePicker
                              onError={onErrorEndDate}
                              error={errorValidateEndDate}
                              disableToolbar
                              variant="inline"
                              format="MM/DD/YYYY"
                              margin="normal"
                              value={endDate}
                              autoOk={true}
                              onChange={handleEndDateChange}
                              name="endDate"
                              minDate={Date.now()}
                              placeholder="mm/dd/yyyy"
                              keyboardIcon={<img src={require('src/assets/images/icon-calendar.svg')} alt="" />}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className={styles.arrowBottom}>
                <img src={require('src/assets/images/arrow-bottom-sponsor.svg')} alt="arrow-bottom" />
              </div>
            </Fragment> : null
          }
          <div className={styles.question}>
            <div className={styles.content}>How much would you like to spend?</div>
            <div className={styles.answers}>
              <div className={styles.answer}>
                <StyledRadio
                  checked={budgetType === 'person'}
                  onChange={handleChangeBudgetType}
                  value="person"
                />
                <div className={styles.inputData}>
                  <StyleInput placeholder="100"
                    type="number" step="1"
                    value={budget}
                    error={errorBudget}
                    onChange={handleChangeBudget} />
                </div>
                <p className={styles.moreInfo}>
                  $ per employee {parseInt(unid) === 0 && recurring === '1' ? <span>(per month)</span> : null}
                </p>
              </div>
              <div className={styles.answer}>
                <StyledRadio
                  checked={budgetType === 'company'}
                  onChange={handleChangeBudgetType}
                  value="company"
                />
                <div className={styles.inputData}>
                  <StyleInput placeholder="10000"
                    type="number" step="1"
                    value={budgetEntire}
                    error={errorBudgetEntire}
                    onChange={handleChangeBudgetEntireCompany} />
                </div>
                <p className={styles.moreInfo}>
                  $ for entire company {parseInt(unid) === 0 && recurring === '1' ? <span>(per month)</span> : null}
                </p>
              </div>
            </div>
          </div>
          <div className={clsx(styles.arrowBottom, styles.arrowBottomLast)}>
            <img src={require('src/assets/images/arrow-bottom-sponsor.svg')} alt="arrow-bottom" />
          </div>
          <div className={styles.question}>
            <div className={styles.content}>How many employees do you expect to participate?</div>
            <div className={styles.answers}>
              <div className={styles.answer}>
                <div className={styles.inputData}>
                  <StyleInput placeholder="10000"
                    type="number" step="1"
                    value={expectedEmployees}
                    error={errorExpectedEmployees}
                    onChange={handleChangeExpectedEmployees}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.actionBtn}>
        <Button
          variant="outlined"
          color="secondary"
          type="button"
          disabled={dataAmount.loading}
          className={styles.btnBack}
          onClick={goBack}
        >
          <img src={require('src/assets/images/arrow-left-pink.svg')} alt="back" />
          Back
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          type="button"
          disabled={dataAmount.loading}
          className={styles.btn}
          onClick={calculateAmount}
        >
          {
            parseInt(unid) === 0 ?
              <Fragment>
                Calculate Sponsorship Amount
              </Fragment> :
              <Fragment>
                Calculate Per Mile Amount
              </Fragment>
          }
        </Button>
      </div>
    </Fragment>
  );
};

CalculationComponent.propTypes = {
  dataAmount: PropTypes.object.isRequired,
  dataCalculation: PropTypes.object.isRequired,
  calcAmount: PropTypes.func.isRequired,
  unid: PropTypes.string.isRequired,
};


export default CalculationComponent;
