import React, {useState, Fragment, useEffect} from 'react';
import {withRouter} from 'react-router-dom';

import Container from '@material-ui/core/Container';

import styles from "./index.module.scss";
import {bindActionCreators} from "redux";
import {calcAmount, createSponsorship, clearDataSponsorship} from "src/redux/sponsorship/action";
import {getChallenge} from "src/redux/challenge/action";
import {setMessageDashboard} from "src/redux/dashboard/action";
import {getBillingPlan} from "src/redux/billing/action";
import {saveStep} from "src/redux/onboarding/action";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Redirect} from "react-router";
import Calculation from "./Calculation";
import Detail from "./Detail";
import Summary from "./Summary";
import {nextStep} from "src/redux/onboarding/action";
import moment from 'moment';
import dateUtils from '../../utils/date'

export const SponsorshipContainer = (props) => {
  const {match, dataAmount, dataChallenge, dataSponsorship, states, dataAccountInfo, dataBillingPlan} = props;
  const [redirect, setRedirect] = useState(null);
  const [isSummary, setIsSummary] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [dataCommon, setDataCommon] = useState({});
  const [dataFinal, setDataFinal] = useState({});
  const [isClickConfirmSponsorship, setClickConformSponsorship] = useState(false)
  const [data, setData] = useState({
    name: "",
    companyCharityMPMRun: "",
    companyCharityMPMBike: "",
    employeeCharityMPMRun: "",
    employeeCharityMPMBike: "",
    additionalCharities: [''],
  });

  const [dataCalculation, setDataCalculation] = useState({
    startDate: null,
    endDate: null,
    budget: "",
    budgetEntire: "",
    recurring: '1',
    budgetType: 'person',
    expectedEmployees: ""
  });

  const {
    params: {unid}
  } = match;

  useEffect(() => {
    if (dataSponsorship.sponsorship) {
      let message;
      if (parseInt(unid) === 0) {
        message = `Excellent! Your sponsorship is confirmed! 
      We’re excited to see your progress. `
      } else {
        message = `Excellent! Your challenge and sponsorship is confirmed! 
        If its the next scheduled challenge you’ll see it in the dashboard, 
        otherwise go to the Challenges section and you’ll find it there. We’re excited to see your progress. `
      }
      if (states.challenge === 'doing') {
        props.action.nextStep({
          challenge: 'done',
          sponsorship: 'done',
          invitation: 'doing'
        });
        props.action.saveStep({
          onBoardingStep: 4,
          finishedOnBoarding: false
        });
        props.action.setMessageDashboard(message);
        setRedirect(`/dashboard#onboarding`)
      } else if (states.sponsorship === 'doing') {
        props.action.nextStep({
          sponsorship: 'done',
          invitation: 'doing'
        });
        props.action.saveStep({
          onBoardingStep: 4,
          finishedOnBoarding: false
        });
        props.action.setMessageDashboard(message);
        setRedirect(`/dashboard#onboarding`)
      } else if (dataAccountInfo.account && dataAccountInfo.account.user.onBoardingStep === 3) {
        props.action.saveStep({
          onBoardingStep: 4,
          finishedOnBoarding: false
        });
        props.action.nextStep({
          sponsorship: 'done',
          invitation: 'doing'
        });
        props.action.setMessageDashboard(message);
        setRedirect(`/dashboard#onboarding`)
      } else if(isClickConfirmSponsorship) {
        props.action.setMessageDashboard(message);
        setRedirect('/dashboard')
      }else{
        props.action.setMessageDashboard(message);
        setRedirect('/dashboard')
      }
    }
    // eslint-disable-next-line
  }, [dataSponsorship]);

  const cancelSponsorship = () => {
    props.action.clearDataSponsorship();
    if (states.sponsorship === 'doing') {
      setRedirect(`/dashboard#onboarding`)
    } else {
      setRedirect('/dashboard')
    }
  };

  useEffect(() => {
    // props.action.getBillingPlan();
    if (parseInt(unid) !== 0) {
      props.action.getChallenge({unid})
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataAmount.moneyPerMileRun) {
      setIsDetail(true);
    }
    // eslint-disable-next-line
  }, [dataAmount]);

  const calculateAmount = (dataCalc) => {
    const {startDate, endDate, budgetType, expectedEmployees, budget, recurring, budgetEntire} = dataCalc;

    console.log("Sponsorship date: " + (startDate ? startDate.toString() : null) 
              + " to " + (endDate ? endDate.toString() : null));

    const data = {
      startDate: startDate ? dateUtils.apiFormatOfDate(startDate.startOf('day')) : null,
      endDate: endDate ? dateUtils.apiFormatOfDate(endDate.startOf('day')) : null,
      budgetType,
      budget: budgetType === 'person' ? parseInt(budget) : parseInt(budgetEntire),
      expectedEmployees: parseInt(expectedEmployees),
      monthly: parseInt(unid) === 0 ? recurring === '1': false
    };
    if (recurring === '1') {
      data.startDate = dateUtils.apiFormatOfDate(moment().startOf('day'));
      data.endDate = dateUtils.apiFormatOfDate(moment().add(10, 'years').startOf('day'));
    }
    setDataCommon(data);
    setDataCalculation(dataCalc);
    props.action.calcAmount(data);
  };

  const _reviewAndConfirm = (detailInfo) => {
    setData(detailInfo);
    const additionalCharities = detailInfo.additionalCharities.filter(charity => !!charity);
    let startDate;
    let endDate;
    if (parseInt(unid) !== 0) {
      startDate = dataChallenge.challenge.startDate;
      endDate = dataChallenge.challenge.endDate;
    } else {
      startDate = dataCommon.startDate;
      endDate = dataCommon.endDate;
    }
    setDataFinal({
      challengeUnid: unid,
      emailTemplate: "Hello everyone, this is an email that is sent to employees",
      name: detailInfo.name,
      budgetType: dataCommon.budgetType,
      budget: dataCommon.budget,
      monthly: dataCommon.monthly,
      startDate,
      endDate,
      companyCharityMPMRun: detailInfo.companyCharityMPMRun ? parseFloat(detailInfo.companyCharityMPMRun) : 0,
      companyCharityMPMBike: detailInfo.companyCharityMPMBike ? parseFloat(detailInfo.companyCharityMPMBike) : 0,
      employeeCharityMPMRun: detailInfo.employeeCharityMPMRun? parseFloat(detailInfo.employeeCharityMPMRun) : 0,
      employeeCharityMPMBike: detailInfo.employeeCharityMPMRun ? parseFloat(detailInfo.employeeCharityMPMBike) : 0,
      additionalCharities
    });
    setIsSummary(true);
  };

  const _confirmSponsorship = () => {
    props.action.createSponsorship(dataFinal);
    setClickConformSponsorship(true)
  };

  const _editData = () => {
    setIsSummary(false);
  };

  const _goBackCalculation = () => {
    props.action.clearDataSponsorship();
    setIsDetail(false);
  };

  if (redirect) {
    return <Redirect to={redirect}/>;
  }

  return (
    <Container className={styles.container}>
      <div className={styles.inner}>
        <img src={require('src/assets/images/icon-cancel-schedule.svg')} className={styles.imgCancel} alt="cancel"
             onClick={cancelSponsorship}/>
        {
          isSummary ? (
            <Summary dataSponsorship={dataSponsorship}
                     dataBillingPlan={dataBillingPlan}
                     data={dataFinal}
                     unid={unid}
                     confirmSponsorship={_confirmSponsorship}
                     editData={_editData}/>
          ) : (
            <Fragment>
              <h1 className={styles.title}>Sponsor Employees</h1>
              {
                !isDetail ? (
                  <Calculation dataAmount={dataAmount}
                               calcAmount={calculateAmount}
                               dataCalculation={dataCalculation}
                               states={states}
                               unid={unid}/>
                ) : (
                  <Detail dataAmount={dataAmount}
                          dataSponsorship={dataSponsorship}
                          dataBillingPlan={dataBillingPlan}
                          dataCommon={dataCommon}
                          data={data}
                          goBack={_goBackCalculation}
                          reviewAndConfirm={_reviewAndConfirm}/>
                )
              }
            </Fragment>
          )
        }
      </div>
    </Container>
  );
};

SponsorshipContainer.propTypes = {
  dataAmount: PropTypes.object.isRequired,
  dataSponsorship: PropTypes.object.isRequired,
  states: PropTypes.object.isRequired,
  dataAccountInfo: PropTypes.object.isRequired,
  dataBillingPlan: PropTypes.object.isRequired,
  dataChallenge: PropTypes.object.isRequired,
  action: PropTypes.shape({
    createSponsorship: PropTypes.func.isRequired,
    calcAmount: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    saveStep: PropTypes.func.isRequired,
    setMessageDashboard: PropTypes.func.isRequired,
    getBillingPlan: PropTypes.func.isRequired,
    getChallenge: PropTypes.func.isRequired,
    clearDataSponsorship: PropTypes.func.isRequired,
  }).isRequired,
};


const mapStateToProps = (state) => {
  return {
    dataAmount: state.sponsorship.dataAmount,
    dataSponsorship: state.sponsorship.dataSponsorship,
    dataAccountInfo: state.account.dataAccountInfo,
    dataBillingPlan: state.billing.dataBillingPlan,
    dataChallenge: state.challenge.dataChallenge,
    states: state.onboarding.states,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({
      calcAmount,
      createSponsorship,
      nextStep,
      saveStep,
      setMessageDashboard,
      getBillingPlan,
      getChallenge,
      clearDataSponsorship
    }, dispatch),
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SponsorshipContainer));
