import React,{useState} from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Fade } from '@material-ui/core';
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import styles from "./index.module.scss";
export default function ExportCsvModal(props){
    const { handleCloseExportCsvModal} = props

    return (
    //     <Modal
    //         onClose={handleCloseExportCsvModal}
    //         className={styles.exportModal}
    //         open={openExportCsvModal}
    //         closeAfterTransition
    //         BackdropComponent={Backdrop}
    //         BackdropProps={{
    //             timeout: 500,
    //         }}
    //   >
    //     <Fade in={openExportCsvModal}>
    //         <div className={styles.exportContentModal}>
    //             <div className={styles.header}>
    //                 <span onClick={handleCloseExportCsvModal} >x</span>
    //             </div>
    //             <p>Your export will be emailed to you when it is ready to download.</p>
    //         </div>
    //     </Fade>
    //   </Modal>
        <Modal
            className={styles.modalAlert}
            open={true}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            onClose={handleCloseExportCsvModal}
        >
            <Fade in={true}>
                <div className={styles.contentModal}>
                <div className={styles.message}>
                    <p>Your export will be emailed to you when it is ready to download.</p>
                </div>
                <div className={styles.modalFooter}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        type="button"
                        onClick={handleCloseExportCsvModal}
                    >
                    OK
                </Button>
                </div>
                </div>
            </Fade>
        </Modal>
    )
}
ExportCsvModal.propTypes = {
    openModalChallenge: PropTypes.bool.isRequired,
};
  