import React, { useRef, useEffect, useState } from 'react';
import { ShortNumber } from 'src/utils';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getMilesCompleted, getCSVMilesCompleted } from "src/redux/dashboard/action";

import Chart from 'src/components/Chart';
import Paper from 'src/components/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import styles from '../box.module.scss';
import styles2 from './index.module.scss';

const filters = [
    {
        text: 'WTD',
        value: 'week',
    },
    {
        text: 'MTD',
        value: 'month',
    },
];

const mapStateToProps = (state) => {
    return {
        milesCompleted: state.dashboard.milesCompleted,
        dataCSVMilesCompleted: state.dashboard.dataCSV,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators({
            getMilesCompleted,
            getCSVMilesCompleted
        }, dispatch),
    };
};

export const MilesCompletedComponent = (props) => {
    const pointRef = useRef(undefined);
    const currentRef = useRef(undefined);
    const pointDateRef = useRef(undefined);
    const { milesCompleted } = props;
    const [current, setCurrent] = useState(filters[1]);
    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));

    const chartConfig = {
        data: {
            labels: [],
            datasets: [
                {
                    label: 'Miles',
                    type: 'line',

                    pointBackgroundColor: '#4BA97C',
                    pointBorderColor: '#4BA97C',
                    pointBorderWidth: 5,
                    pointHoverBorderWidth: 5,

                    backgroundColor: '#999',
                    borderColor: '#999',
                    borderWidth: 1,
                    lineTension: 0,
                    data: [],  // fill in progress
                    fill: false,
                },
            ]
        },
        options: {
            legend: {
                display: false,
            },
            scales: {
                xAxes: [
                    {
                        gridLines : {
                            display : false,
                        },
                        ticks: {
                            padding: 10,
                            fontSize: 14,
                            fontColor: '#868686',
                            fontFamily: '"Charity Miles", Roboto, Helvetica, Arial, sans-serif',
                        },
                    },
                ],
                yAxes: [
                    {
                        gridLines : {
                            drawBorder: false,
                            tickMarkLength: 15,
                        },
                        ticks: {
                            beginAtZero: true,
                            padding: 10,
                            fontSize: 14,
                            fontColor: '#868686',
                            fontFamily: '"Charity Miles", Roboto, Helvetica, Arial, sans-serif',
                        },
                    },
                ]
            },
            tooltips: {
                mode: 'index',
                titleFontStyle: 'normal',
                titleMarginBottom: 6,
                cornerRadius: 0,
                yPadding: 10,
                xPadding: 10,
                backgroundColor: '#fff',
                borderColor: 'rgba(0, 0, 0, 0.1)',
                borderRadius: 0,
                intersect: false,
                borderWidth: 1,
                titleFontSize: 14,
                titleFontColor: '#444',
                bodyFontColor: '#444',
                bodyFontSize: 14,
                displayColors: false,
                callbacks: {
                    title: (tooltipItem, data) => {
                        // show data outside canvas
                        pointRef.current.innerHTML = `
                            ${ShortNumber(data.datasets[0].dataRaw[tooltipItem[0].index].totalMiles)} 
                            <small>- ${data.datasets[0].dataRaw[tooltipItem[0].index].activeUsers || 0} Active users, 
                            $${(data.datasets[0].dataRaw[tooltipItem[0].index].totalMoney ? data.datasets[0].dataRaw[tooltipItem[0].index].totalMoney.toFixed(2) : 0)} Earned</small>`;
                        pointRef.current.style.display = '';
                        currentRef.current.style.display = 'none';
                        pointDateRef.current.innerHTML = moment.utc(data.datasets[0].dataRaw[tooltipItem[0].index].xLabel).format('ddd MMM DD, YYYY');

                        return (data.datasets[0].dataRaw[tooltipItem[0].index].activeUsers) + ' active users';
                    },
                    label: (tooltipItem, data) => {
                        if (data.datasets[0].dataRaw[tooltipItem.index].totalMoney) {
                            return '$' + data.datasets[0].dataRaw[tooltipItem.index].totalMoney.toFixed(2) + ' earned';
                        }
                        return '$0 earned';
                    },
                },
            },
        }
    };

    useEffect(() => {
        const query = { by: current.value, startDate };
        props.action.getMilesCompleted(query);

        // eslint-disable-next-line
    }, [current, startDate]);

    const bindDataToChart = (milesCompleted) => {
        if (milesCompleted.hasOwnProperty('by')) {
            let dataChart = [];
            let labels = [];
            let dataRaw = [];
            if (milesCompleted.by === 'month') {
                const firstDay = parseInt(moment.utc(milesCompleted.startDate).format('DD'), 10);
                const lastDay = parseInt(moment.utc(milesCompleted.endDate).format('DD'), 10);
                for(let i = firstDay; i <= lastDay; i++) {
                    labels[labels.length] = i.toString();

                    let value = NaN;
                    let raw = {};
                    milesCompleted.chartData.map((obj) => {
                        const dataDay = parseInt(moment.utc(obj.xLabel).format('DD'), 10);
                        if (dataDay === i) {
                            value = obj.totalMiles;
                            raw = obj;
                        }
                        return '';
                    });
                    dataChart[dataChart.length] = value;
                    dataRaw[dataRaw.length] = raw;
                }
                chartConfig.options.scales.yAxes[0].tickMarkLength = 10;
            } else {
                for(let i = 0; i < 7; i++) {
                    const loopDate = moment.utc(milesCompleted.startDate).add(i, 'days');
                    labels[labels.length] = loopDate.format('ddd MMM DD');

                    let value = NaN;
                    let raw = {};
                    milesCompleted.chartData.map((obj) => {
                        const dataDay = moment.utc(obj.xLabel);
                        if (dataDay.format('YYYY-MM-DD') === loopDate.format('YYYY-MM-DD')) {
                            value = obj.totalMiles;
                            raw = obj;
                        }
                        return '';
                    });
                    dataChart[dataChart.length] = value;
                    dataRaw[dataRaw.length] = raw;
                }
                chartConfig.options.scales.yAxes[0].tickMarkLength = 50;
            }

            return {
                ...chartConfig,
                data: {
                    ...chartConfig.data,
                    labels,
                    datasets: [
                        {
                            ...chartConfig.data.datasets[0],
                            data: dataChart,
                            dataRaw,
                        },
                    ]
                },
            };
        }
    };

    const handleChangeFilter = (filter) => event => {
        setStartDate(moment.utc().startOf('isoWeek').format('YYYY-MM-DD'));
        setCurrent(filter);
    };

    const handleNavigateFilter = (by, next) => event => {
        let newDate;
        if (by === 'month') {
            if (next) {
                newDate = moment.utc(startDate).add(1, 'months').startOf('month').format('YYYY-MM-DD');
            } else {
                newDate = moment.utc(startDate).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
            }
        } else {
            if (next) {
                newDate = moment.utc(startDate).add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
            } else {
                newDate = moment.utc(startDate).subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
            }
        }
        setStartDate(newDate);
    };

    const handleMouseLeave = (event) => {
        pointRef.current.style.display = 'none';
        currentRef.current.style.display = '';
        pointDateRef.current.innerHTML = moment.utc().format('ddd MMM DD, YYYY');
    };

    const disabledNext = (by) => {
        let appDay;
        let today;
        if (by === 'month') {
            appDay = moment.utc(startDate).startOf('month').format('YYYY-MM-DD');
            today = moment.utc().startOf('month').format('YYYY-MM-DD');
        } else {
            appDay = moment.utc(startDate).startOf('isoWeek').format('YYYY-MM-DD');
            today = moment.utc().startOf('isoWeek').format('YYYY-MM-DD');
        }
        return appDay >= today;
    };

    const showCurrentFilter = (by) => {
        if (by === 'month') {
            return moment.utc(startDate).format('MMMM YYYY');
        } else {
            if (moment.utc(startDate).format('MMM') === moment.utc(startDate).add(6, 'days').format('MMM')) {
                return `${moment.utc(startDate).format('MMMM DD')} - ${moment.utc(startDate).add(6, 'days').format('DD')}`;
            }
            return `${moment.utc(startDate).format('MMM DD')} - ${moment.utc(startDate).add(6, 'days').format('MMM DD')}`;
        }
    };

    return (
        <Paper
            className={styles.box}
            loading={(!milesCompleted || milesCompleted.loading)}
        >
            <div className={styles.header}>
                <h2>
                    <div ref={pointRef} style={{ display: 'none' }}/>
                    <div ref={currentRef}>
                        {ShortNumber(milesCompleted.totalMiles)} <small>- <NumberFormat
                            value={milesCompleted.activeUsers}
                            displayType="text"
                            thousandSeparator={true}
                        /> Active users, <NumberFormat
                            prefix="$"
                            value={milesCompleted.chartData ? milesCompleted.chartData.map((obj) => {
                                return obj.totalMoney;
                            }).reduce((a, b) => a + b, 0) : 0}
                            displayType="text"
                            thousandSeparator={true}
                            decimalScale={2}
                        /> Earned</small>
                    </div>
                </h2>
                <div className={styles.actions}>
                    <div className={styles2.monthSelect}>
                        <IconButton size="small" className={styles2.iconBtn} onClick={handleNavigateFilter(milesCompleted.by, false)}>
                            <img src={require('src/assets/images/arrow-left.svg')} alt=""/>
                        </IconButton>
                        <div className={styles2.textInsideBox}>
                            {showCurrentFilter(milesCompleted.by)}
                        </div>
                        <IconButton size="small" disabled={disabledNext(milesCompleted.by)} className={styles2.iconBtn} onClick={handleNavigateFilter(milesCompleted.by, true)}>
                            <img src={require('src/assets/images/arrow-right.svg')} alt=""/>
                        </IconButton>
                    </div>
                    &nbsp; &nbsp;
                    <div>
                        {filters.map((filter) => {
                            return (
                                <Button key={filter.text}
                                    className={styles.btn + (filter.text === current.text ? ` ${styles.active}` : '')}
                                    onClick={handleChangeFilter(filter)}
                                >{filter.text}</Button>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className={styles.nameWrap + ' ' + styles2.nameWrap}>
                <div className={styles.name}>
                    Miles Completed
                </div>
                <div className={styles2.pointDate} ref={pointDateRef}>
                    {moment.utc().format('ddd MMM DD, YYYY')}
                </div>
            </div>

            <div onMouseLeave={handleMouseLeave}>
                <Chart data={bindDataToChart(milesCompleted)}/>
            </div>

        </Paper>
    );
};

MilesCompletedComponent.propTypes = {
    milesCompleted: PropTypes.object.isRequired,
    dataCSVMilesCompleted: PropTypes.object.isRequired,
    action: PropTypes.shape({
        getMilesCompleted: PropTypes.func.isRequired,
        getCSVMilesCompleted: PropTypes.func.isRequired,
    }).isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MilesCompletedComponent);
