import React, { Fragment, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCurrentSponsorship, getCSVSponsorship, putSponsorshipById, getSponsorshipById, handleOpenModalEditSponsorship } from 'src/redux/dashboard/action';
import { clearDataSponsorship } from 'src/redux/sponsorship/action'
import ModalEditSponsorship from './modalEditSponsorshop';
import Paper from 'src/components/Paper';
import Button from '@material-ui/core/Button';
import styles from '../box.module.scss';
import { Link } from "react-router-dom";
import Status from './SponsorshipStatus';

const mapStateToProps = (state) => {
    return {
        currentSponsorship: state.dashboard.currentSponsorship,
        sponsorshipById: state.dashboard.sponsorshipById,
        isOpenModalEditSponsorship: state.dashboard.isOpenModalEditSponsorship
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators({
            getCurrentSponsorship,
            getCSVSponsorship,
            putSponsorshipById,
            getSponsorshipById,
            handleOpenModalEditSponsorship,
            clearDataSponsorship
        }, dispatch),
    };
};


export const CurrentSponsorshipComponent = (props) => {
    const { currentSponsorship, sponsorshipById, isOpenModalEditSponsorship } = props;

    useEffect(() => {
        props.action.getCurrentSponsorship();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (currentSponsorship.current && currentSponsorship.current.unid) {
            props.action.getSponsorshipById(currentSponsorship.current.unid)
        }else if(currentSponsorship.upcomingSponsorships && currentSponsorship.upcomingSponsorships[0]){
            props.action.getSponsorshipById(currentSponsorship.upcomingSponsorships[0].unid)
        }
    }, [currentSponsorship])


    const getSponsorStatus = (sponsorship) => {
        let result = Status.NO_SPONSOR;
        if (sponsorship) {
            if (sponsorship.current && typeof sponsorship.current === 'object') {
                result = Status.IN_PROGRESS;
            } else if (sponsorship.upcomingSponsorships && sponsorship.upcomingSponsorships[0]) {
                result = Status.IN_SCHEDULED;
            } else if (sponsorship.totalSponsorship > 0) {
                result = Status.ALL_COMPLETE;
            }
        }
        return result;
    };

    const getUnid = () => {
        let result = ''
        if (currentSponsorship.current && currentSponsorship.current.unid) {
            result = currentSponsorship.current.unid
        } else if (currentSponsorship.upcomingSponsorships && currentSponsorship.upcomingSponsorships[0]) {
            result = currentSponsorship.upcomingSponsorships[0].unid
        }
        return result;
    }
    // edit Sponsorship
    const handleOpenSponsorship = () => {
        props.action.handleOpenModalEditSponsorship();
    }
    const handleEditSponsorship = (newSponsorship) => {
        props.action.putSponsorshipById(
            newSponsorship,
            getUnid(),
            getSponsorStatus(currentSponsorship) === Status.IN_PROGRESS ? 1 : 0
        );
    }

    const handleDeleteSponsorship =()=>{
        props.action.clearDataSponsorship()
    }

    var sponsorshipProgress = 0;
    if (currentSponsorship && currentSponsorship.current && currentSponsorship.current.budget > 0) {
        sponsorshipProgress = currentSponsorship.current.currentMoney / currentSponsorship.current.budget * 100;
        sponsorshipProgress = Math.min(100, sponsorshipProgress);
    }

    return (
        <Paper
            className={styles.box}
            loading={(!currentSponsorship || currentSponsorship.loading)}
        >
            {getSponsorStatus(currentSponsorship) === Status.NO_SPONSOR &&
                <div className={styles.noContent}>
                    <Link to="/sponsorship/0">
                        <Button
                            className={styles.btnInvite}
                            style={{ fontSize: '15px' }}
                        >
                            Sponsor Employees
                        </Button>
                    </Link>
                </div>
            }
            {getSponsorStatus(currentSponsorship) === Status.IN_SCHEDULED &&
                currentSponsorship.upcomingSponsorships && currentSponsorship.upcomingSponsorships[0] &&
                <Fragment>
                    <div className={styles.header}>
                        <h4>
                            Sponsorship starting {moment(currentSponsorship.upcomingSponsorships[0].startDate).fromNow()}!
                        </h4>
                        <div className={styles.actions}>
                            <Button onClick={handleOpenSponsorship} className={styles.btn}>EDIT</Button>
                        </div>
                    </div>
                    <div style={{ fontSize: '13px', marginTop: '-15px' }}>
                        <p>Up to <NumberFormat
                            prefix={'$'}
                            value={currentSponsorship.upcomingSponsorships[0].budget}
                            displayType="text"
                            thousandSeparator={true}
                            decimalScale={2}
                        /> {currentSponsorship.upcomingSponsorships[0].budgetType === 'company' ? 'for entire company' : 'per employee'}</p>
                        <p>
                            {moment(currentSponsorship.upcomingSponsorships[0].startDate).format('MMM DD, YYYY')}
                            &nbsp;-&nbsp;
                            {currentSponsorship.upcomingSponsorships && moment(currentSponsorship.upcomingSponsorships[0].endDate).format('MMM DD, YYYY')}
                        </p>
                    </div>
                    <div className={styles.nameWrap}>
                        <div className={styles.name}>
                            Upcoming Sponsorship
                        </div>
                    </div>
                </Fragment>
            }
            {getSponsorStatus(currentSponsorship) === Status.IN_PROGRESS &&
                <Fragment>
                    <div className={styles.header}>
                        <h2>
                            <NumberFormat
                                prefix={'$'}
                                value={currentSponsorship.current && currentSponsorship.current.currentMoney}
                                displayType="text"
                                thousandSeparator={true}
                                decimalScale={2}
                            />
                        </h2>
                        <div className={styles.actions}>
                            <Button onClick={handleOpenSponsorship} className={styles.btn}>EDIT</Button>&nbsp;
                        </div>
                    </div>
                    <div>
                        <div className={styles.slider}>
                            <div className={styles.bar}>
                                <div className={styles.inner}
                                    style={{ width: currentSponsorship.current && (sponsorshipProgress + '%') }}
                                />
                            </div>
                            <div className={styles.title}>
                                <span>Budget</span>
                                <NumberFormat
                                    prefix={'$'}
                                    value={currentSponsorship.current && currentSponsorship.current.budget}
                                    displayType="text"
                                    thousandSeparator={true}
                                    decimalScale={2}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.nameWrap}>
                        <div className={styles.name}>
                            Current Sponsorship
                        </div>
                        <div className={styles.actions}>
                            <div
                                className={`${styles.btn} ${styles.active}`}
                                style={{ fontSize: '14px' }}
                            >
                                {currentSponsorship.current && currentSponsorship.current.daysLeft} days left
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
            {getSponsorStatus(currentSponsorship) === Status.ALL_COMPLETE &&
                <Fragment>
                    <div className={styles.header}>
                        <h2>
                            <NumberFormat
                                prefix={'$'}
                                value={currentSponsorship.totalSponsorship}
                                displayType="text"
                                thousandSeparator={true}
                                decimalScale={2}
                            />
                        </h2>
                    </div>
                    <div className={styles.nameWrap}>
                        <div className={styles.name}>
                            Total Sponsorships
                        </div>
                        <div className={styles.actions}>
                            <Button
                                href="/sponsorship/0"
                                className={styles.btnInvite}
                                style={{ fontSize: '15px' }}>
                                Sponsor Employees
                            </Button>
                        </div>
                    </div>
                </Fragment>
            }
            <ModalEditSponsorship
                isOpenModalEditSponsorship={isOpenModalEditSponsorship}
                handleOpenSponsorship={handleOpenSponsorship}
                currentSponsorship={currentSponsorship}
                handleEditSponsorship={handleEditSponsorship}
                sponsorshipById={sponsorshipById}
                handleDeleteSponsorship={handleDeleteSponsorship}
                status={getSponsorStatus(currentSponsorship)}
            />
        </Paper>
    );
};

CurrentSponsorshipComponent.propTypes = {
    currentSponsorship: PropTypes.object.isRequired,
    action: PropTypes.shape({
        getCurrentSponsorship: PropTypes.func.isRequired,
        clearDataSponsorship: PropTypes.func.isRequired,
    }).isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CurrentSponsorshipComponent);
