import {types} from './index';

const initialState = {
  dataUpdateChallenge: {},
  dataChallenge: {},
};

const challenge = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CHALLENGE_REQUEST:
      return {...state, dataChallenge: {loading: true}};
    case types.GET_CHALLENGE_SUCCESS:
    case types.GET_CHALLENGE_FAILURE:
      return {...state, dataChallenge: action.payload};

    case types.UPDATE_CHALLENGE_REQUEST:
      return {...state, dataUpdateChallenge: {loading: true}};
    case types.UPDATE_CHALLENGE_SUCCESS:
    case types.UPDATE_CHALLENGE_FAILURE:
      return {...state, dataUpdateChallenge: {
        ...action.payload,
        challenge: action.payload.challenge ? action.payload.challenge : {}
      }};

    case types.CLEAR_DATA_UPDATE_CHALLENGE:
      return {...state, dataUpdateChallenge: {}};

    default:
      return state;
  }
};
export default challenge;
