import React, {useEffect, useState, Fragment} from 'react';
import {Link, withRouter, Redirect} from 'react-router-dom';

import Container from '@material-ui/core/Container';

import styles from "./index.module.scss";
import {bindActionCreators} from "redux";
import {nextStep, saveStep} from "src/redux/onboarding/action";
import {getAccountInfo} from "src/redux/account/action";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Button, withStyles} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Confirmation from './Confirmation';
import config from "src/config/config";
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const OnboardingContainer = (props) => {
  const {states, dataAccountInfo, dataOnboarding} = props;

  const [isCopied, setIsCopied] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [percentOfDone, setPercentOfDone] = useState(20);
  const [redirect, setRedirect] = useState(null);

  const BorderLinearProgress = withStyles({
    root: {
      height: 20,
      backgroundColor: '#D5EBE1',
      borderRadius: 4,
      marginTop: "20px"
    },
    bar: {
      background: '#FFFFFF',
      borderRadius: 0,
      backgroundColor: '#4BA97C',
      boxShadow: "0px 1px 3px rgba(91, 109, 98, 0.1)"
    },
  })(LinearProgress);

  const [todoList, setTodoList] = useState([
    {
      step: 'joining',
      status: 'done',
      title: 'Join Charity Miles Employee Engagement'
    },
    {
      step: 'accountSetup',
      status: 'doing',
      title: 'Complete account set up',
      description: 'Add your company details and preferences',
      textBtn: 'Go',
      to: '/account-setup'
    },
    // {
    //   step: 'challenge',
    //   status: 'todo',
    //   title: 'Select a kickoff challenge',
    //   description: 'Challenges are a great way to introduce Charity Miles to your employees.',
    //   textBtn: 'Go',
    //   textBtnSkip: 'Skip',
    //   to: '/schedule'
    // },
    // {
    //   step: 'sponsorship',
    //   status: 'todo',
    //   title: 'Sponsor employees',
    //   description: 'Get your employees excited about using Charity Miles with an introductory sponsorship.',
    //   textBtn: 'Go',
    //   textBtnSkip: 'Skip',
    //   to: '/sponsorship/0'
    // },
    // {
    //   step: 'invitation',
    //   status: 'todo',
    //   title: 'Invite employees',
    //   textBtn: 'Copy Link to Download App'
    // },
  ]);

  useEffect(() => {
    props.action.getAccountInfo();

    // eslint-disable-next-line
  }, []);

  const gotoDashboard = () => {
    props.action.getAccountInfo();
  };

  useEffect(() => {
    if (!states.accountSetup) {
      props.action.nextStep({
        accountSetup: 'doing'
      })
    } else {
      let newTodoList = [...todoList];
      let percent = 0;
      newTodoList = newTodoList.map(todo => {
        if (states[todo.step]) {
          todo.status = states[todo.step];
        }
        if (todo.status === 'done' || todo.status === 'skip') {
          percent += 20;
        }
        return todo;
      });
      setPercentOfDone(percent);
      setTodoList(newTodoList);
    }

    // eslint-disable-next-line
  }, [states]);

  useEffect(() => {
    if (dataAccountInfo.account && dataAccountInfo.account.user.onBoardingStep < 2 && dataAccountInfo.account.company) {
      props.action.saveStep({
        onBoardingStep: 2,
        finishedOnBoarding: false
      });
      props.action.nextStep({
        accountSetup: 'done',
        challenge: 'doing'
      })
    } else if (dataAccountInfo.account && dataAccountInfo.account.user) {
      let statesAccount = {};
      if ((dataAccountInfo.account.user.onBoardingStep === 2 && states.accountSetup === 'done') ||
        dataAccountInfo.account.user.onBoardingStep === 1) {
        return;
      }
      for (let i = 0; i <= dataAccountInfo.account.user.onBoardingStep; i++) {
        if (!todoList[i]) {
          return;
        }
        if (i === dataAccountInfo.account.user.onBoardingStep) {
          if (todoList[i].status === 'todo') {
            statesAccount[todoList[i].step] = 'doing';
          }
        } else {
          statesAccount[todoList[i].step] = 'done';
        }
      }
      props.action.nextStep(statesAccount);
    }

    // eslint-disable-next-line
  }, [dataAccountInfo]);

  const renderClass = (todo) => {
    let classTodo = styles.todo;
    if (todo.status === 'doing') {
      classTodo = `${styles.todo} ${styles.todoActive}`
    }
    if (todo.status === 'done' || todo.status === 'skip') {
      classTodo = `${styles.todo} ${styles.todoCompleted}`
    }
    return classTodo
  };

  const copyToClipboard = () => {
    setIsCopied(true);
    props.action.saveStep({
      onBoardingStep: 4,
      finishedOnBoarding: true
    });
    props.action.nextStep({
      invitation: 'done'
    });
    setTimeout(() => {
      setIsConfirmation(true)
    }, 3000)
  };

  const skipStep = () => {
    let newTodoList = [...todoList];
    let indexDoing = newTodoList.findIndex(todo => todo.status === 'doing');
    let percent = percentOfDone;
    if (indexDoing > -1) {
      newTodoList[indexDoing].status = 'skip';
      newTodoList[indexDoing + 1].status = 'doing';
      percent += 20;
      let newStep = {};
      newStep[newTodoList[indexDoing].step] = 'skip';
      newStep[newTodoList[indexDoing + 1].step] = 'doing';
      props.action.nextStep(newStep);
    }
    setPercentOfDone(percent);
    setTodoList(newTodoList);
  };

  const actionSkipStep = (todo) => {
    console.log('actionSkipStep:', todo);
    if (todo.status !== 'skip') {
      return;
    }
    setRedirect(todo.to);
  };

  if (dataAccountInfo.account && dataAccountInfo.account.user.finishedOnBoarding) {
    return <Redirect to='/' />
  }

  //todo: add this back if we add back payment
  // if (dataAccountInfo.account && dataAccountInfo.account.user.onBoardingStep === 1) {
  //   return <Redirect to='/checkout' />
  // }

  if (redirect) {
    return <Redirect to={redirect}/>
  }

  return (
    <div className={styles.containerFluid}>
      <Container className={styles.container}>
        <div className={styles.inner}>
          {
            isConfirmation ?
              <Confirmation dataOnboarding={dataOnboarding} gotoDashboard={gotoDashboard} /> :
              <Fragment>
                <p className={styles.welcome}>Welcome to Charity Miles Employee Engagement</p>
                <h1 className={styles.title}>Get set up for success.</h1>
                {/* <BorderLinearProgress
                  variant="determinate"
                  color="secondary"
                  value={percentOfDone}
                /> */}
                <div className={styles.checklist}>
                  {
                    todoList.map((todo, index) => {
                      return (<div className={renderClass(todo)} key={index}>
                        {
                          todo.status === 'done' && (
                            <img src={require('src/assets/images/checked.svg')} alt=""/>
                          )
                        }
                        {
                          (todo.status === 'todo' || todo.status === 'doing') && (
                            <img src={require('src/assets/images/non-check.svg')} alt=""/>
                          )
                        }
                        {
                          todo.status === 'skip' && (
                            <img src={require('src/assets/images/skip.svg')} alt=""/>
                          )
                        }
                        {
                          todo.status === 'doing' ? (
                            <div className={styles.activeInfo}>
                              <p className={styles.title}>{todo.title}</p>
                              <p className={styles.description}>{todo.description}</p>
                              {
                                todo.step === 'invitation' && !isCopied &&
                                <CopyToClipboard text={config.appUrl}
                                                 onCopy={copyToClipboard}>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    type="button"
                                    className={styles.btnGo}
                                  >
                                    {todo.textBtn}
                                  </Button>
                                </CopyToClipboard>
                              }
                              {
                                todo.step !== 'invitation' &&
                                <div>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    type="button"
                                    className={styles.btnGo}
                                    component={Link}
                                    to={todo.to}
                                  >
                                    {todo.textBtn}
                                  </Button>
                                  {
                                    todo.textBtnSkip &&
                                    <Button
                                      variant="outlined"
                                      className={styles.btnSkip}
                                      onClick={skipStep}
                                    >
                                      {todo.textBtnSkip}
                                    </Button>
                                  }
                                </div>
                              }
                            </div>
                          ) : (
                            <p onClick={() => {actionSkipStep(todo)}} className={styles.titleSkip}>{todo.title}</p>
                          )
                        }
                      </div>)
                    })
                  }
                  {
                    isCopied &&
                    <div className={styles.copiedClipboard}>
                      <div className={styles.message}>
                        Copied to clipboard!
                      </div>
                    </div>
                  }
                </div>
              </Fragment>
          }
        </div>
      </Container>
    </div>
  );
};

OnboardingContainer.propTypes = {
  states: PropTypes.object.isRequired,
  dataAccountInfo: PropTypes.object.isRequired,
  dataOnboarding: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => {
  return {
    states: state.onboarding.states,
    dataAccountInfo: state.account.dataAccountInfo,
    dataOnboarding: state.onboarding.dataOnboarding,
    action: PropTypes.shape({
      nextStep: PropTypes.func.isRequired,
      getAccountInfo: PropTypes.func.isRequired,
      saveStep: PropTypes.func.isRequired,
    }).isRequired,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({
      nextStep,
      getAccountInfo,
      saveStep
    }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(OnboardingContainer));
