export const ShortNumber = (number, digits = 1) => {
    let toFixed = digits;
    if (!number) {
        return 0;
    }
    if (number < 1000) {
        toFixed++;
    }
    const si = [
        { value: 1, symbol: '' },
        { value: 1E3, symbol: 'k' },
        { value: 1E6, symbol: 'M' },
        { value: 1E9, symbol: 'G' },
        { value: 1E12, symbol: 'T' },
        { value: 1E15, symbol: 'P' },
        { value: 1E18, symbol: 'E' }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (number >= si[i].value) {
            break;
        }
    }
    return (number / si[i].value).toFixed(toFixed).replace(rx, '$1') + si[i].symbol;
};

export const FormatMoney = money => {
  return `$${parseFloat(money).toFixed(2)}`
};
