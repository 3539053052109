import React, { Fragment, useEffect, useState } from "react";

import stylesChallenge from "../index.module.scss";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Popover from "@material-ui/core/Popover";
import moment from "moment";

export const OccurringComponent = (props) => {
  const BorderLinearProgress = withStyles({
    root: {
      height: 7,
      backgroundColor: "#D5EBE1",
    },
    bar: {
      borderRadius: 0,
      backgroundColor: "#4BA97C",
    },
  })(LinearProgress);

  const {
    challenge,
    leaderboard,
    clickExport,
    getCSVChallengesById,
    getCSVDetailsChallenge
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportCsv = (challengeUnid) => {
    getCSVChallengesById(challengeUnid);
    clickExport(true)
  };

  const handleExportCSVDetailChallenge = (challengeUnid) => {
    getCSVDetailsChallenge(challengeUnid);
    clickExport(true)
  };

  return (
    <Fragment>
      <div className={stylesChallenge.header}>
        <div className={stylesChallenge.month}>{challenge.name}</div>
        <div style={{ cursor: "pointer" }}>
          <img
            onClick={handleClick}
            src={require("src/assets/images/ExportIcon.png")}
            alt=""
          />

          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <ul>
              <li
                className="p-1"
                onClick={() => {
                  handleExportCsv(challenge.unid);
                  handleClose();
                }}
              >
                Overview
              </li>
              <li
                onClick={() => {
                  handleExportCSVDetailChallenge(challenge.unid);
                  handleClose();
                }}
              >
                Detailed
              </li>
            </ul>
          </Popover>
        </div>
      </div>
      <div className={stylesChallenge.info}>
        <div className={stylesChallenge.distance}>
          <span>{challenge.distance} miles</span> in {challenge.days} days
        </div>
        <div className={stylesChallenge.daysRemaining}>
          <p>{challenge.daysRemaining} days remaining</p>
          <p className={stylesChallenge.endDate}>Ending { (() => {
            const mm = moment(challenge.endDate);
            return 'on ' + mm.format('MMM DD YYYY') + " at " + mm.format('hh:mm a')
          })()}</p>
        </div>
      </div>
      <div className={stylesChallenge.tableBoard}>
        <Table>
          <TableBody>
            {leaderboard.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center" className={stylesChallenge.no}>
                  {index + 1}
                </TableCell>
                <TableCell
                  align="center"
                  className={stylesChallenge.userAvatar}
                >
                  <img src={row.profilePhoto} alt="" />
                </TableCell>
                <TableCell className={stylesChallenge.name}>
                  {row.name}
                </TableCell>
                <TableCell align="center" className={stylesChallenge.percent}>
                  <BorderLinearProgress
                    className={stylesChallenge.progressBar}
                    variant="determinate"
                    color="secondary"
                    value={
                      parseInt(
                        (parseInt(row.totalMiles) /
                          parseInt(challenge.distance)) *
                          100
                      ) > 100
                        ? 100
                        : parseInt(
                            (parseInt(row.totalMiles) /
                              parseInt(challenge.distance)) *
                              100
                          )
                    }
                  />
                </TableCell>
                <TableCell align="center" className={stylesChallenge.run}>
                  {Math.round(parseFloat(row.totalMiles))} mi
                </TableCell>
                <TableCell align="center" className={stylesChallenge.isPass}>
                  {parseInt(row.totalMiles) >= parseInt(challenge.distance) && (
                    <img
                      src={require("src/assets/images/completed.svg")}
                      alt=""
                    />
                  )}
                </TableCell>
                <TableCell align="center" className={stylesChallenge.cash}>
                  ${parseFloat(row.totalMoney).toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Fragment>
  );
};

OccurringComponent.propTypes = {
  challenge: PropTypes.object.isRequired,
  getCSVChallengesById: PropTypes.func,
  getCSVDetailsChallenge: PropTypes.func,
};

export default OccurringComponent;
