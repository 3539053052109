import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField'
import styles from './index.module.scss';
import './index.module.scss';
import { useFormik } from 'formik';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { resetPassword } from 'src/redux/forgotpassword/action';

const mapStateToProps = (state) => {
    return {
        resetPassword: state.forgotpassword.resetPassword,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators({
            resetPassword,
        }, dispatch),
    };
};

export const ForgotPasswordContainer = (props) => {
    const { resetPassword } = props;
    const [isSuccess, setSuccess] = useState(false)
    const [password, setPassword] = useState('')
    const [confirm, setConfirm] = useState('')
    const [isPasswordNull, setPasswordNull] = useState(false)
    const [isConfirmNull, setConfirmNull] = useState(false)
    const [isDontCorrect, setDontCorrect] = useState(false)
    const [isSubmit, setSubmit] = useState(false)
    useEffect(() => {
        if (resetPassword.isSuccess) {
            setSuccess(true)
            console.log('navigate to Login / Home...');
        }
    }, [resetPassword])

    const handleSubmit = () => {
        setSubmit(true)
        if (password.trim().length === 0) {
            setPasswordNull(true)
            setDontCorrect(false)
        }
        if (confirm.trim().length === 0) {
            setConfirmNull(true)
            setDontCorrect(false)
        }
        if (password.trim().length !== 0 && confirm.trim().length !== 0 && password !== confirm) {
            setDontCorrect(true)
            setConfirmNull(false)
            setPasswordNull(false)
        }
        if (password === confirm && password.trim() && confirm.trim()) {
            setConfirmNull(false)
            setPasswordNull(false)
            setDontCorrect(false)
            props.action.resetPassword({ password: password, token: props.location.search.split('=')[1] });
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <div className={styles.logo}>
                        <img src={require('src/assets/images/logo.svg')} alt="CM" />
                    </div>
                    <h1>Charity Miles Reset Password</h1>
                    {
                        !isSuccess && <div className={styles.subTitle}>Please provide new password.</div>
                    }
                </div>

                <div className={styles.form}>
                    {
                        !isSuccess && (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className={styles.inputWrapUsername}>
                                    <TextField
                                        style={{ width: '50%' }}
                                        label="Password"
                                        name="password"
                                        placeholder="********"
                                        type='password'
                                        onChange={(e) => {
                                            setSubmit(false)
                                            setPassword(e.target.value)
                                        }}
                                    />

                                </div>
                                {
                                    isPasswordNull && isSubmit && (
                                        <span className={styles.validateMessage}>Password is valid.</span>
                                    )
                                }
                                <div className={styles.inputWrapUsername}>
                                    <TextField
                                        style={{ width: '50%' }}
                                        label="Confirm password"
                                        name="confirm"
                                        placeholder="********"
                                        type='password'
                                        onChange={(e) => {
                                            setSubmit(false)
                                            setConfirm(e.target.value)
                                        }}
                                    />
                                </div>
                                {
                                    isConfirmNull && isSubmit && (
                                        <span className={styles.validateMessage}>Confirm password is valid.</span>
                                    )
                                }
                                {
                                    isDontCorrect && isSubmit && (
                                        <span className={styles.validateMessage}>Password and Confirm password isn't match.</span>
                                    )
                                }
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    className={styles.btnNext}
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Next
                                </Button>
                            </div>
                        )
                    }
                    {isSuccess && (
                        <p className={styles.txtResetSuccess}>Reset password is successfully!  <span onClick={() => {
                            props.history.push('/signin')
                        }} className={styles.txtLoginNow}>Login now</span>.</p>)}
                </div>
            </div>
        </div >
    );
};

ForgotPasswordContainer.propTypes = {
    signIn: PropTypes.object.isRequired,
    action: PropTypes.shape({
        signIn: PropTypes.func.isRequired,
    }).isRequired,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(ForgotPasswordContainer));