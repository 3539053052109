import React from 'react';

import stylesChallenge from '../index.module.scss';
import {Link} from "react-router-dom";
import { Button } from '@material-ui/core';

export const ScheduleComponent = () => {
  return (
    <div className={stylesChallenge.schedule}>
      <div className={stylesChallenge.empty}>
        {/* <p className={stylesChallenge.description}>
          Take your team on the most<br/>
          exciting routes in the world.
        </p> */}
        <p className={stylesChallenge.createSchedule}>
          <Button
            component={Link}
            to="/schedule"
          >
            Schedule a Challenge
          </Button>
        </p>
      </div>
    </div>
  );
};

export default ScheduleComponent;
