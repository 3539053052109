import { types } from "./index";

export const getCSVActivitiesLeaderboard = (payload) => ({
  type: types.GET_CSV_ACTIVITEIE_LEADERBOARD_REQUEST,
  payload,
});

export const getCSVDetailsChallenge = (challengeUnid) => ({
  type: types.GET_CSV_DETAIL_CHALLENGE_REQUEST,
  challengeUnid,
});

export const clearCSV = () => ({
  type: types.CLEAR_CSV,
});

export const getCSVPastChallenge = (challengeUnid) => ({
  type: types.GET_CSV_PAST_CHALLENGE_REQUEST,
  challengeUnid,
});

export const handleOpenModalEditSponsorship = () => ({
  type: types.OPEN_MODAL_EDIT_SPONSORSHIP,
});

export const getRegisteredUsers = () => ({
  type: types.REGISTERED_USERS_REQUEST,
});

export const getTotalImpact = (payload) => ({
  type: types.TOTAL_IMPACT_REQUEST,
  payload,
});

export const getCurrentSponsorship = () => ({
  type: types.CURRENT_SPONSORSHIP_REQUEST,
});

export const putSponsorshipById = (sponsorship, unid, status) => ({
  type: types.EDIT_SPONSORSHIP_BY_ID_REQUEST,
  sponsorship,
  unid,
  status,
});
export const getSponsorshipById = (unid) => ({
  type: types.GET_SPONSORSHIP_BY_ID_REQUEST,
  unid,
});

export const getMilesCompleted = (payload) => ({
  type: types.MILES_COMPLETED_REQUEST,
  payload,
});

export const getChallenges = (payload) => ({
  type: types.CHALLENGES_REQUEST,
  payload,
});

export const getLeaderboard = (payload) => ({
  type: types.LEADERBOARD_REQUEST,
  payload,
});

export const getCSVLeaderboard = (payload) => ({
  type: types.CSV_LEADERBOARD_REQUEST,
  payload,
});

export const getCSVChallenges = (payload) => ({
  type: types.CSV_CHALLENGES_REQUEST,
  payload,
});

export const getCSVChallengesById = (challengesId) => ({
  type: types.CSV_CHALLENGES_BY_ID_REQUEST,
  challengesId,
});

export const getCSVImpact = (payload) => ({
  type: types.CSV_IMPACT_REQUEST,
  payload,
});

export const getCSVSponsorship = (payload) => ({
  type: types.CSV_SPONSORSHIP_REQUEST,
  payload,
});

export const getCSVRegisteredUsers = (payload) => ({
  type: types.CSV_REGISTERED_USERS_REQUEST,
  payload,
});

export const getCSVMilesCompleted = (payload) => ({
  type: types.CSV_MILES_COMPLETED_REQUEST,
  payload,
});

export const setMessageDashboard = (payload) => ({
  type: types.SET_MESSAGE_DASHBOARD,
  payload,
});
