import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { activate } from 'src/redux/auth/action';
import AuthStorage from 'src/utils/authStorage';

import Loading from 'src/components/Loading';
import Button from '@material-ui/core/Button';

import styles from './index.module.scss';
import { routerActions } from 'connected-react-router';

const mapStateToProps = (state) => {
	return {
        activate: state.auth.activate,
        location: routerActions.location,
    };
};

const mapDispatchToProps = (dispatch) => {
	return {
		action: bindActionCreators({
            activate,
        }, dispatch),
	};
};

export const ActivationContainer = (props) => {
    const { history, location, activate } = props;
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        if (AuthStorage.getAccessToken()) {
            setLoggedIn(true);
        } else {
            const urlSearch = new URLSearchParams(location.search);
            props.action.activate({
                token: urlSearch.get('token'),
            });
        }
    }, [location.search, props.action]);

    if (loggedIn) {
        return <Redirect to="/" />
    }

    if (activate.hasOwnProperty('error')) {
        setTimeout(() => {
            history.push('/');
        }, 2000);
    }
    if (activate.hasOwnProperty('success') && activate.success === 0) {
        AuthStorage.setActive(true);
        return (
            <div className={styles.root}>
                <div className={styles.container}>
                    <div className={styles.title}>
                        <h1>You’re in! and we’re so excited to have you.</h1>
                    </div>
                    <div className={styles.form}>
                        <div>
                            <Button
                                fullWidth
                                variant="outlined"
                                className={styles.btnLogin}
                                component={Link}
                                to="/signin"
                            >
                                Login
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <Loading/>
            </div>
        </div>
    );
};

ActivationContainer.propTypes = {
    history: PropTypes.object.isRequired,
    activate: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    action: PropTypes.shape({
        activate: PropTypes.func.isRequired,
    }).isRequired,
};

export default connect(
    mapStateToProps,
	mapDispatchToProps,
)(withRouter(ActivationContainer));
