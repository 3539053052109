import React, {useState, Fragment, useEffect} from 'react';
import NumberFormat from 'react-number-format';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getTotalImpact, getCSVImpact} from 'src/redux/dashboard/action';

import Paper from 'src/components/Paper';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import styles from '../box.module.scss';

const filters = [
    {
        text: 'WTD',
        value: 'week',
    },
    {
        text: 'MTD',
        value: 'month',
    },
    {
        text: 'YTD',
        value: 'year',
    },
    {
        text: 'ALL',
        value: 'all',
    },
];

const mapStateToProps = (state) => {
    return {
        totalImpact: state.dashboard.totalImpact,
        dataCSVImpact: state.dashboard.dataCSV,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators({
            getTotalImpact,
            getCSVImpact,
        }, dispatch),
    };
};

export const TotalImpactComponent = (props) => {
    const {totalImpact} = props;
    const [current, setCurrent] = useState(filters[3]);


    useEffect(() => {
        const query = {by: current.value};
        props.action.getTotalImpact(query);

        // eslint-disable-next-line
    }, [current]);

    return (
        <Paper
            className={styles.box}
            loading={(!totalImpact || totalImpact.loading)}
        >
            <div className={styles.header}>
                <h2>
                    <NumberFormat
                        prefix={'$'}
                        value={totalImpact && totalImpact.impact}
                        displayType="text"
                        thousandSeparator={true}
                        decimalScale={2}
                    />
                </h2>
            </div>
            <div className={styles.nameWrap}>
                <div className={styles.name}>
                    Total Impact &nbsp;
                    <Tooltip
                        title={(
                            <Fragment>
                                <strong>The total impact includes:</strong>
                                <br/>&bull; sponsorships to date
                                <br/>&bull; peer to peer fundraising
                                <br/>&bull; bonuses claimed
                            </Fragment>
                        )}
                    >
                        <img src={require('src/assets/images/tip.svg')} alt=""/>
                    </Tooltip>
                </div>
                <div className={styles.actions}>
                    {filters.map((filter) => {
                        return (
                            <Button key={filter.text}
                                    className={styles.btn + (filter.text === current.text ? ` ${styles.active}` : '')}
                                    onClick={() => setCurrent(filter)}
                            >{filter.text}</Button>
                        );
                    })}
                </div>
            </div>
        </Paper>
    );
};

TotalImpactComponent.propTypes = {
    totalImpact: PropTypes.object.isRequired,
    dataCSVImpact: PropTypes.object.isRequired,
    action: PropTypes.shape({
        getTotalImpact: PropTypes.func.isRequired,
        getCSVImpact: PropTypes.func.isRequired,
    }).isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TotalImpactComponent);
