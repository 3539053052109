import React from "react";
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";
import styles from "./index.module.scss";
import { makeStyles } from "@material-ui/core";
export const SearchBoxComponent = (props) => {
  const useStylesInput = makeStyles({
    root: {
      marginBottom: 2,
      paddingLeft: 50,
      height: 50,
      "&:before": {
        borderBottom: "unset",
      },
      "&:hover": {
        borderBottom: "unset !important",
      },
    },
  });
  const classes = useStylesInput();
  return (
    <div className={styles.inputContainer}>
      <Input className={classes.root} {...props} placeholder="Search" />
      <div className={styles.image}>
        <img
          onClick={props.onSearchClick}
          className={styles.searchIcon}
          src={require("src/assets/images/icon-search.svg")}
          alt=""
        />
      </div>
    </div>
  );
};

SearchBoxComponent.propTypes = {};

export default SearchBoxComponent;
