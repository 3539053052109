import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import styles from "../index.module.scss";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";

export const DetailComponent = (props) => {
  const { challengeInfo, setClickExport} = props;
  const { challenge, leaderboard, sponsorship } = challengeInfo;
  const BorderLinearProgress = withStyles({
    root: {
      height: 7,
      backgroundColor: "#D5EBE1",
    },
    bar: {
      borderRadius: 0,
      backgroundColor: "#4BA97C",
    },
  })(LinearProgress);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const back = () => {
    props.back();
  };

  const getCSVPastChallenge = (challengeUnid) => {
    props.getCSVPastChallenge(challengeUnid);
  };

  const getCSVDetailsChallenge = (challengeUnid) => {
    props.getCSVDetailsChallenge(challengeUnid);
  };

  return (
    <div className={styles.challengeDetail}>
      <Button
        color="secondary"
        className={styles.btnBack}
        startIcon={
          <img src={require("src/assets/images/arrow-left-pink.svg")} alt="" />
        }
        onClick={back}
      >
        Back
      </Button>
      <h1 className={styles.title}>{challenge.name}</h1>
      <div className={styles.currentChallenge}>
        <div className={styles.header}>
          <div className={styles.name}>{challenge.name}</div>
          <div style={{ cursor: "pointer" }}>
            <img
              onClick={handleClick}
              src={require("src/assets/images/ExportIcon.png")}
              alt=""
              width="40px"
              height="40px"
            />
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <ul>
                <li
                  className="p-1"
                  onClick={() => {
                    getCSVPastChallenge(challenge.unid);
                    setClickExport(true);
                    handleClose();
                  }}
                >
                  Overview
                </li>
                <li
                  onClick={() => {
                    getCSVDetailsChallenge(challenge.unid);
                    setClickExport(true);
                    handleClose();
                  }}
                >
                  Detailed
                </li>
              </ul>
            </Popover>
          </div>
        </div>
        <div className={styles.moreInfo}>
          <p>
            <span>{challenge.distance} miles</span> in {challenge.days} days
          </p>
          <p>
            {moment(challenge.startDate).format("MMM DD YYYY")} -{" "}
            {moment(challenge.endDate).format("MMM DD YYYY")}
          </p>
          {sponsorship && sponsorship.budget ? (
            <p>up to ${sponsorship.budget} per employee sponsorship</p>
          ) : null}
          <p>
            {sponsorship && sponsorship.emplCharityMPMBike ? (
              <span>{sponsorship.emplCharityMPMBike} bike, </span>
            ) : null}
            {sponsorship && sponsorship.emplCharityMPMRun ? (
              <span>{sponsorship.emplCharityMPMRun} run/walk</span>
            ) : null}
          </p>
        </div>
        <div className={styles.detailInfo}>
          <div className={styles.tableBoard}>
            {leaderboard && (
              <Table>
                <TableBody>
                  {leaderboard.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center" className={styles.no}>
                        {index + 1}
                      </TableCell>
                      <TableCell align="center" className={styles.userAvatar}>
                        <img src={row.profilePhoto} alt="" />
                      </TableCell>
                      <TableCell className={styles.name}>{row.name}</TableCell>
                      <TableCell align="center" className={styles.percent}>
                        <BorderLinearProgress
                          className={styles.progressBar}
                          variant="determinate"
                          color="secondary"
                          value={
                            parseInt(
                              (parseInt(row.totalMiles) /
                                parseInt(challenge.distance)) *
                                100
                            ) > 100
                              ? 100
                              : parseInt(
                                  (parseInt(row.totalMiles) /
                                    parseInt(challenge.distance)) *
                                    100
                                )
                          }
                        />
                      </TableCell>
                      <TableCell align="center" className={styles.run}>
                        {Math.round(parseFloat(row.totalMiles))} mi
                      </TableCell>
                      <TableCell align="center" className={styles.isPass}>
                        {parseInt(row.totalMiles) >=
                          parseInt(challenge.distance) && (
                          <img
                            src={require("src/assets/images/completed.svg")}
                            alt=""
                          />
                        )}
                      </TableCell>
                      <TableCell align="center" className={styles.cash}>
                        ${parseFloat(row.totalMoney).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

DetailComponent.propTypes = {
  challengeInfo:PropTypes.object.isRequired,
  back:PropTypes.func.isRequired,
  getCSVPastChallenge:PropTypes.func.isRequired,
  getCSVDetailsChallenge:PropTypes.func.isRequired,
  setClickExport: PropTypes.func.isRequired,
};

export default DetailComponent;
