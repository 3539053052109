export const types = {
    ACCOUNT_SETUP_REQUEST: 'ACCOUNT_SETUP_REQUEST',
    ACCOUNT_SETUP_SUCCESS: 'ACCOUNT_SETUP_SUCCESS',
    ACCOUNT_SETUP_FAILURE: 'ACCOUNT_SETUP_FAILURE',

    ACCOUNT_INFO_REQUEST: 'ACCOUNT_INFO_REQUEST',
    ACCOUNT_INFO_SUCCESS: 'ACCOUNT_INFO_SUCCESS',
    ACCOUNT_INFO_FAILURE: 'ACCOUNT_INFO_FAILURE',

    UPDATE_ACCOUNT_REQUEST: 'UPDATE_ACCOUNT_REQUEST',
    UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',
    UPDATE_ACCOUNT_FAILURE: 'UPDATE_ACCOUNT_FAILURE',
    CLEAR_UPDATE_ACCOUNT: 'CLEAR_UPDATE_ACCOUNT'
};

export const urls = {
    ACCOUNT_SETUP: 'companyAdmin/companies',
    ACCOUNT_INFO: 'companyAdmin',
    UPDATE_ACCOUNT: 'companyAdmin',
};
