import React from 'react';

function Next() {
  return (
    <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9 8.41391L9.29289 8.12102C9.68342 7.73049 9.68342 7.09733 9.29289 6.70681L9 6.41391L3 0.413911C2.44772 -0.138373 1.55228 -0.138373 1 0.413911C0.447725 0.966195 0.447725 1.86163 1 2.41391L6 7.41391L1 12.4139C0.447724 12.9662 0.447723 13.8616 1 14.4139C1.55228 14.9662 2.44772 14.9662 3 14.4139L8 9.41391L9 8.41391Z" fill="#C4C4C4"/>
    </svg>
  );
}

export default Next;
