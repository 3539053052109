import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import PropTypes from "prop-types";
import styles from "../index.module.scss";
import moment from "moment";
import Popover from '@material-ui/core/Popover';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {clearDataUpdateChallenge, updateChallenge} from "src/redux/challenge/action";
import UpcomingChallenge from 'src/components/Modal/UpcomingChallengeModal';
import notify from 'src/utils/notify';

export const UpcomingComponent = (props) => {
  const {challenges, dataUpdateChallenge} = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [challengeActive, setChallengeActive] = useState(null);
  const [isDisabledInput, setIsDisabledInput] = useState(null);
  const [openModalChallenge, setOpenModalChallenge] = useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [description, setDescription] = React.useState("");
  const [errorStartDate, setErrorStartDate] = useState(false);
  const [errorEndDate, setErrorEndDate] = useState(false);
  const [errorValidateStartDate, setErrorValidateStartDate] = useState(false);
  const [errorValidateEndDate, setErrorValidateEndDate] = useState(false);
  const [minDate, setMinDate] = useState(null);
  const [name, setName] = useState(null);
  const [distance, setDistance] = useState(null);

  useEffect(() => {
    if (isDisabledInput) {
      setMinDate(null);
    } else {
      setMinDate(moment());
    }
  }, [isDisabledInput]);


  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (dataUpdateChallenge.challenge) {
      cancelUpdateChallenge();
      props.action.clearDataUpdateChallenge();
      props.getChallenges();
      // props.getChallenges();
    }
    // eslint-disable-next-line
  }, [dataUpdateChallenge]);

  const updateDataChallenge = () => {
    if (!validateInput()) {
      return;
    }

    let errorCount = 0;
    if(name === ''){
      notify.error('Name is invalid.')
      errorCount++;
    }

    if(distance <= 0){
      notify.error('Distance is invalid.')
      errorCount++;
    }
    if(errorCount > 0){
      return;
    }

    const data = {
      ...challengeActive,
      "startDate": startDate ? moment(startDate).format("YYYY-MM-DD").toString()+'T00:00:00Z' : null,
      "endDate": endDate ? moment(endDate).format("YYYY-MM-DD").toString()+'T00:00:00Z' : null,
      "description": description,
      name:name,
      distance: Number(distance)
    };
    
    props.action.updateChallenge(data);
  };

  const onErrorStartDate = (err, value) => {
    if (err || !value) {
      setErrorStartDate(true);
    } else {
      setErrorStartDate(false);
      setErrorValidateStartDate(false);
    }
  };

  const onErrorEndDate = (err, value) => {
    if (err || !value) {
      setErrorEndDate(true);
    } else {
      setErrorEndDate(false);
      setErrorValidateEndDate(false);
    }
  };

  const validateInput = () => {
    let isValid = true;
    if (!startDate || errorStartDate) {
      isValid = false;
      setErrorValidateStartDate(true);
    }
    if (!endDate || errorEndDate) {
      isValid = false;
      setErrorValidateEndDate(true);
    }
    return isValid;
  };

  const cancelUpdateChallenge = () => {
    setChallengeActive(null);
    setOpenModalChallenge(false);
    setDescription(null);
    setStartDate(null);
    setEndDate(null);
    setName(null);
    setDistance(null);
  };

  const actionChallenge = (challenge, action) => {
    setAnchorEl(null);
    setChallengeActive(challenge);
    setOpenModalChallenge(true);
    setDescription(challenge.description);
    setName(challenge.name);
    setDistance(challenge.distance);
    setStartDate(moment(challenge.startDate));
    setEndDate(moment(challenge.endDate));
    if (action === 'view') {
      setIsDisabledInput(true);
    } else {
      setIsDisabledInput(false);
    }
  };

  const open = Boolean(anchorEl);

  return (
    <div className={styles.tableChallenges}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={styles.name}>Challenge Name</TableCell>
            <TableCell className={styles.dates}>Dates</TableCell>
            <TableCell className={styles.days}>Days</TableCell>
            <TableCell className={styles.sponsorship}>Sponsorship</TableCell>
            <TableCell className={styles.budget}>Budget</TableCell>
            <TableCell className={styles.description}>Description</TableCell>
            <TableCell className={styles.actions}></TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Table>
        <TableBody>
          {challenges.map((row, index) => (
            <TableRow key={index}>
              <TableCell className={styles.name}>{row.challenge.name}</TableCell>
              <TableCell  className={styles.dates}>
                {moment(row.challenge.startDate).format('MM/DD/YYYY')}-<br/>
                {moment(row.challenge.endDate).format('MM/DD/YYYY')}
              </TableCell>
              <TableCell className={styles.days}>{row.challenge.days}</TableCell>
              <TableCell className={styles.sponsorship}>{row.sponsorship ? 'Yes' : 'No'}</TableCell>
              <TableCell className={styles.budget}>{row.sponsorship && row.sponsorship.budget ? `${row.sponsorship.budget}/per employee` : '-'}</TableCell>
              <TableCell className={styles.description}>{row.challenge.description}</TableCell>
              <TableCell className={styles.actions}>
                <div className={styles.icon} onClick={handleClick}>
                  <img src={require('src/assets/images/more.svg')} alt="more" />
                </div>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <ul>
                    <li onClick={() => {actionChallenge(row.challenge, 'edit')}}>Edit</li>
                    <li onClick={() => {actionChallenge(row.challenge, 'view')}}>View</li>
                  </ul>
                </Popover>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <UpcomingChallenge 
        openModalChallenge={openModalChallenge}
        challengeActive={challengeActive}
        startDate={startDate}
        endDate={endDate}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        isDisabledInput={isDisabledInput}
        minDate={minDate}
        onErrorStartDate={onErrorStartDate}
        onErrorEndDate={onErrorEndDate}
        errorValidateStartDate={errorValidateStartDate}
        errorValidateEndDate={errorValidateEndDate}
        handleChangeDescription={handleChangeDescription}
        cancelUpdateChallenge={cancelUpdateChallenge}
        dataUpdateChallenge={dataUpdateChallenge}
        updateDataChallenge={updateDataChallenge}
        description={description}
        name={name}
        setName={setName}
        distance={distance}
        setDistance={setDistance}
      />
    </div>
  );
};


UpcomingComponent.propTypes = {
  challenges: PropTypes.array.isRequired,
  dataUpdateChallenge: PropTypes.object.isRequired,
  action: PropTypes.shape({
    setRefUrl: PropTypes.func.isRequired,
    updateChallenge: PropTypes.func.isRequired,
    clearDataUpdateChallenge: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    dataUpdateChallenge: state.challenge.dataUpdateChallenge,
    clearDataUpdateChallenge: state.challenge.clearDataUpdateChallenge,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({
      clearDataUpdateChallenge,
      updateChallenge
    }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpcomingComponent);
