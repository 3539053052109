import React, { Fragment, useEffect, useState } from "react";
import NumberFormat from "react-number-format";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getRegisteredUsers,
  getCSVRegisteredUsers,
  clearCSV
} from "src/redux/dashboard/action";

import Paper from "src/components/Paper";
import Button from "@material-ui/core/Button";
import styles from "../box.module.scss";
import config from "src/config/config";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ExportCsvModal from '../../../components/Modal/ExportCsvModal';

const mapStateToProps = (state) => {
  return {
    registeredUsers: state.dashboard.registeredUsers,
    dataCSV: state.dashboard.dataCSV,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      {
        getRegisteredUsers,
        getCSVRegisteredUsers,
        clearCSV
      },
      dispatch
    ),
  };
};

export const RegisteredUsersComponent = (props) => {
  const {
    registeredUsers,
    dataCSV,
    handleRedirectToUser,
  } = props;

  const hasEmployee = registeredUsers && registeredUsers.registered > 0;
  const [isCopied, setIsCopied] = useState(false);
  const [openExportCsvModal, setOpenExportCsvModal]= useState(false);
  const [isClickExport, setClickExport]= useState(false);

  useEffect(()=>{
    if(dataCSV.isSuccess){
      setOpenExportCsvModal(true)
    }
  },[dataCSV])

  const handleCloseExportCsvModal =()=>{
    // clear data CSV
    props.action.clearCSV();
    setClickExport(false);
    setOpenExportCsvModal(false)
  }

  useEffect(() => {
    props.action.getRegisteredUsers();
  }, []);

  return (
    <Paper
      className={styles.box}
      loading={!registeredUsers || registeredUsers.loading}
    >
      {!hasEmployee && (
        <div className={styles.noContent}>
          {/* <div>
            <CSVLink
              style={{ marginLeft: 'auto', textDecoration: 'none', marginTop: 'auto' }}
              data={dataCSV.data} headers={dataCSV.headers}
              filename={'RegisterUser.csv'}
            >
              <img src={require('src/assets/images/ExportIcon.png')} alt="" />
            </CSVLink>
          </div> */}
          <CopyToClipboard
            text={config.appUrl}
            onCopy={() => setIsCopied(true)}
          >
            <Button className={styles.btnInvite} style={{ fontSize: "15px" }}>
              Invite Employees
            </Button>
          </CopyToClipboard>
        </div>
      )}
      {hasEmployee && (
        <Fragment>
          <div style={{
            position: 'absolute',
            minWidth: "330px",
            minHeight: "138px",
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            cursor: "pointer",
          }} onClick={handleRedirectToUser}>
          </div>
          <div className={styles.header}>
            <h2>
              <NumberFormat
                value={registeredUsers.registered}
                displayType="text"
                thousandSeparator={true}
              />
            </h2>
            <div style={{ cursor: "pointer", zIndex: 1 }}>
              <img
                onClick={() => {
                  props.action.getCSVRegisteredUsers();
                  setClickExport(true);
                }}
                src={require("src/assets/images/ExportIcon.png")}
                alt=""
              />
            </div>
          </div>
          <div className={styles.nameWrap}>
            <div className={styles.name}>Registered Users</div>
            <div className={styles.actions}>
              <CopyToClipboard
                text={config.appUrl}
                onCopy={() => setIsCopied(true)}
              >
                <Button className={styles.btnInvite}>Invite Employees</Button>
              </CopyToClipboard>
            </div>
          </div>
        </Fragment>
      )}
      {isCopied && (
        <div className={styles.copiedClipboard}>
          <div className={styles.message}>Copied to clipboard!</div>
        </div>
      )}
      {
        openExportCsvModal && isClickExport && (
<ExportCsvModal 
        handleCloseExportCsvModal={handleCloseExportCsvModal}
      />
        )
      }
      
    </Paper>
  );
};

RegisteredUsersComponent.propTypes = {
  registeredUsers: PropTypes.object.isRequired,
  dataCSV: PropTypes.object.isRequired,
  action: PropTypes.shape({
    getRegisteredUsers: PropTypes.func.isRequired,
    clearCSV: PropTypes.func.isRequired
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisteredUsersComponent);
