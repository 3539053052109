import React, {useEffect, useState, forwardRef, useImperativeHandle} from 'react';
import styles from '../index.module.scss';
import {Button} from '@material-ui/core';
import PropTypes from "prop-types";
import AuthStorage from 'src/utils/authStorage';

export const PaymentMethodComponent = forwardRef((props, ref) => {
    const {currentStep, maxStep, stripe, billingAddress } = props;

    const [elementsStripe, setElementsStripe] = useState([]);

    useImperativeHandle(ref, () => ({
        getTokenStripe() {
            const checkoutForm = document.querySelector('.checkout-stripe');
            const form = checkoutForm.querySelector('form');
            let plainInputsValid = true;
            Array.prototype.forEach.call(form.querySelectorAll('input'), function (
                input
            ) {
                if (input.checkValidity && !input.checkValidity()) {
                    plainInputsValid = false;
                    return;
                }
            });
            if (!plainInputsValid) {
                triggerBrowserValidation(form);
                return;
            }

            checkoutForm.classList.add('submitting');
            disableInputs(form);
            let additionalData = {
                name: AuthStorage.getName(),
                address_line1: billingAddress.address ? billingAddress.address : undefined,
                address_city: billingAddress.city ? billingAddress.city : undefined,
                address_state: billingAddress.state ? billingAddress.state : undefined,
                address_zip: billingAddress.zipCode ? billingAddress.zipCode : undefined,
            };

            stripe.createToken(elementsStripe[0], additionalData).then(function (result) {
                checkoutForm.classList.remove('submitting');
                if (result.token) {
                    props.updatePaymentInfo(result.token)
                } else {
                    enableInputs(form);
                }
            });
        }

    }));

    useEffect(() => {
        if (!stripe) {
            return;
        }
        let elements = stripe.elements();
        let inputs = document.querySelectorAll('.checkout-stripe .input');
        Array.prototype.forEach.call(inputs, function (input) {
            input.addEventListener('focus', function () {
                input.classList.add('focused');
            });
            input.addEventListener('blur', function () {
                input.classList.remove('focused');
            });
            input.addEventListener('keyup', function () {
                if (input.value.length === 0) {
                    input.classList.add('empty');
                } else {
                    input.classList.remove('empty');
                }
            });
        });

        const elementStyles = {
            base: {
                color: '#32325D',
                fontWeight: 300,
                fontFamily: '"Charity Miles", Roboto, Helvetica, Arial, sans-serif',
                fontSize: '16px',

                '::placeholder': {
                    color: '#5C8571',
                },
                ':-webkit-autofill': {
                    color: '#e39f48',
                },
            },
            invalid: {
                color: '#f44336',

                '::placeholder': {
                    color: '#5C8571',
                },
            },
        };

        let elementClasses = {
            focus: 'focused',
            empty: 'empty',
            invalid: 'invalid',
        };

        let cardNumber = elements.create('cardNumber', {
            style: elementStyles,
            classes: elementClasses,
        });
        cardNumber.mount('#checkout-card-number');

        let cardExpiry = elements.create('cardExpiry', {
            style: elementStyles,
            classes: elementClasses,
        });
        cardExpiry.mount('#checkout-card-expiry');

        let cardCvc = elements.create('cardCvc', {
            style: elementStyles,
            classes: elementClasses,
        });
        cardCvc.mount('#checkout-card-cvc');
        setElementsStripe([cardNumber, cardExpiry, cardCvc]);
        registerElements([cardNumber, cardExpiry, cardCvc], 'checkout-stripe');

        // eslint-disable-next-line
    }, [maxStep]);

    const triggerBrowserValidation = (form) => {
        let submit = document.createElement('input');
        submit.type = 'submit';
        submit.style.display = 'none';
        form.appendChild(submit);
        submit.click();
        submit.remove();
    };

    const enableInputs = (form) => {
        Array.prototype.forEach.call(
            form.querySelectorAll(
                "input[type='text'], input[type='email'], input[type='tel']"
            ),
            function(input) {
                input.removeAttribute('disabled');
            }
        );
    };

    const disableInputs = (form) => {
        Array.prototype.forEach.call(
            form.querySelectorAll(
                "input[type='text'], input[type='email'], input[type='tel']"
            ),
            function(input) {
                input.setAttribute('disabled', 'true');
            }
        );
    };

    const registerElements = (elements, checkoutName) => {
        let formClass = '.' + checkoutName;
        let checkoutForm= document.querySelector(formClass);

        let form = checkoutForm.querySelector('form');

        let savedErrors = {};
        elements.forEach(function (element, idx) {
            element.on('change', function (event) {
                if (event.error) {
                    savedErrors[idx] = event.error.message;
                } else {
                    savedErrors[idx] = null;
                }
            });
        });

        form.addEventListener('submit', function (e) {
            e.preventDefault();

        });
    };

    const editStep = () => {
        props.editStep(3)
    };

    return (
        <div className={styles.detailInfo}>
            <div className={styles.header}>
                <div className={styles.title}>
                    Payment method
                </div>
                {
                    parseInt(currentStep) !== 3 && parseInt(maxStep) > 3 && (
                        <Button
                            color="secondary"
                            className={styles.btnEdit}
                            endIcon={<img src={require('src/assets/images/arrow-right-edit.svg')} alt=""/>}
                            onClick={editStep}
                        >
                            Edit
                        </Button>
                    )
                }
            </div>
            <div className={parseInt(currentStep) === 3 ? styles.form : styles.hidden}>
                <p className={styles.description}>
                    Visa, Mastercard, American Express or Discover billed in US Dollars
                </p>
                <div className="checkout-stripe">
                    <form>
                        <div className="row">
                            <div className="field">
                                <div id="checkout-card-number" className="input empty"></div>
                                <label htmlFor="checkout-card-number" data-tid="elements_examples.form.card_number_label">Credit Card
                                    Number</label>
                                <div className="baseline"></div>
                            </div>
                            <div className="field">
                                <div id="checkout-card-expiry" className="input empty"></div>
                                <label htmlFor="checkout-card-expiry"
                                       data-tid="elements_examples.form.card_expiry_label">Expiration Date</label>
                                <div className="baseline"></div>
                            </div>
                            <div className="field">
                                <div id="checkout-card-cvc" className="input empty"></div>
                                <label htmlFor="checkout-card-cvc"
                                       data-tid="elements_examples.form.card_cvc_label">CVC</label>
                                <div className="baseline"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
});

PaymentMethodComponent.propTypes = {
    billingAddress: PropTypes.object.isRequired,
    currentStep: PropTypes.number.isRequired,
    editStep: PropTypes.func.isRequired,
    updatePaymentInfo: PropTypes.func.isRequired,
};

export default PaymentMethodComponent;
