import { types } from './index';

export const calcAmount = (payload) => ({
    type: types.CALC_AMOUNT_REQUEST,
    payload
});

export const createSponsorship = (payload) => ({
    type: types.CREATE_SPONSORSHIP_REQUEST,
    payload
});

export const clearDataSponsorship = () => ({
  type: types.CLEAR_DATA_SPONSORSHIP
});
