import React, {useState, useEffect, useRef} from 'react';
import {Redirect, withRouter} from 'react-router-dom';

import PayNow from './PayNow';
import PaymentMethod from './PaymentMethod';
import ContactAddress from './ContactAddress';
import BillingAddress from './BillingAddress';
import Container from '@material-ui/core/Container';


import styles from "../Checkout/index.module.scss";
import {bindActionCreators} from "redux";
import {payNow, getPaymentPlan} from "src/redux/billing/action";
import {saveStep} from "src/redux/onboarding/action";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import config from "../../config/config";

export const CheckoutContainer = (props) => {
  const {payNow, dataAccountInfo} = props;
  const [currentStep, setCurrentStep] = useState(1);
  const [maxStep, setMaxStep] = useState(1);
  const [stripe, setStripe] = useState(null);

  const [billingAddress, setBillingAddress] = useState({
    isSameContact: false,
    firstName: '',
    lastName: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
  });

  const [contactAddress, setContactAddress] = useState({
    firstName: '',
    lastName: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
  });

  const paymentInfoRef = useRef();

  const editStep = (step) => {
    setCurrentStep(step)
  };

  useEffect(() => {
    props.action.getPaymentPlan();

    // eslint-disable-next-line
  }, []);

  const updateContactAddress = (newContactAddress) => {
    if (maxStep < 2) {
      setMaxStep(2);
      setCurrentStep(2);
    } else {
      setCurrentStep(maxStep);
    }
    setContactAddress(newContactAddress)
  };

  const updateBillingAddress = (newBillingAddress) => {
    if (maxStep < 3) {
      setMaxStep(3);
    }
    setCurrentStep(3);
    setBillingAddress(newBillingAddress);
  };

  const updatePaymentInfo = (paymentInfo) => {
    const data = {
      planID: props.dataPaymentPlan.plans ? props.dataPaymentPlan.plans[0].unid : null,
      cardID: paymentInfo.card.id,
      newCard: {
        tokenID: paymentInfo.id,
      },
      contactAddress,
      billingAddress,
    };
    props.action.payNow(data)
  };

  useEffect(() => {
    const stripeKey = config.stripe.key;
    const stripeUrl = "https://js.stripe.com/v3/";
    if (!document.querySelector("#stripe-js")) {
      const script = document.createElement("script");
      script.async = true;
      script.id = "stripe-js";
      script.onload = () => {
        setStripe(window.Stripe(stripeKey));
      };
      document.head.appendChild(script);
      script.src = stripeUrl;
    } else if (window.Stripe) {
      setStripe(window.Stripe(stripeKey));
    }

    // eslint-disable-next-line
  }, []);

  const onChangeSameContact = (value) => {
    if (value) {
      let newBillingAddress = {
        ...contactAddress,
      };
      setBillingAddress(newBillingAddress);
    }

  };

  const onPayNow = async () => {
    paymentInfoRef.current.getTokenStripe()
  };
  if (parseInt(payNow.success) === 0) {
    props.action.saveStep(
      {
        onBoardingStep: 1,
        finishedOnBoarding: false
      }
    );
    return <Redirect to="/confirmation"/>
  }

  if (dataAccountInfo.account && dataAccountInfo.account.user.onBoardingStep !== 0 && !dataAccountInfo.account.user.finishedOnBoarding) {
    return <Redirect to="/onboarding"/>
  }

  if (dataAccountInfo.account && dataAccountInfo.account.user.onBoardingStep !== 0 && dataAccountInfo.account.user.finishedOnBoarding) {
    return <Redirect to="/"/>
  }

  return (
    <div className={styles.containerFluid}>
      <Container className={styles.container}>
        <div className={styles.inner}>
          <div className={styles.billingInfo}>
            <ContactAddress currentStep={currentStep}
                            maxStep={maxStep}
                            editStep={editStep}
                            contactAddress={contactAddress}
                            updateContactAddress={updateContactAddress}
            />
            <BillingAddress currentStep={currentStep}
                            maxStep={maxStep}
                            contactAddress={contactAddress}
                            editStep={editStep}
                            billingAddress={billingAddress}
                            onChangeSameContact={onChangeSameContact}
                            updateBillingAddress={updateBillingAddress}
            />
            <PaymentMethod currentStep={currentStep}
                           maxStep={maxStep}
                           editStep={editStep}
                           billingAddress={billingAddress}
                           stripe={stripe}
                           updatePaymentInfo={updatePaymentInfo}
                           ref={paymentInfoRef}
            />
          </div>
          <div className={styles.payNow}>
            <PayNow currentStep={currentStep}
                    dataPaymentPlan={props.dataPaymentPlan}
                    loading={props.payNow ? props.payNow.loading : false}
                    maxStep={maxStep}
                    onPayNow={onPayNow}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

CheckoutContainer.propTypes = {
  payNow: PropTypes.object.isRequired,
  dataPaymentPlan: PropTypes.object.isRequired,
  dataAccountInfo: PropTypes.object.isRequired,
  action: PropTypes.shape({
    payNow: PropTypes.func.isRequired,
    getPaymentPlan: PropTypes.func.isRequired,
    saveStep: PropTypes.func.isRequired,
  }).isRequired,
};


const mapStateToProps = (state) => {
  return {
    payNow: state.billing.payNow,
    dataPaymentPlan: state.billing.dataPaymentPlan,
    dataAccountInfo: state.account.dataAccountInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({
      payNow,
      getPaymentPlan,
      saveStep
    }, dispatch),
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CheckoutContainer));
