import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

import Layout from "src/components/Layout";
import styles from "./index.module.scss";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  getChallenges,
  getCSVChallenges,
  getCSVPastChallenge,
  clearCSV,
  getCSVDetailsChallenge,
} from "src/redux/dashboard/action";
import { setRefUrl } from "src/redux/schedule/action";
import moment from "moment";
import Current from "./Current";
import Past from "./Past";
import Upcoming from "./Upcoming";
import Detail from "./Detail";
import Paper from "src/components/Paper";
import Button from "@material-ui/core/Button";
import challenge from "src/redux/challenge/reducer";
import ExportCsvModal from "src/components/Modal/ExportCsvModal";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const AntTabs = withStyles({
  root: {
    border: "none",
  },
  indicator: {
    backgroundColor: "#4BA97C",
    height: 4,
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    marginRight: 50,
    fontWeight: 300,
    fontSize: 26,
    minWidth: 50,
    padding: 0,
    color: "#444444",
    fontFamily: [
      '"Charity Miles"',
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    "&:hover": {
      color: "#444444",
      opacity: 1,
    },
    "&$selected": {
      color: "#444444",
    },
    "&:focus": {
      color: "#444444",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const ChallengesContainer = (props) => {
  const { dataChallenges, location, dataCSV } = props;
  const urlSearch = new URLSearchParams(location.search);
  const tab = urlSearch.get("tab");
  const classes = useStyles();
  let tabIndex = 0;
  if (tab === "upcoming") {
    tabIndex = 1;
  } else if (tab === "past") {
    tabIndex = 2;
  }

  const [openExportCsvModal, setOpenExportCsvModal]= useState(false);
  const [isClickExport, setClickExport]= useState(false);
  const [value, setValue] = useState(tabIndex);
  const [challengeDetail, setChallengeDetail] = useState(null);
  const [redirect, setRedirect] = useState(null);

  let pastChallenges = [];
  let currentChallenge;
  let upcomingChallenge;
  let upcomingChallenges = [];
  let currentSponsorship;
  let currentLeaderboard;

  if (dataChallenges.current) {
    currentChallenge = dataChallenges.current.challenge;
    currentChallenge.days = moment(currentChallenge.endDate).diff(
      moment(currentChallenge.startDate),
      "d"
    );
    currentChallenge.daysRemaining = moment(currentChallenge.endDate).diff(
      moment(),
      "d"
    );
    currentSponsorship = dataChallenges.current.sponsorship;
    currentLeaderboard = dataChallenges.current.leaderboard;
  }

  if (dataChallenges.upcoming && dataChallenges.upcoming.challenge) {
    upcomingChallenge = dataChallenges.upcoming;
    upcomingChallenge.challenge.days = moment(
      upcomingChallenge.challenge.endDate
    ).diff(moment(upcomingChallenge.challenge.startDate), "d");
    upcomingChallenge.challenge.daysRemaining = moment().diff(
      moment(upcomingChallenge.challenge.startDate),
      "d"
    );
    upcomingChallenges = [upcomingChallenge];
  }

  if (dataChallenges.past) {
    pastChallenges = dataChallenges.past;
  }

  useEffect(() => {
    props.action.getChallenges();
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    if(dataCSV.isSuccess){
      setOpenExportCsvModal(true)
    }
  },[dataCSV])

  const handleCloseExportCsvModal =()=>{
    // clear data CSV
    props.action.clearCSV();
    setClickExport(false);
    setOpenExportCsvModal(false)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const _showDetail = (challengeInfo) => {
    setChallengeDetail(challengeInfo);
  };

  const _back = () => {
    setChallengeDetail(null);
  };

  const scheduleChallenge = () => {
    let tab = "current";
    if (value === 1) {
      tab = "upcoming";
    } else if (value === 2) {
      tab = "past";
    }
    props.action.setRefUrl("/challenges?tab=" + tab);
    setRedirect("/schedule");
  };

  const getCSVPastChallenge = (challengeUnid) => {
    props.action.getCSVPastChallenge(challengeUnid);
  };

  const getCSVDetailsChallenge = (challengeUnid) => {
    props.action.getCSVDetailsChallenge(challengeUnid);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
    <Layout>
      {challengeDetail ? (
        <Detail
          challengeInfo={challengeDetail}
          back={_back}
          getCSVPastChallenge={getCSVPastChallenge}
          getCSVDetailsChallenge={getCSVDetailsChallenge}
          setClickExport={setClickExport}
        />
      ) : (
        <div className={styles.challenges}>
          <h1 className={styles.title}>Challenges</h1>
          {value !== 0 && !dataChallenges.loading ? (
            <Button
              variant="outlined"
              color="secondary"
              className={styles.scheduleChallenge}
              type="button"
              onClick={scheduleChallenge}
            >
              Schedule a Challenge
            </Button>
          ) : null}
          <div className={classes.root}>
            <AntTabs
              value={value}
              onChange={handleChange}
              aria-label="ant example"
            >
              <AntTab label="Current" />
              <AntTab label="Upcoming " />
              <AntTab label="Past" />
            </AntTabs>
            <Paper
              loading={!dataChallenges || dataChallenges.loading}
              className={styles.paper}
            >
              <TabPanel value={value} index={0}>
                {currentChallenge ? (
                  <Current
                    currentChallenge={currentChallenge}
                    currentSponsorship={currentSponsorship}
                    currentLeaderboard={currentLeaderboard}
                    getChallenges={props.action.getChallenges}
                  />
                ) : !dataChallenges.loading ? (
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={styles.scheduleChallenge}
                    type="button"
                    onClick={scheduleChallenge}
                  >
                    Schedule a Challenge
                  </Button>
                ) : null}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {upcomingChallenge && (
                  <Upcoming
                    challenges={upcomingChallenges}
                    getChallenges={props.action.getChallenges}
                  />
                )}
              </TabPanel>
              <TabPanel value={value} index={2}>
                {pastChallenges && pastChallenges.length ? (
                  <Past challenges={pastChallenges} showDetail={_showDetail} />
                ) : null}
              </TabPanel>
            </Paper>
          </div>
          
        </div>
      )}
    </Layout>
    {
      openExportCsvModal && isClickExport && (
        <ExportCsvModal 
          handleCloseExportCsvModal={handleCloseExportCsvModal}
        />
      )
    }
    </>
  );
};

ChallengesContainer.propTypes = {
  dataChallenges: PropTypes.object.isRequired,
  dataCSV: PropTypes.func.isRequired,
  action: PropTypes.shape({
    getChallenges: PropTypes.func.isRequired,
    getCSVChallenges: PropTypes.func.isRequired,
    setRefUrl: PropTypes.func.isRequired,
    getCSVPastChallenge: PropTypes.func.isRequired,
    clearCSV: PropTypes.func.isRequired,
    getCSVDetailsChallenge: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    dataChallenges: state.dashboard.dataChallenges,
    dataCSV: state.dashboard.dataCSV,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      {
        getChallenges,
        getCSVChallenges,
        setRefUrl,
        getCSVPastChallenge,
        clearCSV,
        getCSVDetailsChallenge,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChallengesContainer));
