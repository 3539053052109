import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';

import Container from '@material-ui/core/Container';

import styles from "./index.module.scss";
import {bindActionCreators} from "redux";
import {getTemplates} from "src/redux/schedule/action";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Grid from '@material-ui/core/Grid';
import Paper from 'src/components/Paper';

export const PreviewChallengeContainer = (props) => {
    const {match, dataTemplates} = props;
    const {
        params: {unid}
    } = match;
    useEffect(() => {
        props.action.getTemplates();

        // eslint-disable-next-line
    }, []);

    const template = dataTemplates.templates ? dataTemplates.templates.find(template => template.unid === unid) : null;

    return (
        <div className={styles.containerFluid}>
            <Container className={styles.container}>
                <div className={styles.inner}>
                    <Paper
                        className={styles.paper}
                        loading={(!dataTemplates || dataTemplates.loading)}
                    >
                        {
                            template && (
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6}>
                                        <div className={styles.challenge}>
                                            <div className={styles.header}>
                                                Email that gets sent to employees
                                            </div>
                                            <div className={styles.content}>
                                                <h2 className={styles.name}>{template.name}</h2>
                                                <p className={styles.description}>{template.description}</p>
                                                <div className={styles.imgPreview}>
                                                  <img src={require('src/assets/images/preview-mobile.svg')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div className={styles.appPreview}>
                                            <div className={styles.header}>
                                                Challenge in the app
                                            </div>
                                            <div className={styles.content}>
                                                <img src={require('src/assets/images/preview-mobile.svg')} alt="" />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Paper>
                </div>
            </Container>
        </div>
    );
};

PreviewChallengeContainer.propTypes = {
    dataTemplates: PropTypes.object.isRequired,
    action: PropTypes.shape({
        getTemplates: PropTypes.func.isRequired,
    }).isRequired,
};


const mapStateToProps = (state) => {
    return {
        dataTemplates: state.schedule.dataTemplates,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators({
            getTemplates
        }, dispatch),
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(PreviewChallengeContainer));
