import { call, put, takeLatest } from 'redux-saga/effects';
import { types, urls } from './index';
import {apiGet, apiPost} from 'src/utils/api';
import notify from 'src/utils/notify';

function* getTemplates() {
    try {
        const response = yield call(apiGet, {
            path: urls.TEMPLATES
        });
        if (response.error) {
            yield put({
                type: types.TEMPLATES_FAILURE,
                payload: response,
            });
            notify.error(response.error);
        } else {
            yield put({
                type: types.TEMPLATES_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        yield put({
            type: types.TEMPLATES_FAILURE,
            payload: error,
        });
        notify.error(error.message);
    }
}

function* createChallenge(action) {
    try {
        const response = yield call(apiPost, {
            path: urls.CREATE_CHALLENGE,
            payload: action.payload
        });
        if (response.error) {
            yield put({
                type: types.CREATE_CHALLENGE_FAILURE,
                payload: response,
            });
            notify.error(response.error);
        } else {
            yield put({
                type: types.CREATE_CHALLENGE_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        yield put({
            type: types.CREATE_CHALLENGE_FAILURE,
            payload: error,
        });
        notify.error(error.message);
    }
}

export default function* scheduleWatcher() {
    yield takeLatest(types.TEMPLATES_REQUEST, getTemplates);
    yield takeLatest(types.CREATE_CHALLENGE_REQUEST, createChallenge);
}
