export const types = {
  CALC_AMOUNT_REQUEST: 'CALC_AMOUNT_REQUEST',
  CALC_AMOUNT_SUCCESS: 'CALC_AMOUNT_SUCCESS',
  CALC_AMOUNT_FAILURE: 'CALC_AMOUNT_FAILURE',

  CREATE_SPONSORSHIP_REQUEST: 'CREATE_SPONSORSHIP_REQUEST',
  CREATE_SPONSORSHIP_SUCCESS: 'CREATE_SPONSORSHIP_SUCCESS',
  CREATE_SPONSORSHIP_FAILURE: 'CREATE_SPONSORSHIP_FAILURE',

  CLEAR_DATA_SPONSORSHIP: 'CLEAR_DATA_SPONSORSHIP',
};

export const urls = {
  CALC_AMOUNT: 'companyAdmin/sponsorships/recommend',
  CREATE_SPONSORSHIP: 'companyAdmin/challenges/unid/sponsorships',
  CREATE_SPONSORSHIP_WITHOUT_CHALLENGE: 'companyAdmin/challenges/0/sponsorships',
};
