import {types} from './index';

const initialState = {
  states: {
    joining: 'done',
    accountSetup: 'doing'
  },
  dataOnboarding: {}
};

const onboarding = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_STEP:
      return {...state, states: action.payload};

    case types.ONBOARDING_REQUEST:
      return {...state, dataOnboarding: {loading: true}};
    case types.ONBOARDING_SUCCESS:
    case types.ONBOARDING_FAILURE:
      return { ...state, dataOnboarding: action.payload };

    default:
      return state;
  }
};

export default onboarding;
