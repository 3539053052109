import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import Layout from 'src/components/Layout';
import styles from './index.module.scss';
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Next from './IconNext';
import Prev from './IconPrev';
import {getGallery} from "src/redux/gallery/action";

export const GalleryContainer = (props) => {
  const {dataGallery} = props;
  const [openModalPreview, setOpenModalPreview] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [classIconNext, setClassIconNext] = useState(`${styles.next} ${styles.iconControl}`);
  const [classIconPrev, setClassIconPrev] = useState(`${styles.prev} ${styles.iconControl}`);
  const images = dataGallery.sharedPhotos ?  dataGallery.sharedPhotos.map(src => {
    if (src.photo.includes('http')) {
      return src;
    }
    return `https://cmprofilepics.s3.amazonaws.com/${src}`
  }) : [];

  useEffect(() => {
    props.action.getGallery();

    // eslint-disable-next-line
  }, []);

  const actionImage = (index) => {
    setCurrentIndex(index);
    setOpenModalPreview(true);
  };

  const closeModalPreview = () => {
    setOpenModalPreview(false);
    setTimeout(() => {
      setCurrentIndex(null);
    }, 300)
  };

  const nextImage = () => {
    if (currentIndex === images.length - 1) {
      return;
    }
    const newIndex = currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const prevImage = () => {
    if (currentIndex === 0) {
      return;
    }
    const newIndex = currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    if (currentIndex === images.length - 1) {
      setClassIconNext(`${styles.hidden}`)
    } else {
      setClassIconNext(`${styles.next} ${styles.iconControl}`)
    }
    if (currentIndex === 0) {
      setClassIconPrev(`${styles.hidden}`)
    } else {
      setClassIconPrev(`${styles.prev} ${styles.iconControl}`)
    }

    // eslint-disable-next-line
  }, [currentIndex]);

  return (
    <Layout>
      <div className={styles.root}>
        <div className={styles.header}>
          <h1 className={styles.title}>
            Gallery
          </h1>
        </div>
        <div className={styles.imageList}>
          {
            images.map((img, index) => (
              <div className={styles.grid}>
                <div key={index} className={styles.imgThumb} onClick={() => {
                  actionImage(index)
                }}>
                  <div className={styles.photo}><img src={img.photo} alt="gallery"/></div>
                  <p className={styles.username}>{img.userName}</p>
                  <p className={styles.email}>{img.userEmail}</p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <Modal
        className={styles.modalPreview}
        open={openModalPreview}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onClose={closeModalPreview}
      >
        <Fade in={openModalPreview}>
          <div className={styles.contentModal}>
            <img src={require("src/assets/images/icon-remove.svg")}
                 alt=""
                 className={styles.iconClose}
                 onClick={closeModalPreview}/>
            <div className={styles.imgPreview}>
              <img src={images[currentIndex] ? images[currentIndex].photo : null} alt="preview"/>
            </div>
            <div className={classIconNext} onClick={nextImage}>
              <Next/>
            </div>
            <div className={classIconPrev} onClick={prevImage}>
              <Prev/>
            </div>
          </div>
        </Fade>
      </Modal>
    </Layout>
  );
};


GalleryContainer.propTypes = {
  dataGallery: PropTypes.object.isRequired,
  action: PropTypes.shape({
    setupAccount: PropTypes.func.isRequired
  }).isRequired,
};


const mapStateToProps = (state) => {
  return {
    dataGallery: state.gallery.dataGallery,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({
      getGallery,
    }, dispatch),
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(GalleryContainer));
