import { call, put, takeLatest } from 'redux-saga/effects';
import { types, urls } from './index';
import { apiPost, apiGet, apiPut } from 'src/utils/api';
import notify from 'src/utils/notify';

function* payNow(action) {
    try {
        const response = yield call(apiPost, {
            path: urls.PAY_NOW,
            payload: action.payload
        });
        if (response.error) {
            yield put({
                type: types.PAY_NOW_FAILURE,
                payload: response,
            });
            notify.error(response.error);
        } else {
            yield put({
                type: types.PAY_NOW_SUCCESS,
                payload: response,
            });
        }
    } catch (error) {
        yield put({
            type: types.PAY_NOW_FAILURE,
            payload: error,
        });
        notify.error(error.message);
    }
}

function* getPaymentPlan() {
    try {
        const response = yield call(apiGet, {
            path: urls.PAYMENT_PLAN
        });
        if (response.error) {
            yield put({
                type: types.PAYMENT_PLAN_FAILURE,
                payload: response,
            });
            notify.error(response.error);
        } else {
            yield put({
                type: types.PAYMENT_PLAN_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        yield put({
            type: types.PAYMENT_PLAN_FAILURE,
            payload: error,
        });
        notify.error(error.message);
    }
}

function* getBillingPlan() {
  try {
    const response = yield call(apiGet, {
      path: urls.BILLING_PLAN
    });
    if (response.error) {
      yield put({
        type: types.BILLING_PLAN_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.BILLING_PLAN_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.BILLING_PLAN_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* getInvoices() {
  try {
    const response = yield call(apiGet, {
      path: urls.INVOICES
    });
    if (response.error) {
      yield put({
        type: types.INVOICES_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.INVOICES_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.INVOICES_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* updateCard(action) {
  try {
    const response = yield call(apiPut, {
      path: urls.UPDATE_CARD,
      payload: action.payload
    });
    if (response.error) {
      yield put({
        type: types.UPDATE_CARD_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.UPDATE_CARD_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: types.UPDATE_CARD_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

export default function* payNowWatcher() {
    yield takeLatest(types.PAY_NOW_REQUEST, payNow);
    yield takeLatest(types.PAYMENT_PLAN_REQUEST, getPaymentPlan);
    yield takeLatest(types.BILLING_PLAN_REQUEST, getBillingPlan);
    yield takeLatest(types.INVOICES_REQUEST, getInvoices);
    yield takeLatest(types.UPDATE_CARD_REQUEST, updateCard);
}
