import { types } from "./index";

const initialState = {
  employees: {
    data: [],
  },
  workouts: {},
};

const employees = (state = initialState, action) => {
  switch (action.type) {
    case types.INACTIVE_EMPLOYEE_REQUEST:
    case types.DELETE_EMPLOYEE_REQUEST:
      return { ...state, employees: { ...state.employees, loading: true } };
    case types.GET_EMPLOYEES_REQUEST:
    case types.GET_EMPLOYEES_BY_NAME_REQUEST:
      return { ...state, employees: { ...state.employees, loading: true } };
    case types.GET_EMPLOYEES_FAIURE:
    case types.GET_EMPLOYEES_SUCCESS:
    case types.GET_EMPLOYEES_BY_NAME_SUCCESS:
    case types.GET_EMPLOYEES_BY_NAME_FAILURE:
      return {
        ...state,
        employees: {
          data: action.payload.users,
        },
      };

    case types.GET_EMPLOYEES_SESSION_REQUEST:
      return { ...state, workouts: { loading: true } };

    case types.GET_EMPLOYEES_SESSION_SUCCESS:
    case types.GET_EMPLOYEES_SESSION_FAILURE:
      return { ...state, workouts: action.payload.sessions };
    default:
      return state;
  }
};
export default employees;
