import { types } from "./index";

const initialState = {
  registeredUsers: {},
  totalImpact: {},
  currentSponsorship: {},
  milesCompleted: {},
  dataLeaderboard: {},
  dataChallenges: {},
  // dataCSVChallenges: {},
  // dataCSVLeaderboard: {},
  // dataCSVActivitiesLeaderboard: {},
  // dataCSVSponsorship: {},
  // dataCSVImpact: {},
  // dataCSVRegisteredUsers: {},
  // dataCSVMilesCompleted: {},
  //   dataCSVPastChallenge: {},
  // dataCSVChallengesById: {},
  // dataCSVDetailChallenge: {},
  dataCSV:{},
  messageDashboard: "",
  sponsorshipById: {},
  isOpenModalEditSponsorship: false,

};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    //get CSV activities leaderboard
    case types.GET_CSV_ACTIVITEIE_LEADERBOARD_REQUEST:
    case types.GET_CSV_DETAIL_CHALLENGE_REQUEST:
    case types.CSV_CHALLENGES_BY_ID_REQUEST:
    case types.GET_CSV_PAST_CHALLENGE_REQUEST:
    case types.CSV_CHALLENGES_REQUEST:
    case types.CSV_LEADERBOARD_REQUEST:
    case types.CSV_REGISTERED_USERS_REQUEST:
    case types.CSV_IMPACT_REQUEST:
    case types.CSV_SPONSORSHIP_REQUEST:
    case types.CSV_MILES_COMPLETED_REQUEST:
      return { ...state, dataCSV: { loading: true } };

    case types.GET_CSV_FAILURE:
      return { ...state, dataCSV: { data: action.payload },};

    case types.GET_CSV_SUCCESS:
      return { ...state, dataCSV: { isSuccess: true},};

    case types.CLEAR_CSV:
      return { ...state, dataCSV:{}};
   
    case types.OPEN_MODAL_EDIT_SPONSORSHIP:
      return {
        ...state,
        isOpenModalEditSponsorship: !state.isOpenModalEditSponsorship,
      };

    case types.EDIT_SPONSORSHIP_BY_ID_SUCCESS:
      return {
        ...state,
        sponsorshipById: action.payload.data.sponsorship,
      };
      
    case types.REGISTERED_USERS_REQUEST:
      return { ...state, registeredUsers: { loading: true } };
    case types.REGISTERED_USERS_SUCCESS:
    case types.REGISTERED_USERS_FAILURE:
      return { ...state, registeredUsers: action.payload };

    case types.TOTAL_IMPACT_REQUEST:
      return { ...state, totalImpact: { loading: true } };
    case types.TOTAL_IMPACT_SUCCESS:
    case types.TOTAL_IMPACT_FAILURE:
      return { ...state, totalImpact: action.payload };

    case types.CURRENT_SPONSORSHIP_REQUEST:
      return { ...state, currentSponsorship: { loading: true } };
    case types.CURRENT_SPONSORSHIP_SUCCESS:
    case types.CURRENT_SPONSORSHIP_FAILURE:
      return { ...state, currentSponsorship: action.payload };

    case types.MILES_COMPLETED_REQUEST:
      return { ...state, milesCompleted: { loading: true } };
    case types.MILES_COMPLETED_SUCCESS:
    case types.MILES_COMPLETED_FAILURE:
      return { ...state, milesCompleted: action.payload };

    case types.LEADERBOARD_REQUEST:
      return { ...state, dataLeaderboard: { loading: true } };
    case types.LEADERBOARD_SUCCESS:
    case types.LEADERBOARD_FAILURE:
      return { ...state, dataLeaderboard: action.payload };

    case types.CHALLENGES_REQUEST:
      return { ...state, dataChallenges: { loading: true } };
    case types.CHALLENGES_SUCCESS:
    case types.CHALLENGES_FAILURE:
      return { ...state, dataChallenges: action.payload };

    case types.UPDATE_MESSAGE_DASHBOARD:
      return { ...state, messageDashboard: action.payload };

    case types.GET_SPONSORSHIP_BY_ID_REQUEST:
      return { ...state, sponsorshipById: { loading: true } };
    case types.GET_SPONSORSHIP_BY_ID_SUCCESS:
    case types.GET_SPONSORSHIP_BY_ID_FAILURE:
      return { ...state, sponsorshipById: { data: action.payload } };
    default:
      return state;
  }
};
export default dashboard;
