import {call, put, takeLatest} from 'redux-saga/effects';
import {types, urls} from './index';
import {apiGet, apiPut} from 'src/utils/api';
import notify from 'src/utils/notify';

function* getChallenge(action) {
  try {
    const url = urls.GET_CHALLENGE.replace('unid', action.payload.unid);
    const response = yield call(apiGet, {
      path: url
    });
    if (response.error) {
      yield put({
        type: types.GET_CHALLENGE_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.GET_CHALLENGE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_CHALLENGE_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

function* updateChallenge(action) {
  try {
    const url = urls.UPDATE_CHALLENGE.replace('unid', action.payload.unid);
    const payload = {...action.payload};
    delete payload.unid;
    const response = yield call(apiPut, {
      path: url,
      payload
    });
    if (response.error) {
      yield put({
        type: types.UPDATE_CHALLENGE_FAILURE,
        payload: response,
      });
      notify.error(response.error);
    } else {
      yield put({
        type: types.UPDATE_CHALLENGE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: types.UPDATE_CHALLENGE_FAILURE,
      payload: error,
    });
    notify.error(error.message);
  }
}

export default function* scheduleWatcher() {
  yield takeLatest(types.GET_CHALLENGE_REQUEST, getChallenge);
  yield takeLatest(types.UPDATE_CHALLENGE_REQUEST, updateChallenge);
}
