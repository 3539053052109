export const types = {
    SIGN_IN_REQUEST: 'SIGN_IN_REQUEST',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',

    SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',

    ACTIVATE_REQUEST: 'ACTIVATE_REQUEST',
    ACTIVATE_SUCCESS: 'ACTIVATE_SUCCESS',
    ACTIVATE_FAILURE: 'ACTIVATE_FAILURE',

    SIGN_OUT_REQUEST: 'SIGN_OUT_REQUEST',
    SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
}

export const urls = {
    SIGN_IN: 'companyAdmin/login',
    SIGN_UP: 'companyAdmin/register',
    ACTIVATE: 'companyAdmin/activate',
}
