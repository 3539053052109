import React, {Fragment} from 'react';

import styles from "../index.module.scss";
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import moment from "moment";

export const SummaryComponent = (props) => {
  const {dataSponsorship, data, dataBillingPlan, unid} = props;

  const confirmSponsorship = () => {
    props.confirmSponsorship()
  };

  const goBack = () => {
    props.editData();
  };

  return (
    <Fragment>
      {
        parseInt(unid) === 0 ?
          <h1 className={styles.title}>Sponsorship Summary</h1> :
          <h1 className={styles.title}>Sponsorship and Challenge Summary</h1>
      }
      <Button className={styles.editSponsorship}
              color="secondary"
              onClick={props.editData}
      >
        Edit
      </Button>
      <div className={styles.summary}>
        <div className={styles.blockInfo}>
          <div className={styles.labelData}>
            Budget
          </div>
          <div className={styles.content}>
            ${data.budget} {data.budgetType === 'company' ? 'for entire company' : 'per employee'}
            {parseInt(unid) === 0 && data.monthly?<span className={styles.perMonth}>&nbsp;(per month)</span>:null}
          </div>
        </div>
        <div className={styles.blockInfo}>
          <div className={styles.labelData}>
            Dates
          </div>
          <div className={styles.content}>
            {parseInt(unid) === 0 && data.monthly ? "Monthly, cancel any time":
              <>{moment.utc(data.startDate).format('MMMM DD')}, {moment.utc(data.startDate).format('YYYY')} -
            {moment.utc(data.endDate).format('MMMM DD')}, {moment.utc(data.endDate).format('YYYY')}</>}
          </div>
        </div>
        <div className={styles.blockInfo}>
          <div className={styles.labelData}>
            Sponsorship Amounts and Beneficiaries
          </div>
          <div className={styles.charity}>
            <div>
              <div className={styles.typeCharity}>
                Employee’s Charity
              </div>
              <div className={styles.inputData}>
                <img src={require('src/assets/images/walk.svg')} alt="walk" />
                <span>${data.employeeCharityMPMRun}</span>
              </div>
              <div className={styles.inputData}>
                <img src={require('src/assets/images/bike.svg')} alt="bike"/>
                <span>${data.employeeCharityMPMBike}</span>
              </div>
            </div>
            <div>
              <div className={styles.typeCharity}>
                Company’s Charity
              </div>
              <div className={styles.inputData}>
                <img src={require('src/assets/images/walk.svg')} alt="walk" />
                <span>${data.companyCharityMPMRun}</span>
              </div>
              <div className={styles.inputData}>
                <img src={require('src/assets/images/bike.svg')} alt="bike" />
                <span>${data.companyCharityMPMBike}</span>
              </div>
              {
                ((data.companyCharityMPMRun || data.companyCharityMPMBike) && data.additionalCharities.length) ?
                  <div className={styles.inputData}>
                    <img src={require('src/assets/images/heart.svg')} alt="heart" />
                    <span>{data.additionalCharities.map((charity, index) => {
                      if (charity) {
                        if (index !== data.additionalCharities.length - 1) {
                          return charity + ', '
                        }
                        return charity
                      }
                      return null;
                    })}</span>
                  </div> : null
              }
            </div>
          </div>
        </div>
        <div className={styles.blockInfo}>
          <div className={styles.labelData}>
            Sponsorship name
          </div>
          <div className={styles.content}>
            {data.name}
          </div>
        </div>
      </div>
      <div className={styles.actionBtn}>
        <Button
          variant="outlined"
          color="secondary"
          type="button"
          disabled={dataSponsorship.loading}
          className={styles.btnBack}
          onClick={goBack}
        >
          <img src={require('src/assets/images/arrow-left-pink.svg')} alt="back"/>
          Back
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          type="button"
          disabled={dataSponsorship.loading}
          onClick={confirmSponsorship}
        >
          Confirm Sponsorship
        </Button>
      </div>
    </Fragment>
  );
};

SummaryComponent.propTypes = {
  dataSponsorship: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  confirmSponsorship: PropTypes.func.isRequired,
  editData: PropTypes.func.isRequired,
  unid: PropTypes.string.isRequired,
};



export default SummaryComponent;
