import { call, put, takeLatest } from 'redux-saga/effects';
import { types, urls } from './index';
import { apiGet } from 'src/utils/api';
import notify from 'src/utils/notify';

function* getGallery() {
    try {
        const response = yield call(apiGet, {
            path: urls.GALLERY
        });
        if (response.error) {
            yield put({
                type: types.GALLERY_FAILURE,
                payload: response,
            });
            notify.error(response.error);
        } else {
            yield put({
                type: types.GALLERY_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        yield put({
            type: types.GALLERY_FAILURE,
            payload: error,
        });
        notify.error(error.message);
    }
}

export default function* payNowWatcher() {
    yield takeLatest(types.GALLERY_REQUEST, getGallery);
}
