import { call, put, takeLatest } from 'redux-saga/effects';
import { types, urls } from './index';
import { apiPost } from 'src/utils/api';
import notify from 'src/utils/notify';

function* forgotPassword({ payload }) {
    try {
        const response = yield call(apiPost, {
            path: urls.FORGOT_PASSWORD,
            payload: payload
        });
        if (response.error) {
            yield put({
                type: types.FORGOT_PASSWORD_FAILURE,
                payload: response,
            });
            notify.error(response.error);
        } else {
            yield put({
                type: types.FORGOT_PASSWORD_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        yield put({
            type: types.FORGOT_PASSWORD_FAILURE,
            payload: error,
        });
        notify.error(error.message);
    }
}


function* resetPassword({ payload }) {
    try {
        const response = yield call(apiPost, {
            path: urls.RESET_PASSWORD,
            payload: payload,
        });
        if (response.error) {
            yield put({
                type: types.RESET_PASSWORD_FAILURE,
                payload: response,
            });
            notify.error(response.error);
        } else {
            yield put({
                type: types.RESET_PASSWORD_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error) {
        yield put({
            type: types.RESET_PASSWORD_FAILURE,
            payload: error,
        });
        notify.error(error.message);
    }
}
export default function* forgotPasswodWatcher() {
    yield takeLatest(types.FORGOT_PASSWORD_REQUEST, forgotPassword);
    yield takeLatest(types.RESET_PASSWORD_REQUEST, resetPassword)
}
