import { types } from './index';

export const signIn = (payload) => ({
    type: types.SIGN_IN_REQUEST,
    payload,
});

export const signUp = (payload) => ({
    type: types.SIGN_UP_REQUEST,
    payload,
});

export const activate = (payload) => ({
    type: types.ACTIVATE_REQUEST,
    payload,
});

export const signOut = () => ({
    type: types.SIGN_OUT_REQUEST,
});
