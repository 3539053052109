import React, { useState, useEffect } from "react";

import stylesChallenge from "../index.module.scss";
import PropTypes from "prop-types";
import moment from "moment";
import { CSVLink } from "react-csv";
import Popover from "@material-ui/core/Popover";

export const UpcomingComponent = (props) => {
  const {
    upcomingChallenge,
    getCSVChallengesById
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  if (!upcomingChallenge.challenge) {
    return null;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportCsv = (challengeUnid) => {
    props.getCSVChallengesById(challengeUnid);
  };

  const handleExportCSVDetailChallenge = (challengeUnid) => {
    props.getCSVDetailsChallenge(challengeUnid);
  };

  return (
    <>
      <div className={stylesChallenge.header}>
        <div className={stylesChallenge.month}>
          {upcomingChallenge.challenge.name}
        </div>
        <div style={{ cursor: "pointer" }}>
          <img
            onClick={handleClick}
            src={require("src/assets/images/ExportIcon.png")}
            alt=""
          />
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <ul>
              <li
                className="p-1"
                onClick={() => {
                  handleExportCsv(upcomingChallenge.challenge.unid);
                  handleClose();
                }}
              >
                Overview
              </li>
              <li
                onClick={() => {
                  handleExportCSVDetailChallenge(
                    upcomingChallenge.challenge.unid
                  );
                  handleClose();
                }}
              >
                Detailed
              </li>
            </ul>
          </Popover>
        </div>
      </div>
      <div className={stylesChallenge.info}>
        <div className={stylesChallenge.distance}>
          <span>{upcomingChallenge.challenge.distance} miles</span> in{" "}
          {upcomingChallenge.challenge.days} days
        </div>
        <div className={stylesChallenge.routeMap}>Starts Soon</div>
      </div>
      <div className={stylesChallenge.time}>
        {moment(upcomingChallenge.challenge.startDate)
          .format("MMM DD YYYY")}{" "}
        -{" "}
        {moment(upcomingChallenge.challenge.endDate).format("MMM DD YYYY")}
      </div>
    </>
  );
};

UpcomingComponent.propTypes = {
  upcomingChallenge: PropTypes.object.isRequired,
  getCSVDetailsChallenge: PropTypes.object.isRequired,
  getCSVChallengesById: PropTypes.object.isRequired,
};

export default UpcomingComponent;
