import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme();

export default createMuiTheme({
    palette: {
        default: {
            light: '#444444',
            main: '#444444',
            dark: '#444444',
            contrastText: '#fff',
        },
        primary: {
            light: '#47d673',
            main: '#41C469',
            dark: '#38a95b',
            contrastText: '#fff',
        },
        secondary: {
            light: '#FE3E9A',
            main: '#FE3E9A',
            dark: '#FE3E9A',
            contrastText: '#fff',
        },
        text: {
            primary: '#444',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: 'rgba(0, 0, 0, 0.38)',
        },
    },
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg'],
        values: {
            xs: 0,
            sm: 768,
            md: 1024,
            lg: 1440
        }
    },
    typography: {
        fontFamily: '"Charity Miles", Roboto, Helvetica, Arial, sans-serif',
        fontSize: 15,
        body1: {
            fontSize: 15,
        },
        body2: {
            fontSize: 15,
            lineHeight: 1,
            letterSpacing: '0.02em',
        },
        button: {
            textTransform: 'none',
            fontWeight: 'normal',
            height: 36,
            lineHeight: 1,
            [theme.breakpoints.up(768)]: {
                fontSize: 20,
                height: 42,
            }
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    background: 'linear-gradient(198.99deg, #FCFEFF 7.06%, #D9EAE4 92.38%);',
                },
            },
        },
        MuiMenuItem: {
            root: {
                minHeight: 'auto',
            },
        },
        MuiFormLabel: {
            root: {
                color: '#5C8571',
                [theme.breakpoints.up('sm')]: {
                    fontSize: '18px'
                }
            },
        },
        MuiInputBase: {
            root: {
                fontSize: '15px',
                [theme.breakpoints.up('sm')]: {
                    fontSize: '18px'
                }
            },
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: '1px solid #444',
                },
            },
        },
        MuiInputLabel: {
            shrink: {
                transform: 'translate(0, 1.5px) scale(0.85)',
            },
        },
        MuiButton: {
            outlined: {
                borderColor: '#444',
                backgroundColor: '#fff',
                padding: '5px 10px',
            },
            outlinedSecondary: {
                border: '2px solid #FE3E9A !important'
            },
        },
        MuiPaper: {
            elevation1: {
                boxShadow: '0px 1px 3px rgba(91, 109, 98, 0.1)',
            },
        },
        MuiGrid: {
            item: {
                maxWidth: 'none !important',
            }
        },
        MuiTooltip: {
            tooltip: {
                fontWeight: 'normal',
            }
        },
        MuiPopover: {
            paper: {
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
                borderRadius: 1,
                '& ul': {
                    listStyle: 'none',
                    padding: '10px 20px 10px 10px',
                    margin: 0,
                    '& li': {
                        padding: '6px 5px',
                        cursor: 'pointer',
                        fontSize: 15
                    },
                }
            }
        }
    }
});
