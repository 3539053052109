export const types = {
  PAY_NOW_REQUEST: 'PAY_NOW_REQUEST',
  PAY_NOW_SUCCESS: 'PAY_NOW_SUCCESS',
  PAY_NOW_FAILURE: 'PAY_NOW_FAILURE',

  PAYMENT_PLAN_REQUEST: 'PAYMENT_PLAN_REQUEST',
  PAYMENT_PLAN_SUCCESS: 'PAYMENT_PLAN_SUCCESS',
  PAYMENT_PLAN_FAILURE: 'PAYMENT_PLAN_FAILURE',

  BILLING_PLAN_REQUEST: 'BILLING_PLAN_REQUEST',
  BILLING_PLAN_SUCCESS: 'BILLING_PLAN_SUCCESS',
  BILLING_PLAN_FAILURE: 'BILLING_PLAN_FAILURE',

  INVOICES_REQUEST: 'INVOICES_REQUEST',
  INVOICES_SUCCESS: 'INVOICES_SUCCESS',
  INVOICES_FAILURE: 'INVOICES_FAILURE',

  UPDATE_CARD_REQUEST: 'UPDATE_CARD_REQUEST',
  UPDATE_CARD_SUCCESS: 'UPDATE_CARD_SUCCESS',
  UPDATE_CARD_FAILURE: 'UPDATE_CARD_FAILURE',

};

export const urls = {
  PAY_NOW: 'companyAdmin/payments/charges',
  PAYMENT_PLAN: 'companyAdmin/payments/plans',
  BILLING_PLAN: 'companyAdmin/billing/plan',
  INVOICES: 'companyAdmin/billing/invoices',
  UPDATE_CARD: 'companyAdmin/billing/subscription'
};
